<template>
    <v-row
      no-gutters
    >
      <v-col
        cols="auto"
        class="custom-sidebar"
      >
        <sidebar />
      </v-col>
      <v-col class="custom-container">
        <toolbar />
        <v-main >
          
          <v-container fluid class="background-custom" >
            <router-view  />
          </v-container>
        </v-main>
      </v-col>
    </v-row>
  </template>
  <script>
  import toolbar from "../../components/toolbar";
  import sidebar from "../../components/sidebar";
  export default {
    components: { toolbar, sidebar },
  };
  </script>
  <style scoped>
  @media screen and (max-width: 1483px) {
    .custom-sidebar {
      position: absolute;
      flex: none;
      z-index: 2;
      height: 100%;
    }
  }
  .custom-container {
    z-index: 1;
  }
  .background-custom {
    background-image: url("@/assets/bg.png");
    background-size: cover;
    position: relative;
    top: 0;
    right: 0;
    background-position-x: -89px;
    height: 100vh;
    width: auto;
    
  }
  </style>