<template>
    <div>
      <v-card-title class="font-weight-light headline pa-0 mb-5">
        Login
      </v-card-title>
      <v-card-subtitle class="font-weight-light pa-0 mb-10" >
      </v-card-subtitle>
      <v-text-field
        outlined
        label="Email"
        type="email"
        class="pb-0"
        @keyup.enter.native="handleSubmit"
        :rules="[rules.requiredUsername, rules.email]"
        :error-messages="usernameError"
        v-model="username"
      />
      <v-text-field
        outlined
        label="Password"
        type="password"
        @keyup.enter.native="handleSubmit"
        :rules="[rules.requiredPassword]"
        :error-messages="passwordError"
        v-model="password"
      />
      <v-row
        no-gutters
        justify="end"
      >
        <v-col cols="auto">
          <v-btn
            rounded
            color="secondary"
            class="pa-5"
            @click="()=>{$router.push('/register').catch(err=>{})}"
          >
            Registrati
          </v-btn>
          <v-btn
            rounded
            color="primary"
            class="ml-3 pa-5"
            :loading="loading"
            @click="handleSubmit"
          >
            Login
          </v-btn>
          <v-btn
            v-if="false"
            rounded
            color="primary"
            class="ml-3 pa-5"
            :loading="loading"
            @click="accountsList"
          >
            Lista account
          </v-btn>
          <v-btn
                v-if="false"
                rounded
                :loading="loadingMunicipalitiesButton"
                color="primary"
                class="ml-3 pa-5"
                @click="startInserimentoComuni"
                >
                Inserimento Comuni
            </v-btn>
            <!--<v-btn
                v-if="true"
                rounded
                :loading="loadingZoneClimaticheButton"
                color="primary"
                class="ml-3 pa-5"
                @click="startZoneClimatiche"
                >
                Inserimento Zone Climatiche
            </v-btn>-->
            <v-btn
                v-if="false"    
                rounded
                color="primary"
                class="ml-3 pa-5"
                @click="testEmail"
                >
                TestEmail
            </v-btn>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        justify="end"
        class="mt-4"
      >
        <v-col cols="auto">
          <v-btn
            rounded
            color="primary"
            class="pa-0"
            @click="$router.push('/forgot-password').catch(err=>{})"
            text
            x-small
          >
            Password dimenticata?
          </v-btn>
        </v-col>
      </v-row>
     
    </div>
      
</template>
<script>
  import { getAccounts,testEmail, getByEmail } from '@/api/accounts.api.js'
  import { inserimentoMassivoComuni, inserimentoMassivoZoneClimatiche } from "@/api/municipalities.api"
    export default {
        name: 'Login',
        data(){
            return{
                loading: false,
                loadingEmail:false,
                loadingMunicipalitiesButton: false,
                loadingZoneClimaticheButton: false,
                username: "",
                usernameError: "",
                password: "",
                emailETS:"",
                emailETSError:"",
                passwordError: "",
                loading: false,
                rules: {
                    requiredUsername: (value) => !!value || "Inserire Email.",
                    requiredPassword: (value) => !!value || "Inserire password.",
                    email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Inserire Email valida.";
                    },
                }
            }
        },
        /*computed: {
            loggingIn () {
                return this.$store.state.authentication.status.loggingIn;
            },
        },*/
        methods:{
            validate () {
                const usernameValid = this.username.length > 0;
                const passwordValid = this.password.length > 0;
                this.usernameError = usernameValid ? "" : "Inserire username";
                this.passwordError = passwordValid > 0 ? "" : "Inserire password";
                return usernameValid & passwordValid;
            },
            handleSubmit (e) {
                if (this.validate()) {
                    this.loading = true;
                    this.$store
                    .dispatch("authentication/login", {
                        Email: this.username,
                        Password: this.password,
                    })
                    .then(() => {
                          this.loading = false; 
             
                      //TODO vediamo se riusciamo a farlo in un altro modo
                        //this.$store.dispatch("municipalities/getAllMunicipalities"); 
                    });
                    
                }
            },
            verifyEmail(){
              if (this.validateEmail()){
                this.loadingEmail = true;
                getByEmail({Email: this.emailETS}).then(res=>{
                  //this.$router.push('/terms-conditions/'+res.data.id).catch(err=>{});
                  this.$router.push({
                    name: 'TeC', 
                    params: {
                      userId: res.data.id,
                      account: res.data
                      /*attorneyFirstName: res.data.attorneyFirstName,
                      attorneyLastName: res.data.attorneyLastName,
                      attorneyIDCardType: res.data.attorneyIDCardType,
                      attorneyIDCardExpiryDate: res.data.attorneyIDCardExpiryDate,
                      attorneyIDCardNumber: res.data.attorneyIDCardNumber,
                      attorneyIDCardIssuingAuthority: res.data.attorneyIDCardIssuingAuthority*/
                    }
                  })
                })
                .catch(err=>{
                  this.$store.dispatch('alert/error', 'Account non trovato', {root: true})
                }).finally(()=>{
                  this.loadingEmail = false;
                });
              }
            

             
            
            },
            validateEmail(){
              const emailETSValid = this.emailETS.length > 0;
              this.emailETSError = emailETSValid ? "" : "Inserire email";

              return emailETSValid;
            },
            register(){
              this.$router.push('/register')
            },
            accountsList(){
              getAccounts()
                .then((res) => {
                })
                .catch((res) => {
                  console.log(res)
                })
            },
            startInserimentoComuni(){
                inserimentoMassivoComuni()
                    .then(() => {
                    })
                    .catch(err =>{
                        console.log('ERRORE inserimento comuni');
                        console.log(err)
                    })
            },
            startZoneClimatiche(){
              inserimentoMassivoZoneClimatiche()
                  .then(() => {
                  })
                  .catch(err =>{
                      console.log('ERRORE inserimento zone climatiche');
                      console.log(err)
                  })
            },
            testEmail(){
                testEmail()
                    .then(() => {
                        console.log('Email ');
                    })
                    .catch(err =>{
                        console.log('ERRORE inserimento comuni');
                        console.log(err)
                    })
            }
        }
    }
</script>
<style>
.background-auth {
  background-image: url("@/assets/bg.png");
  background-size: cover;
  position: relative;
  top: 0;
  right: 0;
  max-width: unset !important;
  max-height: unset !important;
  width: 100% !important; 
  height: 100% !important;
}
.v-btn__content {
  letter-spacing: 0px !important;
}
.v-card-auth {
  max-width: 1200px !important;
}
</style>