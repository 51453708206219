export default class Module {


    constructor(){
       
    }
    
    id = 0;
    status = "";
    accountId = "";
    serviceType = "1";
    isCompany = false;
    annoRiferimento = "";
    acquisizioneBeneficiarioAmbrosi = false;

    beneficiaryFirstName = "";
    beneficiaryLastName = "";
    beneficiaryGender = "";
    beneficiaryBirthDate;
    beneficiaryBirthMunicipality = "";
    beneficiaryBirthProvince = "";
    beneficiaryBirthNation = "";
    beneficiaryPhone = "";

    beneficiarySsn = "";
    beneficiaryIdentificationDocNumber = "";
    beneficiaryIdentificationDocDate;
    beneficiaryCompanyType = "";
    beneficiaryCompanyName = "";
    beneficiaryAttorneyFirstName = "";
    beneficiaryAttorneyLastName = "";
    beneficiaryAttorneySsn = "";
    beneficiaryAttorneyGender = "";
    beneficiaryAttorneyBirthDate;
    beneficiaryAttorneyBirthMunicipality = "";
    beneficiaryAttorneyBirthProvince = "";
    beneficiaryAttorneyBirthNation = "";


    beneficiaryResidentialAddress = "";
    beneficiaryResidentialAddressNumber = "";
    beneficiaryResidentialMunicipality = "";
    beneficiaryResidentialProvince = "";
    beneficiaryResidentialZip = "";
    beneficiaryResidentialClimateZone = "";
    beneficiaryResidentialNation = "Italia";

    otherBeneficiaries = [];

    immovableAddress = "";
    immovableAddressNumber = "";
    immovableMunicipality = "";
    immovableProvince = "";
    immovableZip = "";
    immovableStair = "";
    immovableInterior = "";
    immovableCadastralCode = "";
    immovableClimateZone = "";

    cadastralSection = "";
    cadastralSheet = "";
    cadastralParcel = "";
    cadastralSubordinate = "";

    immovableEstimatedYearOfConstruction = "";
    immovableSurfaceArea = "";
    immovableUnitNumber = "";
    immovableMergeUnits = false;
    immovablePossessionTitle = "0";
    immovableGeneralUse = "";
    immovableParticularUse = "";
    immovableParticularUsesHardAttributions = "";
    immovableBuildingTypology = "";

    ImmovableMunicipalityCode="";

    interventionOnImmovableType = "";
    interventionStartDate;
    interventionEstimatedEndDate;
    interventionEndDate;

    manufatti = {'serramenti': [], 'schermature': [], 'chiusure': []};

    tipoImpianto = "";
    tipoTerminaleErogazione = "";
    tipoDistribuzione = "";
    tipoRegolazione = "";
    marcaImpiantoTermico = "";
    modelloImpiantoTermico = "";
    vettoreEnergetico = "metano";
    impiantoClimatizzazioneEstiva = false;
    
    valoreEER = "";
    
    generatori = [];


    spese = {
        spesaDetraibileSerramenti: "",
        speseProfessionaliSerramenti: "",    
        spesaDetraibileSchermature: "",
        speseProfessionaliSchermature: "",
        spesaDetraibileChiusure: "",
        speseProfessionaliChiusure: ""
    };
}