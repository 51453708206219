<template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
        flat
      >
        <v-toolbar-title>Utente</v-toolbar-title>
        <v-spacer />
        <v-col cols="auto">
            <v-btn text>
                <v-btn :loading="downloadIDLoading" @click="downloadUserDocuments" text>Scarica documenti utente</v-btn>
            </v-btn>
        </v-col>
      </v-toolbar>
      <v-card-text>
        <v-card
            elevation="2"
        >
        <p style="padding-left: 5px;">
            <b>Ragione sociale</b> {{account.businessName}}<br>
            <b>Indirizzo</b> {{account.street}} - {{account.city}}({{account.province}}) {{account.zip}}<br>
            <b>Partita Iva</b> {{account.vat}} - <b>Codice fiscale</b> {{account.ssnCompany}}<br>
            <b>Telefono</b> {{account.phone}}<br>
            <b>Nome operatore referente</b> {{ account.attorneyFirstName }} {{ account.attorneyLastName }}<br>
            <b>Telefono operatore referente</b> {{account.attorneyPhone}}<br>
         
            <b>Email</b> {{account.email}} - <b>Pec</b> {{account.pec}}<br>
            <b>CF legale rappresentante</b> {{account.attorneySSN}} - <b>Telefono legale rappresentante</b> {{account.attorneyPhone}}
        </p>
        </v-card>
        <v-card
            elevation="2"
        >
        <p style="padding-left: 5px;">
            <b>Numero utente</b> {{account.id}}<br>
            <b>Data registrazione</b> {{dateToYMD(account.created)}}<br>
            <b>Email verificata</b> {{account.isVerified}} 
            <v-btn
                rounded
                text
                color="primary"
                v-if="account.isVerified == 'No'"
                @click="verifyEmail"
                >
                Verifica manuale
                </v-btn>
            <br>
            <b>Contratto verificato</b> {{account.isContractVerified}} 
            <v-btn
                rounded
                text
                color="primary"
                v-if="account.isContractVerified == 'No'"
                @click="verifyContract"
                >
                Verifica contratto
                </v-btn>
            <br>
            <b>Crediti</b> 
                <v-btn
                    rounded
                    text
                    color="primary"
                    @click="showCreditsDialog()"
                >
                    <span v-if="account.credits !== undefined">
                        {{account.credits}}
                    </span>
                    <v-progress-circular
                        v-else
                        indeterminate
                        size="18"
                    />
                </v-btn>
            <br>
          <b>Sponsor</b>
            <v-btn
                text
                rounded
                color="primary"
                @click="editSponsor()"
            >
                {{ account.sponsor || 'Aggiungi Sponsor' }}
            </v-btn>
          
        </p>
        </v-card>

        <v-card>
            <v-data-table
            :headers="headersPackages"
            :items="filteredPackages"
            :search="search"
            
            fixed-header
            :footer-props="{
                'items-per-page-options':[10, 20, 30, 40]
            }"
        >
        <template v-slot:top>
            <v-toolbar
            dark
            color="secondary"
            flat
            >
            <v-toolbar-title>Pacchetti attivi</v-toolbar-title>
                <v-spacer />
                <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    label="Cerca"
                    single-line
                    hide-details
                    outlined
                />  
                <v-col cols="auto">
                    <v-btn text>
                        <v-btn :loading="rechargePackagesDialog.loading" @click="showAddAccountPackageDialog" text>Aggiungi pacchetto ad utente</v-btn>
                    </v-btn>
                </v-col>
             
            </v-toolbar>
         
        </template>
    
    </v-data-table>

        </v-card>

      </v-card-text>
        
      
        <v-dialog
            v-model="addEditSponsor.visible"
            persistent
            max-width="360"
        >
            <v-card>
            <v-card-title>
                Modifica sponsor utente <br>{{ account.email }}
            </v-card-title>
            <v-card-text>
                <v-text-field
                label="Sponsor"
                v-model="sponsorToChange"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                rounded
                text
                v-show="!addEditSponsor.loading"
                @click="editSponsorAction(false)"
                >
                Annulla
                </v-btn>
                <v-btn
                rounded
                color="primary"
                text
                :loading="addEditSponsor.loading"
                @click="editSponsorAction(true)"
                >
                Conferma
                </v-btn>
            </v-card-actions>
            </v-card>
            
        </v-dialog>
        <v-dialog v-model="deleteDialog.visible" max-width="600">
            <v-card>
                <v-card-title class="primary white--text headline mb-3"> Eliminazione account </v-card-title>
                <v-card-subtitle>
                    <br>
                    Sei sicuro di voler eliminare l'account? <br>
                
                </v-card-subtitle>
               
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancelDelete()" :disabled="deleteDialog.loading"> Annulla </v-btn>
                    <v-btn @click="sendDelete()" :loading="deleteDialog.loading" :disabled="deleteDialog.loading" color="error"> Conferma </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn
                text
                align="end"
                rounded
                color="red"
                @click="deleteAccount()"
            >
             {{ 'Elimina account' }}
        </v-btn>


       
    <v-dialog
        v-model="rechargePackagesDialog.visible"
        persistent
        max-width="360"
    >
            <v-card>
                <v-card-title class="headline">
                Ricarica Crediti Utente
                </v-card-title>
                <v-card-text>
                <v-select
                    :items="packages"
                    v-model="rechargePackagesDialog.package"
                    item-value="name"
                    item-text="name"
                    label="Pacchetto"
                />
                </v-card-text>
                <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    text
                    v-show="!rechargePackagesDialog.loading"
                    @click="focusOut"
                >
                    Annulla
                </v-btn>
                <v-btn
                    rounded
                    color="primary"
                    text
                    :loading="rechargePackagesDialog.loading"
                    @click="rechargeAccountPackage()"
                >
                    Conferma
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
   
          
  </template>
  <script>
    import { mapGetters } from "vuex";
    import { get, changeSponsor, downloadUserDocs, verifyEmailManual, deleteAccount, verifyContract, getActivePackages, insertNewAccountPackage } from "@/api/accounts.api";
    import { getPackages} from "@/api/recharge.api";
    
    export default {
      
        computed: {
            ...mapGetters({
                user: "authentication/user",
            }),
           
        },
        created (){
            if(this.$route.params.id){
                this.id = this.$route.params.id 
            }
        },
        mounted (){
            get(this.id).then(res=>{
                this.account = res.data;
                this.sponsorToChange = this.account.sponsor;
                this.account.isVerified = (res.data.isVerified)? "Si": "No";
                this.account.isContractVerified = (res.data.isContractVerified)? "Si": "No";
             
                this.getActivePackages(this.account.id);
            });

            getPackages().then((res) => {
                res.data.map((value)=>{
                    this.packages.push(value);                    
                })
            });
          
        },
        data (){
            return {
                search: "",
                deleteDialog: {
                    visible: false,
                    loading: false
                   
                },
                rechargePackagesDialog: {
                    visible: false,
                    activation: false,
                    loading: false,  
                },
                updateUserBuyerLoading:false,
                updateOperatorLoading:false,
                updateClientTypeLoading:false,
                downloadIDLoading: false,
                sponsorToChange: "",
                packages: [],
                account: {},
                
                addEditSponsor: {
                    visible: false,
                    loading: false,
                    user: {},
                },
                filteredPackages:[],
                alreadyEdited: false,
                headersPackages: [
                    {text:  "Nome pacchetto", value: "rechargePackage.name", type: "string"},
                    { text: "Crediti iniziali", value: "initialCredits", type: "number", filterActive: false },
                    { text: "Crediti residui", value: "residualCredits", type: "number", filterActive: false },
                ],
            }
        },
        methods: {
            deleteAccount(){
                this.deleteDialog.visible = true;
            },
            cancelDelete(){
                this.deleteDialog.visible = false;
            },
            sendDelete(){
                this.deleteDialog.loading = true;

                deleteAccount(this.id).then(res=>{
                    this.$router.push("/administration/users/").catch(err=>{});
                }).catch(err=>{
                    this.$store.dispatch('alert/error', 'Errore eliminazione account', {root: true})
                }).finally(()=>{
                    this.deleteDialog.loading = false;
                    this.deleteDialog.visible = false;
                })


            },
         
            dateToYMD (date) {
                const dateObj = date ? new Date(date) : new Date();
                var d = dateObj.getDate();
                var m = dateObj.getMonth() + 1; //Month from 0 to 11
                var y = dateObj.getFullYear();
                return '' + (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
            },
            refreshAccount () {
                getAccount(this.id).then(res=>{
                    this.$nextTick(()=>{
                        this.account = res.data;
                        this.account.isVerified = (res.data.isVerified)? "Si": "No";
                    })
                });
            },
          
            showCreditsDialog () {
                this.creditsDialog.visible = true;
            },
           
            
            focusOut () {
               this.rechargePackagesDialog.visible = false;
            },
            verifyEmail () {
                verifyEmailManual(this.id).then(res=>{
                    this.account.isVerified = "Si";
                }).catch(err=>{
                    this.$store.dispatch('alert/error', 'Errore nella verifica', {root: true})
                    this.account.isVerified = "No";
                })
            },
            verifyContract () {
                verifyContract(this.id).then(res=>{
                    this.account.isContractVerified = "Si";
                }).catch(err=>{
                    this.$store.dispatch('alert/error', 'Errore nella verifica', {root: true})
                    this.account.isVerified = "No";
                })
            },
            editSponsor () {
                this.addEditSponsor = {
                visible: true,
                loading: false,
                user: this.account
                }
            },
            editSponsorAction (action) {
               if (action) {
                    this.addEditSponsor.loading = true
                    changeSponsor(this.account.id, this.sponsorToChange)
                    .then(res=>{
                        this.account.sponsor = this.sponsorToChange;
                    })
                    .catch(err=>{
                        this.sponsorToChange = this.account.sponsor;
                        this.$store.dispatch('alert/error', 'modifica sponsor non riuscita', {root: true})
                    })
                    .finally(c => {
                        this.addEditSponsor = {
                        visible: false,
                        loading: false,
                        user: {}
                        }
                    })
                } else {
                    this.addEditSponsor = {
                        visible: false,
                        loading: false,
                        user: {}
                    }
                   
                }
            },
            downloadUserDocuments () {
                this.downloadIDLoading = true
                downloadUserDocs(this.account.id)
                    .then(response => {
                        this.registrationComplete = true;
                        let fileName = response.headers["content-disposition"].split(
                        "filename="
                        )[1];
                        fileName = fileName.substring(0, fileName.indexOf(";"));
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        var file = new Blob([response.data]);
                        var fileURL = URL.createObjectURL(file);
                        a.href = fileURL;
                        a.download = fileName.split('"').join("");
                        a.click();
                    })
                    .catch(err => {console.log(err)})
                    .finally(f => { this.downloadIDLoading = false})
            },
            getActivePackages(){
                getActivePackages(this.account.id)
                .then(res =>{      
                    this.filteredPackages = res.data;
                })
                .catch(err => {console.log(err)})
            },
            showAddAccountPackageDialog () {
                this.rechargePackagesDialog.visible = true;
            },
            rechargeAccountPackage(){
                var packageChoosed;
                this.packages.forEach(p=>{
                    if(p.name == this.rechargePackagesDialog.package){
                        packageChoosed = p;
                    }
                });
                if (packageChoosed != null){
                    insertNewAccountPackage(this.account.id, packageChoosed)
                    .then(r=>{
                        this.getActivePackages(this.account.id);
                    }).catch(err=>{
                        this.$store.dispatch('alert/error', "Errore aggiunta pacchetto");
                    }).finally(()=>{
                        this.rechargePackagesDialog.loading = false;
                        this.rechargePackagesDialog.visible = false;
                    });

                }

            }
           
            
           
            /*sendCredentialMail(){
                this.loadingCredentialMail = true;
                sendCredentialMail(this.account.id)
                .finally(c => {
                    this.loadingCredentialMail = false;
                })
            }*/
        }
       
    }
  </script>
  