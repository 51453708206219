import Vue from 'vue';
import Router from 'vue-router';

import DefaultLayout from '../views/layouts/Default'
import AuthLayout from '../views/layouts/AuthLayout'
import NotFoundLayout from '../views/layouts/NotFound'
import ForgotPasswordPage from '../views/auth/ForgotPassword'


//import Home from '../components/Home'
import LoginPage from '../views/auth/Login'
import RegisterPage from '../views/auth/Register'
import DashboardPage from '../views/dashboard'
import VerifyEmailPage from '../views/auth/VerifyEmail'
import ResetPasswordPage from '../views/auth/ResetPassword'
import CheckTermsAndCondPage from '../views/auth/CheckTermsAndConditions'

import AccountEditPage from '../views/accounts/edit'

import NewModulePage from '../views/modules/new'
import EditDraftPage from '../views/modules/edit'
import DraftsPage from '../views/modules/drafts'
import SentPage from '../views/modules/sent'
import ElaboratePage from '../views/modules/elaborate'


import PackagesPage from '../views/recharge-packages/index'
import PaymentPage from '../views/payments/index'
import WalletPage from '../views/wallet/index.vue'

import HelpPage from '../views/help'
import DocsPage from '../views/docs'

//administration links

import AdminReceivedModulesPage from '../administration/modules/received'
import AdminCompletedModulesPage from '../administration/modules/completed'
import AdminEditModulePage from '../administration/modules/edit'
import AdminGetUsersPage from '../administration/users/list'
import AdminUsersViewPage from '../administration/users/view'
import AdminPaymentsPage from '../administration/payments/list'

 



Vue.use(Router);

var router = new Router({
 routes: [
    {
        path: '',
        component: AuthLayout,
        meta: {
            requiresAuth: false
        },
        children: [
            {
                path: '/login',
                component: LoginPage,
            },
            {
                path: '/register',
                component: RegisterPage,
            },
            {
                path: '/forgot-password',
                component: ForgotPasswordPage,
            },
            {
                path: '/verify-email/:token',
                component: VerifyEmailPage
            },
            {
                path: '/reset-password/:token',
                component: ResetPasswordPage,
                
            },
            {
                path: '/terms-conditions/:userId',
                name: 'TeC',
                component: CheckTermsAndCondPage,
                
            }
        ]
    },
    {
        path: '/',
        component: DefaultLayout,
        meta: {
            requiresAuth: true
        },
        children: [
            // start administration links

            {
                path: '/administration/modules/received',
                component: AdminReceivedModulesPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/administration/modules/completed',
                component: AdminCompletedModulesPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/administration/modules/edit/:id',
                component: AdminEditModulePage,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/administration/users',
                component: AdminGetUsersPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'ad-users-view',
                path: 'administration/users/:id',
                component: AdminUsersViewPage,
                meta: {
                  requiresAuth: true
                }
              },
              {
                path: '/administration/payments',
                component: AdminPaymentsPage,
                meta: {
                  requiresAuth: true
                }
              },
            // end administration links
            
            {
                path: '/dashboard',
                component: DashboardPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/accounts/edit',
                component: AccountEditPage,
                meta: {
                    requresAuth: true
                }
            },
            {
                path: '/modules/new',
                component: NewModulePage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/modules/edit/:id',
                component: EditDraftPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/modules/drafts',
                component: DraftsPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/modules/sent',
                component: SentPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/modules/elaborate',
                component: ElaboratePage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/packages',
                component: PackagesPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/wallet/:id',
                component: WalletPage,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/payments/:type',
                component: PaymentPage,
                meta: {
                  requiresAuth: true
                },
            },
            {
                path: '/help',
                component: HelpPage,
                meta: {
                  requiresAuth: true
                },
            },
            {
                path: '/utility-docs',
                component: DocsPage,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    { 
        path: '/:pathMatch(.*)*', 
        name: 'not-found', 
        component: NotFoundLayout
    },
]})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user');
    if(to.meta.requiresAuth && !loggedIn || (!loggedIn && to.path=='/')){
        return next('/login')
    }

    const user = JSON.parse(loggedIn);

    if(user){
        if (to.path == '/') {
            switch(user.role){
                case "SuperAdmin":
                case "Admin":
                    return next('administration/modules/received');
                break;
                default:
                    return next('/dashboard')
                break;
            }
        }
    }
    
    next();
})
export default router

