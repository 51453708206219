import api from './baseApi'

export function authenticate(payload) {
    return api.post(`Accounts/login`, payload)
}

export function register(payload) {
    return api.post(`Accounts/register`, payload, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export function downloadContract(user) {
    return api.downloadPOST(`Accounts/download-contract`, user)
}

