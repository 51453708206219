<template>
    <div v-if="!registrationComplete">
      <v-card-title class="font-weight-light headline pa-0 mb-5">
          Registrazione nuovo utente
      </v-card-title>
      <v-card-subtitle class="font-weight-light pa-0 mb-5">
          Compila con cura tutti i campi
      </v-card-subtitle>
          <v-stepper
            vertical
            v-model="step"
          >
              <v-stepper-step
                  :complete="step > 1"
                  step="1">
                  Dati Account
              </v-stepper-step>
              <v-stepper-content step="1">
                  <v-form
                  ref="datiAccount"
                  lazy-validation>
                  <br> 
                      <v-row dense>
                       
                          <v-col
                              cols="12"
                              xs="12"
                              md="6"
                          >
                              <v-text-field
                                  outlined
                                  label="Nome"
                                  v-model="user.firstName"
                                  :rules="requiredField"
                              />
                          </v-col>
                          <v-col
                          cols="12"
                          xs="12"
                          md="6"
                          >
                              <v-text-field
                                  outlined
                                  label="Cognome"
                                  v-model="user.lastName"
                                  :rules="requiredField"
                              />
                          </v-col>
                          <v-col cols="12">
                              <v-text-field
                                  outlined
                                  label="Email"
                                  v-model="user.email"
                                  :rules="[...requiredField, ...emailField]"
                              />
                          </v-col>
                          <v-col cols="12">
                              <v-text-field
                                  outlined
                                  label="Telefono"
                                  v-model="user.phone"
                                  :rules="[...requiredField, ...phoneField]"
                              
                              />
                          </v-col>
                          <v-col cols="12">
                              <v-text-field
                                  outlined
                                  label="Sponsor"
                                  v-model="user.sponsor"
                              />
                          </v-col>
                          <v-col cols="12">
                              <v-text-field
                                  outlined
                                  label="Password"
                                  type="password"
                                  v-model="user.password"
                                  :rules="[...requiredField, ...passwordField]"
                              />
                          </v-col>
                          <v-col cols="12">
                              <v-text-field
                                  outlined
                                  label="Ripeti Password"
                                  type="password"
                                  v-model="user.confirmPassword"
                                  :rules="[...requiredField, ...confirmPasswordField]"
                              />
                          </v-col>
                          <div class="my-1">
                              <v-btn
                                  rounded
                                  color="primary"
                                  @click="$refs.datiAccount.validate() && step++"
                              >
                                Avanti
                              </v-btn>
                          </div>
                      </v-row>
                      
                  </v-form>
              </v-stepper-content>
              <v-stepper-step
                  :complete="step > 2"
                  step="2"
              >
                    Dati Fatturazione
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-form
                    ref="datiFatturazione"
                    lazy-validation
                >  <br> 
                    <v-row dense>

                        <v-col cols="12">
                            <v-text-field
                                outlined
                                label="Ragione Sociale"
                                v-model="user.businessName"
                                :rules="requiredField"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                :items="legalForms"
                                outlined
                                label="Forma Giuridica"
                                v-model="user.legalForm"
                                :rules="requiredField"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-text-field
                                outlined
                                label="Via"
                                v-model="user.street"
                                :rules="requiredField"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            >
                            <v-text-field
                                outlined
                                label="Paese"
                                v-model="user.village"
                                :rules="requiredField"
                                required
                                />
                            </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-autocomplete
                                outlined
                                label="Comune"
                                item-value="id"
                                item-text="label"
                                v-model="selectedComune"
                                :items="this.municipalities"
                                :rules="requiredField"
                                @change="manageComune"
                                required
                            />
                          
                        </v-col>
                        <v-col cols="12" xs="12" md="12"
                            v-if="selectedComune=='47890_San Marino_SM'"
                        >
                        <v-alert
                                dense
                                outlined
                                type="warning"
                               
                            >
                                Se necessario specificare dati diversi è possibile modificarli una volta creato il profilo nella sezione “Profilo” senza vincoli d’inserimento
                            </v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" md="12">
                            <v-select 
                                outlined
                                label="Regione"
                                :rules="requiredField"
                                :items="regions" 
                                v-model="user.region"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        >
                            <v-text-field
                                outlined
                                label="Codice Fiscale"
                                :rules="requiredField"
                                v-model="user.sSNCompany"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                outlined
                                label="Partita IVA"
                                v-model="user.vat"
                                :rules="[...requiredField, ...vatField]"
                                    />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                outlined
                                label="PEC"
                                v-model="user.pec"
                                :rules="[...requiredField, ...emailField]"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                outlined
                                label="Codice Univoco"
                                v-model="user.uniqueCode"
                                :rules="[...requiredField, ...uniqueCodeField]"
                            />
                        </v-col>
                      
                    </v-row>
                    <div class="my-1">
                        <v-btn
                        rounded
                        color="primary"
                        @click="$refs.datiFatturazione.validate() && step++"
                        >
                        Avanti
                        </v-btn>
                        <v-btn
                        rounded
                        @click="step = 1"
                        text
                        >
                            Indietro
                        </v-btn>
                    </div>
                </v-form>         
                </v-stepper-content>
                <v-stepper-step
                    :complete="step > 3"
                    step="3"
                >
                    Legale Rappresentante
                </v-stepper-step>
                <v-stepper-content step="3">
                    <v-form
                    ref="legaleRappresentante"
                    lazy-validation
                    >  <br> 
                        <v-row dense>
                            <v-col   
                            cols="12"
                            md="6">
                                <v-text-field
                                    outlined
                                    label="Nome Rappresentante"
                                    v-model="user.attorneyFirstName"
                                    :rules="requiredField"
                                />
                            </v-col>
                            <v-col   
                            cols="12"
                            md="6">
                                <v-text-field
                                    outlined
                                    label="Cognome Rappresentante"
                                    v-model="user.attorneyLastname"
                                    :rules="requiredField"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                            <v-text-field
                                outlined
                                label="Paese Residenza Rappresentante"
                                v-model="user.attorneyVillage"
                                :rules="requiredField"
                                required
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                            <v-autocomplete
                                outlined
                                label="Comune Residenza Rappresentante"
                                item-value="id"
                                item-text="label"
                                v-model="selectedAttorneyMunicipality"
                                :items="this.municipalities"
                                :rules="requiredField"
                                @change="manageAttorneyMunicipality"
                                required
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="12"
                                    v-if="selectedAttorneyMunicipality=='47890_San Marino_SM'"
                            >
                            <v-alert
                                    dense
                                    outlined
                                    type="warning"
                                
                                >
                                    Se necessario specificare dati diversi è possibile modificarli una volta creato il profilo nella sezione “Profilo” senza vincoli d’inserimento
                                </v-alert>
                            </v-col>
                            <v-col cols="12" xs="12" md="12">
                                <v-select 
                                    outlined
                                    label="Regione Residenza Rappresentante"
                                    :rules="requiredField"
                                    :items="regions" 
                                    v-model="user.attorneyRegion"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    label="Codice Fiscale Rappresentante"
                                    v-model="user.attorneySsn"
                                    :rules="[...requiredField, ...ssnField]"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :items="idTypes"
                                    outlined
                                    label="Documento di identificazione"
                                    v-model="user.attorneyIDCardType"
                                    :rules="requiredField"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-menu
                                    ref="menuExpiryDate"
                                    v-model="menuExpiryDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="formattedDate"
                                            label="Data scadenza Documento di identità"
                                            placeholder=" "
                                            :rules="requiredField"
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                <v-date-picker
                                    ref="picker"
                                    v-model="user.attorneyIDCardExpiryDate"
                                    :min="currentDate"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Numero Documento di identità"
                                outlined
                                v-model="user.attorneyIDCardNumber"
                                placeholder=" "
                                :rules="requiredField"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        >
                            <v-text-field
                                outlined
                                label="Comune di rilascio Documento di identità"
                                v-model="user.attorneyIDCardIssuingAuthority"
                                placeholder=""
                                :rules="requiredField"
                                required
                            />
                         </v-col>
                         <v-col
                            cols="12"
                            xs="12"
                            md="6"
                        >
                        <v-file-input
                            outlined
                            chips
                            :rules="[
                            (value) =>
                                !value ||
                                value.size < 40000000 ||
                                'Dimensione massima consentita di 40MB superata!',
                            ...contractField,
                            ]"
                            ref="ciFrontFile"
                            v-model="ciFront"
                            label="Documento di identità fronte"
                        />
                        </v-col>
                        <v-col
                            cols="12"
                            xs="12"
                            md="6"
                        >
                        <v-file-input
                            outlined
                            chips
                            :rules="[
                            (value) =>
                                !value ||
                                value.size < 40000000 ||
                                'Dimensione massima consentita di 40MB superata!',
                            ...contractField,
                            ]"
                            ref="ciRetroFile"
                            v-model="ciRear"
                            label="Documento di identità retro"
                        />
                        </v-col>
                        
                        </v-row>
                        <br>
                        <div class="my-1">
                            <v-btn
                            rounded
                            color="primary"
                            :loading="loading"
                            @click="$refs.legaleRappresentante.validate() && step++"
                            >
                            Avanti
                            </v-btn>

                            <v-btn
                            rounded
                            @click="step = 2"
                            text
                            >
                            Indietro
                            </v-btn>
                        </div>
                    </v-form>
                </v-stepper-content>
                <v-stepper-step
                    :complete="step > 4"
                    step="4"
                >
                    Contratto
                </v-stepper-step>
                <v-stepper-content step="4">
                    <v-form
                    ref="contractAndPrivacy"
                    lazy-validation
                    >
                        <v-btn 
                            rounded color="primary"
                            :loading="downloadContractLoading"
                            @click="downloadContract">
                            Scaricare contratto precompilato
                        </v-btn>

                    <v-col
                            cols="12"
                            xs="12"
                            md="12"
                        >
                        <v-file-input
                            outlined
                            chipss
                            :rules="[
                            (value) =>
                                !value ||
                                value.size < 40000000 ||
                                'Dimensione massima consentita di 40MB superata!',
                            ...contractField,
                            ]"
                            ref="contractFile"
                            v-model="contract"
                            label="Contratto firmato"
                        />
                    </v-col>
                    

                    </v-form>

                <br>
                    <div class="my-1">
                        <v-btn
                        rounded
                        color="primary"
                        :loading="loading"
                        @click="$refs.contractAndPrivacy.validate() && submit()"
                        >
                        Registrati
                        </v-btn>

                        <v-btn
                        rounded
                        @click="step = 3"
                        text
                        >
                        Indietro
                        </v-btn>
                    </div>
                </v-stepper-content>
          </v-stepper>
    </div>
    <div v-else>
      <v-card-title class="font-weight-light headline pa-0 mb-5">
        <v-icon
          color="success"
          class="mr-3"
        >
          fas fa-check-circle
        </v-icon>
        Registrazione completata!
      </v-card-title>
      <v-card-subtitle class="font-weight-light pa-0 mb-5">
        Controlla la tua mail per confermare la registrazione
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          class="px-0"
          text
          @click="$router.push('login')"
        >
          Torna al login
        </v-btn>
      </v-card-actions>
      
    </div>
  
  </template>
  
<script>
    import { mapGetters } from "vuex";
    import { register, downloadContract } from '@/api/auth.api.js'
    import Account from "../../models/Account";
    import LegalForms from "../../models/LegalForms";
    import IDTypes from "../../models/IDTypes";
    import Regions from '../../models/Regions';
    export default {
        name: 'Register',
        data(){
            return {
                step:1,
                regions: Regions,
                user: new Account(),
                selectedComune: null,
                downloadContractLoading:false,
                selectedAttorneyMunicipality: null,
                requiredField: [(v) => !!v || "Campo Obbligatorio!"],
                contractField: [(v) => !!v || "Caricare documento!"],
                emailField: [
                    (v) =>
                    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || "Inserire Email Valida",
                ],
                ssnField: [
                    (v) =>
                    !!(v && v.length === 16) ||
                    "Il codice fiscale deve essere composto da 16 caratteri",
                ],
                uniqueCodeField: [
                    (v) =>
                    !!(v && v.length === 7) ||
                    "Il codice univoco deve essere composto da 7 caratteri",
                ],
                zipCodeField: [
                    (v) => !!(v && v.length <= 9) || "Inserire Codice Postale Valido",
                ],
                passwordField: [
                    (v) =>
                    !!(v && v.length > 7) ||
                    "La password deve essere composta da almeno 8 caratteri",
                ],
                confirmPasswordField: [
                    (v) => v === this.user.password || "Le password devono coincidere",
                ],
                phoneField: [
                    (value) => {
                        const pattern = /^(\d{10})$/;
                        return pattern.test(value) || "Inserire numero di telefono valido.";
                    }
                ],
                ssnRule: [
                    (v) => {
                    if (v && !this.user.taxCode) {
                        if (v.length === 11 || v.length === 16) {
                        return true;
                        }
                        return "Il codice fiscale deve essere composto da 11 o da 16 caratteri";
                    }
                    if (!v && this.user.taxCode) {
                        return true;
                    }
                    if (v && this.user.taxCode) {
                        if (v.length === 11 || v.length === 16){
                        return true;
                        }
                        return "Il codice fiscale deve essere composto da 11 o da 16 caratteri";
                    }
                    return "Codice Fiscale o/e P.IVA Richiesti";
                    },
                ],
                vatField: [
                    (v) =>
                    !!(v && v.length === 11) ||
                    "La partita IVA deve essere composta da 11 caratteri",
                ],
                
                ciFront: null,
                ciRear: null,
                contract: null,
                successful: false,
                loading: false,
                message: '',
                legalForms: LegalForms,
                idTypes: IDTypes,
                menuExpiryDate: false,
                registrationComplete: false
            }
        }, 
        mounted() {
            if (this.loggedIn){
                this.$router.push('/dashboard').catch(err=>{})
            }
        },  
        computed:{
            ...mapGetters({
                municipalities: "municipalities/getAll" 
            }),
            formattedDate() {
                return this.user.attorneyIDCardExpiryDate
                    ? this.user.attorneyIDCardExpiryDate.split("-").reverse().join("/")
                    : "";
            },
            currentDate() {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1;
                var yyyy = today.getFullYear();
                if (dd < 10) {
                    dd = "0" + dd;
                }
                if (mm < 10) {
                    mm = "0" + mm;
                }
                return `${yyyy}-${mm}-${dd}`;
            },
        },
        created() {
            this.$store.dispatch("municipalities/getAllMunicipalities"); 
        }, 
        methods:{
            manageComune(){ 
                console.log(this.selectedComune);
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedComune)
                    .then(
                        response => {
                        this.user.zip = response[0];
                        this.user.city = response[1];
                        this.user.province = response[2];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            manageAttorneyMunicipality(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedAttorneyMunicipality)
                    .then(
                        response => {
                        this.user.attorneyZip = response[0];
                        this.user.attorneyCity = response[1];
                        this.user.attorneyProvince = response[2];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            downloadContract() {
                this.downloadContractLoading = true;
                downloadContract(this.user)
                .then((res) => {
                    let fileName = res.headers["content-disposition"].split("filename=")[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    var file = new Blob([res.data],{ type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    document.body.appendChild(a);
                 
                    a.click();
                })
                .catch(e => {
                    console.log(e);
                    this.$store.dispatch('alert/error', "Errore Download Lettera di conferimento incarico");
                })
                .finally((_) => {
                    this.downloadContractLoading = false;
                });
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                formData.append('user', JSON.stringify(this.user))
                if (this.ciFront && this.ciFront !== undefined) {
                    const ext = this.ciFront.name.split(".").pop();
                    const fileName = `I.D. FRONTE ${this.user.attorneyLastname}_${this.user.attorneyFirstName}.${ext}`;
                    
                    formData.append("ciFront", this.ciFront, fileName);
                }
                if (this.ciRear && this.ciRear !== undefined) {
                    const ext = this.ciRear.name.split(".").pop();
                    const fileName = `I.D. RETRO ${this.user.attorneyLastname}_${this.user.attorneyFirstName}.${ext}`;
                    formData.append("ciRear", this.ciRear, fileName);
                }
                if (this.contract && this.contract !== undefined) {
                    const ext = this.contract.name.split(".").pop();
                    const fileName = `CONTRATTO ${this.user.attorneyLastname}_${this.user.attorneyFirstName}.${ext}`;
                    formData.append("contract", this.contract, fileName);
                }
                register(formData)
                .then(
                    () => {
                        this.registrationComplete = true;
                    },
                    (error) => {
                         this.$store.dispatch('alert/error', error.response.data.message, {root: true})
                    }
                )
                .finally(() => {
                    this.loading = false
                })
            }
        }
    }
</script>