<template>
  <v-card>
    <v-toolbar
      color="primary"
      dark
    >
      <v-card-title>
        Documenti utili
      </v-card-title>
    </v-toolbar>
    <v-card-text class="subtitle-1">
      <br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Breve procedura per l utilizzo della piattaforma ENEA rev2.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Breve procedura per l'utilizzo della piattaforma ENEA
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Elenco produttori per impianto di raffrescamento.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Elenco produttori per impianto di raffrescamento
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Elenco produttori per impianto di riscaldamento.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Elenco produttori per impianto di riscaldamento
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Luigi Costantini assicurazione professionale.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Luigi Costantini assicurazione professionale
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Luigi Costantini Carta d identità.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Luigi Costantini Carta d'identità
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/COSIMO BIANCHI ASSICURAZIONE PROFESSIONALE.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Cosimo Bianchi assicurazione professionale
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/COSIAMO BIANCHI CARTA IDENTITA.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Cosimo Bianchi Carta d'identità
          </v-btn>
      </v-row>

      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/facsimile dichiarazione congruita della spesa.zip')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Facsimile dichiarazione congruità della spesa
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/facsimile dichiarazione requisiti tecnici.zip')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Facsimile dichiarazione requisiti tecnici
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Guida al recupero della visura catastale.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Guida al recupero della visura catastale
          </v-btn>
      </v-row>
      <br><br>
      <v-row >
        <v-btn
            rounded
            color="success"
            @click="openInNewTab('/assets/Guida al recupero delle informazioni del generatore di calore.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Guida al recupero delle informazioni del generatore di calore
          </v-btn>
      </v-row>
      </v-card-text>
   
 
  </v-card>
</template>
<script>
 import { mapGetters } from "vuex";
export default {
  data () {
    return {
    
    }
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
  },
  methods: {
    openInNewTab (href) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: href,
      }).click();
    }
  }

}
</script>