<template>
  <div>
  <v-card>
    <v-toolbar
      color="primary"
      dark
    >
      <v-card-title>
        Assistenza
      </v-card-title>
    </v-toolbar>
    <v-card-text class="subtitle-1">
      <ul>
        <li>Per informazioni o comunicazioni relative alle pratiche, siamo disponibili alla mail: <a href="mailto:info@eneapraticheserramenti.com">pratiche@asseverazionibarrierearchitettoniche.it</a></li>
        <li>In caso di problemi generali <b>sull’utilizzo della piattaforma e il caricamento dei dati</b> siamo disponibili alla mail: <a href="mailto:info@eneapraticheserramenti.com">info@asseverazionibarrierearchitettoniche.it</a></li>
        <li> Per informazioni o comunicazioni relative <b>a pagamenti e acquisti dei servizi</b> siamo disponibili alla mail: <a href="mailto:amministrazione@ambrosipartner.com">amministrazione@ambrosipartner.com</a></li>
      </ul>

    </v-card-text>
  </v-card>
  <br><br>
  <v-card>
    <v-toolbar
      color="primary"
      dark
    >
      <v-card-title>
        Manuale
      </v-card-title>
    </v-toolbar>
    <v-card-text class="subtitle-1">  
      <v-row>
        <v-col>
          <v-btn
            rounded
            color="success"
            :loading="contractLoading"
            @click="openInNewTab('/assets/Manuale caricamento pratica easy 75%.pdf')"
          >
            <v-icon
              left
              dark
            >
              fas fa-file-download
            </v-icon>Manuale caricamento pratica easy 75%
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</div>
</template>
<script>
export default {
  data () {
    return {
    
    }
  },
  methods: {
    openInNewTab (href) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: href,
      }).click();
    }
  }

}
</script>