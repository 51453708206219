<template>
    <div>
        <v-stepper class="remove-shadow" vertical v-model="stepperPosition">
            <v-stepper-step
                editable
                edit-icon="fa-check"
                :complete="stepperPosition > 0"
                :rules="[validateZeroStep]"
                :step=0
            >
                <p class="ma-0">Quale servizio intendi richiederci?</p>

            </v-stepper-step>
            <v-stepper-content step="0" id="content-tab0">
                <div style="color: #1976d2;">
                    Se usi il programma Fattura Infissi, hai la possibilità di esportare un file XML
                    contenente la maggior parte delle informazioni richieste nella pratica; in questo modo la
                    compilazione sarà molto più veloce e precisa!
                </div>
                <v-file-input
                    outlined
                    chips               
                    v-model="fileXml"
                    id="fileXml"
                    label="File xml fattura infissi"
                    @change="fileXmlUploaded"
                />  
     
                <br><br>
                <v-row>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Seleziona il servizio"
                            :error-messages="serviceTypeErrors"
                            :items="serviceTypes" 
                            v-model="module.serviceType"
                            @change="updatedService"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Anno di riferimento"
                            v-model="module.annoRiferimento"
                            error-count="2"
                            :error-messages="(!module.annoRiferimento)?['Campo obbligatorio', 'NOTA: l’anno di riferimento è l’anno di conclusione dei lavori']:[]"
                            :messages="['NOTA: l’anno di riferimento è l’anno di conclusione dei lavori']"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-select 
                            outlined
                            label="Seleziona la tipologia di beneficiario"
                            :error-messages="beneficiaryTypeErrors"
                            :items="beneficiaryTypes" 
                            v-model="module.isCompany"
                        />
                    </v-col>
                
                    <v-col cols="12" xs="12" md="6">
                        <v-checkbox
                            v-model="module.acquisizioneBeneficiarioAmbrosi"
                            label="Servizio acquisizione dati beneficiario da parte di Ambrosi Partner"
                        ></v-checkbox>
                    </v-col>

                    

                    <v-col cols="12" align="end">
                        <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition++">Avanti</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled"
                edit-icon="fa-check"
                :complete="stepperPosition > 1"
                :rules=[validateFirstStep]
                :step=1
                id="tab1"
            >
                <p class="ma-0">Anagrafica del beneficiario</p>
            </v-stepper-step>
            <v-stepper-content step="1">
                <div style="color: #1976d2;">
                    Per l’inserimento di questi dati avvalersi del documento d’identità e del codice fiscale. Nel caso in cui il beneficiario sia un’azienda servono i documenti del legale rappresentante e la visura camerale
                </div>
                <br><br>
                
                <v-row v-if="module.isCompany">
                    <v-col cols="12">
                        <v-select 
                            outlined
                            label="Seleziona la tipologia di persona giuridica"
                            :error-messages="beneficiaryCompanyTypeErrors"
                            :items="beneficiaryCompanyTypes" 
                            v-model="module.beneficiaryCompanyType"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Ragione sociale"
                            v-model="module.beneficiaryCompanyName"
                            :error-messages="(!module.beneficiaryCompanyName)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Codice fiscale azienda"
                            v-model="module.beneficiarySsn"
                            required
                            :error-messages="(!module.beneficiarySsn)?['Campo obbligatorio']:[]"
                           
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Nome rappresentante"
                            v-model="module.beneficiaryAttorneyFirstName"
                            :error-messages="(!module.beneficiaryAttorneyFirstName)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Cognome rappresentante"
                            v-model="module.beneficiaryAttorneyLastName"
                            :error-messages="(!module.beneficiaryAttorneyLastName)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Codice fiscale rappresentante"
                            v-model="module.beneficiaryAttorneySsn"
                            required
                            :error-messages="beneficiaryAttorneySsnErrors"
                            @input="$v.module.beneficiaryAttorneySsn.$touch()"
                            @blur="$v.module.beneficiaryAttorneySsn.$touch()"
                            @change="readAttorneySSN()"  
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12"  xs="12" md="6">
                        <v-select 
                            outlined
                            label="Genere"
                            :error-messages="beneficiaryAttorneyGenderErrors"
                            :items="beneficiaryGenders" 
                            v-model="module.beneficiaryAttorneyGender"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuBirthDate"
                            v-model="menuBirthDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedBeneficiaryAttorneyBirthDate"
                                    label="Data di nascita"
                                    placeholder=" "
                                    :error-messages="beneficiaryAttorneyBirthDateErrors"
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.beneficiaryAttorneyBirthDate"
                                
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <!-- TEXT FIELD PERCHè PRESO IN AUTOMATICO DA CF -->
                        <v-text-field
                            label="Comune di nascita"
                            v-model="module.beneficiaryAttorneyBirthMunicipality"
                            :error-messages="(!module.beneficiaryAttorneyBirthMunicipality)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <!-- TEXT FIELD PERCHè PRESO IN AUTOMATICO DA CF -->
                        <v-text-field
                            label="Provincia di nascita"
                            v-model="module.beneficiaryAttorneyBirthProvince"
                            :error-messages="(!module.beneficiaryAttorneyBirthProvince)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            label="Nazione di nascita"
                            v-model="module.beneficiaryAttorneyBirthNation"
                            :error-messages="(!module.beneficiaryAttorneyBirthNation)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Numero documento d'identità"
                            v-model="module.beneficiaryIdentificationDocNumber"
                            required
                            :error-messages="(!module.beneficiaryIdentificationDocNumber)?['Campo obbligatorio']:[]" 
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuBeneficiaryIdentificationDocDate"
                            v-model="menuBeneficiaryIdentificationDocDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedBeneficiaryIdentificationDocDate"
                                    label="Data scadenza documento d’identità"
                                    placeholder=" "
                                    :error-messages="beneficiaryIdentificationDocDateErrors"
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.beneficiaryIdentificationDocDate"
                                :min="currentDate"
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Telefono rappresentante legale"
                            type="number"
                            v-model="module.beneficiaryPhone"
                            :error-messages="(!module.beneficiaryPhone)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Nome"
                            v-model="module.beneficiaryFirstName"
                            :error-messages="(!module.beneficiaryFirstName)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>

                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Cognome"
                            v-model="module.beneficiaryLastName"
                            :error-messages="(!module.beneficiaryLastName)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Codice fiscale"
                            v-model="module.beneficiarySsn"
                            required
                            :error-messages="beneficiarySsnErrors"
                            @input="$v.module.beneficiarySsn.$touch()"
                            @blur="$v.module.beneficiarySsn.$touch()"
                            @change="readSSN()" 
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12"  xs="12" md="6">
                        <v-select 
                            outlined
                            label="Genere"
                            :error-messages="beneficiaryGenderErrors"
                            :items="beneficiaryGenders" 
                            v-model="module.beneficiaryGender"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuBirthDate"
                            v-model="menuBirthDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedBeneficiaryBirthDate"
                                    label="Data di nascita"
                                    placeholder=" "
                                    :error-messages="beneficiaryBirthDateErrors"
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.beneficiaryBirthDate"
                                
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <!-- TEXT FIELD PERCHè PRESO IN AUTOMATICO DA CF -->
                        <v-text-field
                            label="Comune di nascita"
                            v-model="module.beneficiaryBirthMunicipality"
                            :error-messages="(!module.beneficiaryBirthMunicipality)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <!-- TEXT FIELD PERCHè PRESO IN AUTOMATICO DA CF -->
                        <v-text-field
                            label="Provincia di nascita"
                            v-model="module.beneficiaryBirthProvince"
                            :error-messages="(!module.beneficiaryBirthProvince)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            label="Nazione di nascita"
                            v-model="module.beneficiaryBirthNation"
                            :error-messages="(!module.beneficiaryBirthNation)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Numero documento d'identità"
                            v-model="module.beneficiaryIdentificationDocNumber"
                            required
                            :error-messages="(!module.beneficiaryIdentificationDocNumber)?['Campo obbligatorio']:[]" 
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuBeneficiaryIdentificationDocDate"
                            v-model="menuBeneficiaryIdentificationDocDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedBeneficiaryIdentificationDocDate"
                                    label="Data scadenza documento d’identità"
                                    placeholder=" "
                                    :error-messages="beneficiaryIdentificationDocDateErrors"
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.beneficiaryIdentificationDocDate"
                                :min="currentDate"             
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Telefono"
                            type="number"
                            v-model="module.beneficiaryPhone"
                            :error-messages="(!module.beneficiaryPhone)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12"></v-col>

                    <v-col cols="12"></v-col>
                    
                </v-row>
                <span v-for="(k, v) in this.module.otherBeneficiaries" style="width:100%">
                    <br>
                    <v-card
                        class="mx-auto"
                        width="99%"
                        title="User Registration"
                        style="padding:10px;"
                    >
                    <p>Nuovo beneficiario {{ v+1 }} <v-btn rounded x-small color="primary" @click="removeNewBeneficiary(v)">Rimuovi beneficiario</v-btn></p>
                        <v-row>
                            <v-col cols="12">
                                <v-select 
                                    outlined
                                    label="Seleziona la tipologia di beneficiario"
                                    :items="beneficiaryTypes" 
                                    v-model="module.otherBeneficiaries[v].isCompany"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="module.otherBeneficiaries[v].isCompany">
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field
                                        label="Ragione sociale"
                                        v-model="module.otherBeneficiaries[v].beneficiaryCompanyName"
                                        :error-messages="(!module.otherBeneficiaries[v].beneficiaryCompanyName)?['Campo obbligatorio']:[]"
                                        outlined
                                        required
                                        placeholder=" "
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Codice fiscale azienda"
                                        v-model="module.otherBeneficiaries[v].beneficiarySsn"
                                        required
                                        :error-messages="(module.otherBeneficiaries[v].beneficiarySsn.length != 16)?['Il codice fiscale deve essere composto da 16 caratteri']:[]"
                                        outlined
                                        placeholder=" "
                                />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field
                                    label="Nome"
                                    v-model="module.otherBeneficiaries[v].beneficiaryFirstName"
                                    :error-messages="(!module.otherBeneficiaries[v].beneficiaryFirstName)?['Campo obbligatorio']:[]"
                                    outlined
                                    required
                                    placeholder=" "
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field
                                    label="Cognome"
                                    v-model="module.otherBeneficiaries[v].beneficiaryLastName"
                                    :error-messages="(!module.otherBeneficiaries[v].beneficiaryLastName)?['Campo obbligatorio']:[]"
                                    outlined
                                    required
                                    placeholder=" "
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Codice fiscale"
                                    v-model="module.otherBeneficiaries[v].beneficiarySsn"
                                    required
                                    :error-messages="(module.otherBeneficiaries[v].beneficiarySsn.length != 16)?['Il codice fiscale deve essere composto da 16 caratteri']:[]"
                                    outlined
                                    placeholder=" "
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- Lista nuovi beneficiari -->
                </span>
                <v-col cols="12">
                    <v-btn rounded color="primary" @click="addNewBeneficiary">Aggiungi beneficiario</v-btn>
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition = (module.acquisizioneBeneficiarioAmbrosi === true)? stepperPosition + +6: stepperPosition + +1">Avanti</v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled && !module.acquisizioneBeneficiarioAmbrosi"
                edit-icon="fa-check"
                :complete="stepperPosition > 2"
                :rules="[validateSecondStep]"
                :step=2
                id="tab2"
            >
                <p class="ma-0">Residenza</p>
            </v-stepper-step>
            <v-stepper-content step="2">
                <div style="color: #1976d2;">
                    Inserire i dati relativi alla residenza fiscale del beneficiario, ovvero quelli usati per l’intestazione delle fatture
                </div>
                <br><br>
                <v-row>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Indirizzo di residenza"
                            v-model="module.beneficiaryResidentialAddress"
                            :error-messages="(!module.beneficiaryResidentialAddress)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                  <!--  <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Numero civico"
                            v-model="module.beneficiaryResidentialAddressNumber"
                            :error-messages="(!module.beneficiaryResidentialAddressNumber)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>-->
                    <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                            outlined
                            label="Comune di residenza"
                            item-value="id"
                            item-text="label"
                            v-model="selectedBeneficiaryResidentialMunicipality"
                            :items="this.municipalities"
                            :error-messages="(!this.selectedBeneficiaryResidentialMunicipality)?['Campo obbligatorio']:[]"
                            @change="manageBeneficiaryResidentialMunicipality"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Nazione di residenza"
                            v-model="module.beneficiaryResidentialNation"
                            :error-messages="(!module.beneficiaryResidentialNation)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition++">Avanti</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled && !module.acquisizioneBeneficiarioAmbrosi"
                edit-icon="fa-check"
                :complete="stepperPosition > 3"
                :rules="[validateThirdStep]"
                :step=3
                id="tab3"
            >
                <p class="ma-0">Ubicazione immobile oggetto dell'intervento</p>
            </v-stepper-step>
            <v-stepper-content step="3">
                <div style="color: #1976d2;">
                    Per l’inserimento di questi dati ci si può avvalere della visura catastale. Se l’indirizzo di residenza ha subito una variazione toponomastica rispetto a quello riportato nella visura, riportare la denominazione corrente. In caso l’intervento abbia comportato la fusione di due o più subalterni indicare i dati catastali dell’immobile così come risultanti dalla variazione catastale presentata al termine dei lavori
                </div>
                <br><br>
                <v-row>
                    <v-col cols="12">
                        <v-btn rounded color="primary" @click="copyResidentialDataToImmovable">Copia dati da residenza beneficiario</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Indirizzo"
                            v-model="module.immovableAddress"
                            :error-messages="(!module.immovableAddress)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>
                    <!--<v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Numero civico"
                            v-model="module.immovableAddressNumber"
                            :error-messages="(!module.immovableAddressNumber)?['Campo obbligatorio']:[]"
                            outlined
                            required
                            placeholder=" "
                        />
                    </v-col>-->
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Scala"
                            v-model="module.immovableStair"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Interno"
                            v-model="module.immovableInterior"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                            outlined
                            label="Comune"
                            item-value="id"
                            item-text="label"
                            v-model="selectedImmovableMunicipality"
                            :items="this.municipalities"
                            :error-messages="(!this.selectedImmovableMunicipality)?['Campo obbligatorio']:[]"
                            @change="manageImmovableMunicipality"
                        />
                    </v-col>
                   
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Zona climatica"
                            v-model="module.immovableClimateZone"
                            :error-messages="(!this.module.immovableClimateZone)?['Campo obbligatorio']:[]"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition++">Avanti</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled && !module.acquisizioneBeneficiarioAmbrosi"
                edit-icon="fa-check"
                :complete="stepperPosition > 4"
                :rules="[validateFourthStep]"
                :step=4
                id="tab4"
            >
                <p class="ma-0">Dati catastali</p>
            </v-stepper-step>
            <v-stepper-content step="4">
                <br>
                <v-row>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Categoria catastale"
                            :items="cadastralCategories" 
                            v-model="module.immovableCadastralCode"
                            @change="onCadastralCategoryChange"
                            :error-messages="(!this.module.immovableCadastralCode)?['Campo obbligatorio']:[]"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Codice comunale"
                            v-model="module.immovableMunicipalityCode"
                            @input="(val) => (module.immovableMunicipalityCode = module.immovableMunicipalityCode.toUpperCase())"
                            :error-messages="(!this.module.immovableMunicipalityCode)?['Campo obbligatorio']:[]"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Sezione"
                            v-model="module.cadastralSection"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Foglio"
                            v-model="module.cadastralSheet"
                            :error-messages="(!this.module.cadastralSheet)?['Campo obbligatorio']:[]"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Particella"
                            v-model="module.cadastralParcel"
                            :error-messages="(!this.module.cadastralParcel)?['Campo obbligatorio']:[]"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Subalterno"
                            v-model="module.cadastralSubordinate"
                            error-count="2"
                            outlined
                            placeholder=" "
                            :messages="['NOTA: è possibile inserire un unico subalterno. Per interventi che si svolgono su più subalterni, ad esempio, abitazione principale e garage oppure due appartamenti adiacenti, anche se dello stesso proprietario, fare fatture separate e creare due pratiche distinte']"
                        />
                    </v-col>
                    
                    <v-col cols="12" align="end">
                        <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition++">Avanti</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled && !module.acquisizioneBeneficiarioAmbrosi"
                edit-icon="fa-check"
                :complete="stepperPosition > 5"
                :rules="[validateFifthStep]"
                :step=5
                id="tab5"
            >
                <p class="ma-0">Proprietà immobile</p>
            </v-stepper-step>
            <v-stepper-content step="5">
                <br>
                <v-row>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Anno stimato di costruzione"
                            type="number"
                            v-model="module.immovableEstimatedYearOfConstruction"
                            :error-messages="(!this.module.immovableEstimatedYearOfConstruction)?['Campo obbligatorio', 'NOTA: se non si è certi dell’anno di costruzione, indicare pure il decennio presunto: 1960, 1970.. per edifici costruiti prima del 1900 inserire pure il secolo presunto: 1600, 1700, 1800']:[]"
                            error-count="2"
                            outlined
                            placeholder=" "
                            :messages="['NOTA: se non si è certi dell’anno di costruzione, indicare pure il decennio presunto: 1960, 1970.. per edifici costruiti prima del 1900 inserire pure il secolo presunto: 1600, 1700, 1800']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Superficie utile dell’immobile oggetto dell’intervento"
                            v-model="module.immovableSurfaceArea"
                            :error-messages="(!this.module.immovableSurfaceArea)?['Campo obbligatorio', 'NOTA: va inserita la superficie coperta dell’immobile nel quale vengono eseguiti i lavori. Se specificata è possibile usare la superficie coperta riportata nella visura catastale per quel subalterno.']:[]"
                            error-count="2"
                            type="number"
                            outlined
                            placeholder=" "
                            suffix="m²"
                            :messages="['NOTA: va inserita la superficie coperta dell’immobile nel quale vengono eseguiti i lavori. Se specificata è possibile usare la superficie coperta riportata nella visura catastale per quel subalterno.']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Numero totale delle unità immobiliari dell’Edificio alla fine dei lavori"
                            type="number"
                            v-model="module.immovableUnitNumber"
                            :error-messages="(!this.module.immovableUnitNumber)?['Campo obbligatorio', 'NOTA: se l’intervento viene eseguito su di un appartamento all’interno di un condominio o di una casa plurifamiliare, qui va inserito il numero di tutte le unità che compongono l’edificio']:[]"
                            error-count="2"
                            outlined
                            placeholder=" "
                            :messages="['NOTA: se l’intervento viene eseguito su di un appartamento all’interno di un condominio o di una casa plurifamiliare, qui va inserito il numero di tutte le unità che compongono l’edificio']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-checkbox
                            v-model="module.immovableMergeUnits"
                            label="Si sono verificati degli accorpamenti di unità immobiliari"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Titolo di possesso"
                            :items="immovablePossessionTitles" 
                            v-model="module.immovablePossessionTitle"
                            :messages="(this.module.immovablePossessionTitle == '3')?  ['NOTA: nel caso in cui il beneficiario sia il promissario acquirente è necessario che il preliminare di vendita sia stato registrato all’Agenzia delle Entrate e il promissario acquirente sia stato immesso nella disponibilità dell’immobile'] : []"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Destinazione d’uso generale"
                            :items="immovableGeneralUses" 
                            v-model="module.immovableGeneralUse"
                            error-count="2"
                            :error-messages="(this.module.immovableGeneralUse == '')?['Campo obbligatorio', 'NOTA: la destinazione d’uso generale riguarda l’immobile nel suo complesso; quindi, se si dovesse trattare di un condominio con al piano terra dei negozi, la scelta corretta sarebbe “edificio ad uso generale MISTO”']:[]"
                            :messages="['NOTA: la destinazione d’uso generale riguarda l’immobile nel suo complesso; quindi, se si dovesse trattare di un condominio con al piano terra dei negozi, la scelta corretta sarebbe “edificio ad uso generale MISTO”']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Tipologia edilizia"
                            :items="buildingTypologies" 
                            v-model="module.immovableBuildingTypology"
                            error-count="2"
                            :error-messages="(this.module.immovableBuildingTypology == '')?['Campo obbligatorio', 'NOTA: la differenza tra edifici in linea e edificio a schiera è che gli edifici in linea condividono le scale che solitamente separano le due unità, mentre le case a schiera solitamente hanno la scala interna all’abitazione']:[]"
                            :messages="['NOTA: la differenza tra edifici in linea e edificio a schiera è che gli edifici in linea condividono le scale che solitamente separano le due unità, mentre le case a schiera solitamente hanno la scala interna all’abitazione']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Destinazione d’uso particolare"
                            :items="immovableParticularUses" 
                            v-model="module.immovableParticularUse"
                            error-count="2"
                            :error-messages="(this.module.immovableParticularUse == '')?['Campo obbligatorio', 'NOTA: la destinazione d’uso particolare riguarda solo l’immobile nel quale vengono eseguiti i lavori. Questo campo viene compilato in automatico in funzione del codice catastale specificato in precedenza']:[]"
                            :messages="['NOTA: la destinazione d’uso particolare riguarda solo l’immobile nel quale vengono eseguiti i lavori. Questo campo viene compilato in automatico in funzione del codice catastale specificato in precedenza']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6" v-if="module.immovableParticularUse == '8'">
                        <v-select 
                            outlined
                            v-if="isEcobonus"
                            label="Destinazione d’uso particolare (categorie catastali di difficile attribuzione)"
                            :items="immovableParticularUsesHardAttributions" 
                            v-model="module.immovableParticularUseHardAttribution"
                            error-count="2"
                            :error-messages="(this.module.immovableParticularUseHardAttribution == '')?['Campo obbligatorio', 'NOTA: la destinazione d’uso particolare riguarda solo l’immobile nel quale vengono eseguiti i lavori. Questo campo viene compilato in automatico in funzione del codice catastale specificato in precedenza']:[]"
                            :messages="['NOTA: la destinazione d’uso particolare riguarda solo l’immobile nel quale vengono eseguiti i lavori. Questo campo viene compilato in automatico in funzione del codice catastale specificato in precedenza']"
                        />
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition++">Avanti</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled && !module.acquisizioneBeneficiarioAmbrosi"
                edit-icon="fa-check"
                :complete="stepperPosition > 6"
                :rules="[validateSixthStep]"
                :step=6
                id="tab6"
            >
                <p class="ma-0">Intervento</p>
            </v-stepper-step>
            <v-stepper-content step="6">
                <br>
                <v-row>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Intervento su"
                            :items="interventionOnImmovableTypes" 
                            v-model="module.interventionOnImmovableType"
                            :error-messages="(!this.module.interventionOnImmovableType)?['Campo obbligatorio', 'NOTA: la differenza tra edifici in linea e edificio a schiera è che gli edifici in linea condividono le scale che solitamente separano le due unità, mentre le case a schiera solitamente hanno la scala interna all’abitazione']:[]"
                            :messages="['NOTA: la differenza tra edifici in linea e edificio a schiera è che gli edifici in linea condividono le scale che solitamente separano le due unità, mentre le case a schiera solitamente hanno la scala interna all’abitazione']"
                            @change="manageInterventionOnImmovableTypes"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuInterventionStartDatePicker"
                            v-model="menuInterventionStartDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedInterventionStartDate"
                                    label="Data inizio lavori"
                                    placeholder=" "
                                    error-count="2"
                                    :error-messages="(!module.interventionStartDate)?['Campo obbligatorio', 'NOTA: in caso di edilizia libera si può inserire la data di rilievo esecutivo delle misure e di verifica di fattibilità dell’intervento, la data del primo bonifico d’acconto, la data di richiesta del titolo abilitativo oppure la data in cui è iniziata l’installazione in cantiere']:[]"
                                    v-bind="attrs"
                                    v-on="on"
                                    :messages="['NOTA: in caso di edilizia libera si può inserire la data di rilievo esecutivo delle misure e di verifica di fattibilità dell’intervento, la data del primo bonifico d’acconto, la data di richiesta del titolo abilitativo oppure la data in cui è iniziata l’installazione in cantiere']"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.interventionStartDate"
                                
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuInterventionEstimatedEndDatePicker"
                            v-model="menuInterventionEstimatedEndDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedInterventionEstimatedEndDate"
                                    label="Data presunta ultimazione dei lavori"
                                    placeholder=" "
                                    v-bind="attrs"
                                    v-on="on"
                                    :messages="['NOTA:  compila questo campo con la data presunta di fine lavori per ricevere delle notifiche relative alla scadenza per l’invio della pratica ENEA']"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.interventionEstimatedEndDate"
                                
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-menu
                            ref="menuInterventionEndDatePicker"
                            v-model="menuInterventionEndDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="formattedInterventionEndDate"
                                    label="Data ultimazione dei lavori (collaudo)"
                                    placeholder=" "
                                    v-bind="attrs"
                                    v-on="on"
                                    :messages="['NOTA:  indicare la data in cui si è completata l’installazione in cantiere. Attenzione: se c’è un titolo abilitativo tale data non dovrebbe successiva a quella riportata nella dichiarazione di fine lavori che il tecnico ha depositato in Comune']"
                                />
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="module.interventionEndDate"
                                
                            />
                        </v-menu>
                    </v-col>
                </v-row>
                <v-col cols="12" align="end">
                    <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn rounded color="primary" :disabled="!isZeroStepFilled" @click="stepperPosition++">Avanti</v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled"
                edit-icon="fa-check"
                :complete="stepperPosition > 7"
                :rules="[validateSeventhStep]"
                :step=7
                id="tab7"
            >
                <p class="ma-0">Manufatti</p>
            </v-stepper-step>
            <v-stepper-content step="7">
                <span v-if="isEcobonus">
                    <div style="color: #1976d2;">
                        <v-alert
                            dense
                            outlined
                            type="info"
                            >
                            NOTA: se i manufatti hanno le stesse caratteristiche, è possibile inserire una sola riga e sommare i m 2 .
                            Per esempio nel caso di infissi che hanno tutti la medesima tipologia di telaio e vetrata “vecchi”
                            e la medesima tipologia ti telaio e vetrata “nuovi” e se su tutte (o nessuna) delle posizioni c’è
                            stata la contemporanea sostituzione della relativa chiusura oscurante, allora è possibile creare
                            una sola riga e sommare i m 2 . Inserire i cassonetti in una riga separata e selezionare come tipo
                            di pannello il pannello opaco.
                     
                        </v-alert>
                        <template>
                        <div class="text-center">
                            <v-dialog
                            v-model="dialogNotaTipoIntervento"
                            width="900"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn rounded color="primary"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon left dark>
                                    fas fa-question-circle
                                </v-icon>  
                                Maggiori info
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                Nota
                                </v-card-title>

                                <v-card-text>
                                    Per la fornitura di serramenti e cassonetti con la contestuale fornitura di chiusure oscuranti devi selezionare solo “Serramenti e infissi”.
                                    <br>Per la fornitura di fornito schermature solari quali veneziane, frangisole e tende tecniche devi selezionare solo “schermature solari”
                                    <br>Per la fornitura di sole chiusure oscuranti quali tapparelle, persiane e scuri senza la contestuale fornitura dell’infisso sullo stesso foro devi selezionare solo “chiusure oscuranti”.
                                    <br>Se l’intervento prevede per alcuni fori la fornitura dei soli serramenti e per altri fori la fornitura delle sole chiusure oscuranti, così come nel caso di fornitura di serramenti e schermature solari, allora allora dovrai selezionare entrambi gli interventi. Andranno fatte due pratiche all’ENEA e quindi ti verrà scalato il costo di due invii.
                                    <br>Se vengono fornite delle schermature solari in un appartamento nel quale è presente un impianto di condizionamento o solo delle chiusure oscuranti in un appartamento nel quale è presente un impianto di riscaldamento, dovremo fare il calcolo del risparmio di energia primaria e quindi le verrà addebitato il costo per quest’operazione.”
                                    <template>
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        MANUFATTI
                                                    </th>
                                                    <th class="text-left">
                                                        PRESENZA CONDIZIONATORE
                                                    </th>
                                                    <th>
                                                        PRESENZA RISCALDAMENTO
                                                    </th>
                                                    <th>
                                                        PRATICA SERRAMENTO
                                                    </th>
                                                    <th>
                                                        PRATICA SCHERMATURE E CHIUSURE
                                                    </th>
                                                    <th>
                                                        CALCOLO ENERGETICO
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>SERRAMENTI E CASSONETTI</td>
                                                    <td>Irrilevante</td>
                                                    <td>Si</td>
                                                    <td>Si</td>
                                                    <td>No</td>
                                                    <td>No</td>
                                                </tr>
                                                <tr>
                                                    <td>SERRAMENTI, CASSONETTI E CHIUSURE SULLO STESSO FORO</td>
                                                    <td>Irrilevante</td>
                                                    <td>Si</td>
                                                    <td>Si</td>
                                                    <td>No</td>
                                                    <td>No</td>
                                                </tr>
                                                <tr>
                                                    <td>SCHERMATURE</td>
                                                    <td>Si</td>
                                                    <td>Irrilevante</td>
                                                    <td>No</td>
                                                    <td>Si</td>
                                                    <td>Si</td>
                                                </tr>
                                                <tr>
                                                    <td>SCHERMATURE</td>
                                                    <td>No</td>
                                                    <td>Irrilevante</td>
                                                    <td>No</td>
                                                    <td>Si</td>
                                                    <td>Si</td>
                                                </tr>
                                                <tr>
                                                    <td>CHIUSURE</td>
                                                    <td>Irrilevante</td>
                                                    <td>Si</td>
                                                    <td>No</td>
                                                    <td>Si</td>
                                                    <td>Si</td>
                                                </tr>
                                                <tr>
                                                    <td>CHIUSURE</td>
                                                    <td>Irrilevante</td>
                                                    <td>No</td>
                                                    <td>No</td>
                                                    <td>Si</td>
                                                    <td>No</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>
                                </v-card-text> 

                                <v-divider></v-divider>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="dialogNotaTipoIntervento = false"
                                >
                                    Chiudi
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </div>
                        <br>
                        </template>
                       
                        A seconda dell’intervento eseguito, potrebbe essere necessario l’invio di più pratiche ENEA.
                        Controlla la nota pop-up per maggiori dettagli.
                        <br><br>
                        <font color="#f00">Se usi il software Fattura Infissi la maggior parte di questi campi si compilerà in automatico.</font>
                    </div>

                   
                </span>
                <span v-else>
                    <div style="color: #1976d2;">
                        <v-alert
                            dense
                            outlined
                            type="info"
                            >
                        NOTA: la pratica ENEA per
                        BonusCasa/Bonus Sicurezza va compilata solamente per le finestre, i portoncini e i
                        cassonetti che separano caldo da freddo. Non vengono quindi richiesti dati relativi alle
                        schermature solari, alle chiusure oscuranti o alle porte interne. Per quegli infissi che
                        hanno tutti la medesima tipologia di telaio e vetrata “vecchi” e la medesima tipologia di
                        telaio e vetrata “nuovi” e che hanno comportato tutti (o nessuno) la contemporanea
                        sostituzione della relativa oscurante è possibile inserire una sola riga e sommare i m 2 dei
                        manufatti. Inserire i cassonetti in una riga separata indicando come tipo di pannello il
                        “pannello opaco”. 
                        </v-alert>
                        
                        <font color="#f00">Se usi il software “Fattura Infissi” questi campi vengono compilati in
                        automatico
                        </font>
                    </div>
                </span>
                <br><br>
                <v-row>
                    <v-col cols="12" xs="12" md="4">
                        <v-select 
                            outlined
                            label="Tipologia di manufatto"
                            :items="manufattoTypes" 
                            v-model="manufattoType"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-text-field
                            label="Numero di righe"
                            type="number"
                            v-model="numberOfSelectedManufatto"
                            @input="$v.numberOfSelectedManufatto.$touch()"
                            @blur="$v.numberOfSelectedManufatto.$touch()"
                            min="0"
                            max="10"
                            :error-messages="(!$v.numberOfSelectedManufatto.maxValue)?['Il numero massimo per ogni tipologia di manufatto è 10']:[]"
                            outlined
                            placeholder=" "
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                        <v-col cols="12">
                            <v-btn rounded color="primary" :disabled="numberOfSelectedManufatto < 1" @click="manageManufatti()">Aggiungi</v-btn>
                        </v-col>
                    </v-col>

                    <v-col cols="12" xs="12" md="12">
                        <v-alert
                            dense
                            outlined
                            type="info"
                            >
                            NOTA: se i manufatti forniti hanno le stesse caratteristiche, è possibile inserire una sola riga e sommare i m 2
                            <font color="#f00">(se usi il software “Fattura Infissi” la maggior parte di questi campi vengono compilati in automatico)</font>
                           
                        </v-alert>
                    </v-col>

                </v-row>
                <v-chip color="red" outlined v-if="manufattiError">
                    {{ manufattiError }}
                </v-chip>





                <span v-if="module['manufatti']['serramenti'].length >= 1">
                    <!--<div style="color: #1976d2;">
                        NOTA: per quegli infissi che hanno tutti la medesima tipologia di telaio e vetrata “vecchi” e la medesima tipologia di telaio e vetrata “nuovi” e che hanno comportato tutti (o nessuno) la contemporanea sostituzione della relativa oscurante è possibile inserire una sola riga e sommare i m2 dei manufatti. Inserire i cassonetti in una riga separata indicando come tipo di pannello il “pannello opaco”. 
                        <font color="#f00">(se usi il software “Fattura Infissi” questi campi vengono compilati in automatico)</font>
                    </div>-->
                    <span v-for="item, k in module['manufatti']['serramenti']" :key="item.id" style="width:100%">
                        <br>
                        <v-card
                            class="mx-auto"
                            width="99%"
                            title="User Registration"
                            style="padding:10px;"
                        >
                        <p>Serramento o infisso {{ k+1 }} <v-btn rounded x-small color="primary" @click="removeManufatto('serramenti', k)">Rimuovi serramento/infisso</v-btn></p>

                        <v-row>
                            <v-col cols="12" xs="12" md="6">
                                <v-select 
                                    outlined
                                    label="Tipologia di infisso"
                                    :items="tipologieInfisso" 
                                    v-model="item.tipologiaInfisso"
                                    :error-messages="(!item.tipologiaInfisso)?['Campo obbligatorio']:[]"
                                    @change="manageTrasmittanzaVecchioInfisso(k)"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select 
                                    outlined
                                    v-if="isEcobonus"
                                    label="Tipologia telaio esistente prima dell'intervento"
                                    :items="tipologieTelaio[item.tipologiaInfisso]" 
                                    v-model="item.tipologiaTelaioPre"

                                    :error-messages="(!item.tipologiaTelaioPre)?['Campo obbligatorio']:[]"
                                    @change="manageTrasmittanzaVecchioInfisso(k)"
                                />
                                <v-select 
                                    outlined
                                    v-else
                                    label="Tipologia telaio esistente prima dell'intervento"
                                    :items="tipologieTelaioBonusCasa[item.tipologiaInfisso]" 
                                    v-model="item.tipologiaTelaioPre"

                                    :error-messages="(!item.tipologiaTelaioPre)?['Campo obbligatorio']:[]"
                                    @change="manageTrasmittanzaVecchioInfisso(k)"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select 
                                    outlined
                                    label="Tipologia di vetro o pannello di riempimento prima dell'intervento"
                                    :items="tipologieVetroPre" 
                                    v-model="item.tipologiaVetroPre"
                                    error-count="2"
                                    :error-messages="(!item.tipologiaVetroPre)?['Campo obbligatorio', 'NOTA: se si tratta di un portoncino o di un cassonetto, selezionare pannello opaco']:[]"
                                    :messages="['NOTA: se si tratta di un portoncino o di un cassonetto, selezionare pannello opaco']"
                                    @change="manageTrasmittanzaVecchioInfisso(k)"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field
                                    label="Trasmittanza vecchio infisso (W/m²K)"
                                    type="number"
                                    v-model="item.trasmittanzaPre"
                                    outlined
                                    suffix="W/m²K"
                                    step="0.01"
                                    placeholder=" "
                                    :error-messages="(!item.trasmittanzaPre)?['Campo obbligatorio']:[]"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field
                                    label="Superficie in m²"
                                    type="number"
                                    v-model="item.superficie"
                                    outlined
                                    suffix ="m²"
                                    placeholder=" "
                                    :error-messages="(!item.superficie)?['Campo obbligatorio']:[]"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select 
                                    outlined
                                    v-if="isEcobonus"
                                    label="Tipologia di telaio esistente dopo l'intervento"
                                    :items="tipologieTelaioPost" 
                                    v-model="item.tipologiaTelaioPost"
                                    :error-messages="(!item.tipologiaTelaioPost)?['Campo obbligatorio']:[]"
                                />
                                <v-select 
                                    outlined
                                    v-else
                                    label="Tipologia di telaio esistente dopo l'intervento"
                                    :items="tipologieTelaioPost" 
                                    v-model="item.tipologiaTelaioPost"
                                    :error-messages="(!item.tipologiaTelaioPost)?['Campo obbligatorio']:[]"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select 
                                    outlined
                                    label="Tipologia di vetro o pannello di riempimento dopo l'intervento"
                                    :items="tipologieVetroPost" 
                                    v-model="item.tipologiaVetroPost"
                                    :error-messages="(!item.tipologiaVetroPost)?['Campo obbligatorio']:[]"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-text-field
                                    label="Trasmittanza del nuovo infisso (W/m²K) "
                                    type="number"
                                    v-model="item.trasmittanzaPost"
                                    outlined
                                    placeholder=" "
                                    error-count="2"
                                    suffix="W/m²K"
                                    step="0.01"
                                    :error-messages="trasmittanzaPostError(item)"
                                    :messages="['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale']"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-select 
                                    outlined
                                    label="Confine"
                                    :items="confini" 
                                    v-model="item.confine"
                                    :error-messages="(!item.confine)?['Campo obbligatorio']:[]"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                                <v-checkbox
                                    v-if="isEcobonus"
                                    v-model="item.installazioneChiusuraOscurante"
                                    label="Installazione sullo stesso foro di una chiusura oscurante"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        
                        </v-card>
                    </span>
                </span>
                <span v-if="module.manufatti['schermature'].length >= 1">
                    <!--<div style="color: #1976d2;">
                        NOTA: se i manufatti forniti hanno le stesse caratteristiche, è possibile inserire una sola riga e sommare i m2
                        <font color="#f00">(se usi il software “Fattura Infissi” la maggior parte di questi campi vengono compilati in automatico)</font>
                    </div>-->
                    <span v-for="item, k in module.manufatti['schermature']" :key="item.id" style="width:100%">
                        <br>
                        <v-card
                            class="mx-auto"
                            width="99%"
                            title="User Registration"
                            style="padding:10px;"
                        >
                            <p>Schermatura solare {{ k+1 }} <v-btn rounded x-small color="primary" @click="removeManufatto('schermature', k)">Rimuovi schermatura</v-btn></p>
                            <v-row>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Tipologia schermatura solare"
                                        :items="tipologieSchermatura" 
                                        v-model="item.tipologia"
                                        :error-messages="(!item.tipologia)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Installazione"
                                        :items="installazioni" 
                                        v-model="item.installazione"
                                        :error-messages="(!item.installazione)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Superficie schermatura"
                                        type="number"
                                        v-model="item.superficieSchermatura"
                                        outlined
                                        placeholder=" "
                                        suffix="m²"
                                        :error-messages="(!item.superficieSchermatura)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Superficie finestrata protetta"
                                        type="number"
                                        v-model="item.superficieFinestrata"
                                        outlined
                                        placeholder=" "
                                        suffix="m²"
                                        :error-messages="(!item.superficieFinestrata)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Tipologia di finestra protetta"
                                        :items="tipologieFinestra" 
                                        v-model="item.tipologiaFinestra"
                                        :error-messages="(!item.tipologiaFinestra)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Tipologia di vetrata protetta"
                                        :items="tipologieVetrata" 
                                        v-model="item.tipologiaVetrata"
                                        :error-messages="(!item.tipologiaVetrata)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Esposizione"
                                        :items="esposizioniSchermatura" 
                                        v-model="item.esposizione"
                                        :error-messages="(!item.esposizione)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field 
                                        outlined
                                        label="Fattore di trasmissione solare gₜₒₜ"
                                        type="number"
                                        step="0.01"
                                        v-model="item.trasmissioneSolare"
                                        :error-messages="(!item.trasmissioneSolare || Number(item.trasmissioneSolare) > 0.35)?['Il fattore di trasmissione solare può avere un valore massimo di 0,35']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Modalità di calcolo adottata per determinare la resistenza termica supplementare"
                                        :items="modalitaDiCalcoloResistenzaTermica" 
                                        v-model="item.modalitaCalcoloResistenzaTermica"
                                        :error-messages="(!item.modalitaCalcoloResistenzaTermica)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Materiale schermature"
                                        :items="materialiSchermatura" 
                                        v-model="item.materialeSchermatura"
                                        :error-messages="(!item.materialeSchermatura)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Meccanismo di regolazione"
                                        :items="meccanismiRegolazione" 
                                        v-model="item.meccanismoRegolazione"
                                        :error-messages="(!item.meccanismoRegolazione)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                            </v-row>
                            <p>Presenza di aggetti o ostruzioni</p>
                            <div style="color: #1976d2;">
                                NOTA: da compilare solo se nell’immobile è presente un impianto di condizionamento.
                                <br>Gli aggetti e le ostruzioni influenzano l’irraggiamento diretto della finestra e quindi l’aumento della temperatura interna. In presenza di aggetti, la schermatura produce una riduzione di consumi conseguente minore rispetto alla situazione senza aggetti. Ad esempio, una finestra che è parzialmente protetta da un terrazzo avrà una riduzione di consumi inferiore ad una schermatura che è completamente esposta; ENEA vuole monitorare questo dato e quindi chiede di inserire alcuni dati relativi ad aggetti e ostruzioni. Noi abbiamo condotto centinaia di simulazioni e siamo riusciti a comprendere come funziona l’algoritmo di ENEA. 
                                <br>Fornendoci pochi semplici dati saremo in grado di calcolare in maniera accurata il risparmio di energia primaria. 
                                <br>Gli aggetti orizzontali sono ad esempio portici, balconi, poggioli, tettorie posizionati sopra la finestra.
                                <br>Gli aggetti verticali sono ad esempio pannelli di separazione sui poggioli, pareti frangivento, muri, giroscale sporgenti lateralmente rispetto alla facciata sulla quale è situata la finestra.
                                <br>Le ostruzioni sono principalmente costituite da edifici posti di fronte all’abitazione.  
                            </div>
                            
                            <br>
                           <p> AGGETTI ORIZZONTALI <v-btn dark rounded color="primary" x-small @click="removeAggettoOrizzontale(k)">Azzera aggetto</v-btn></p> 
                            <v-row>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Selezionare altezza soffitto"
                                        :items="altezzeSoffitto" 
                                        v-model="item.aggettiOstruzioni.orizzontali.altezzaSoffitto"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Selezionare profondità approssimativa aggetto"
                                        :items="profonditaAggetto" 
                                        v-model="item.aggettiOstruzioni.orizzontali.profonditaAggetto"
                                    />
                                </v-col>
                            </v-row>
                            <p>AGGETTI VERTICALI <v-btn dark rounded color="primary" x-small @click="removeAggettoVerticale(k)">Azzera aggetto</v-btn> </p>
                            <v-row>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Guardando dall'interno verso l'esterno, l'aggetto è posto a"
                                        :items="posizioniAggetto" 
                                        v-model="item.aggettiOstruzioni.verticali.posizione"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field 
                                        outlined
                                        label="Distanza dell'aggetto dal centro della vetrata"
                                        type="number"
                                        suffix="m"
                                        v-model="item.aggettiOstruzioni.verticali.distanza"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field 
                                        outlined
                                        label="Profondità dell'aggetto"
                                        type="number"
                                        suffix="m"
                                        v-model="item.aggettiOstruzioni.verticali.profondita"
                                    />
                                </v-col>
                            </v-row>
                            
                            <p>OSTRUZIONI <v-btn dark rounded color="primary" x-small @click="removeAggettoOstruzione(k)">Azzera aggetto</v-btn> </p>
                            <v-row>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Distanza approssimativa tra i palazzi"
                                        :items="distanzePalazzi" 
                                        suffix="m"
                                        v-model="item.aggettiOstruzioni.ostruzioni.distanza"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Differenza di piani rispetto al palazzo di fronte"
                                        :items="differenzePiani" 
                                        v-model="item.aggettiOstruzioni.ostruzioni.differenzaPiani"
                                    />
                                </v-col>
                            </v-row>
                        </v-card>
                    </span>
                </span>
                <span v-if="module.manufatti['chiusure'].length >= 1">
                    <!--<div style="color: #1976d2;">
                        NOTA: se i manufatti forniti hanno le stesse caratteristiche, è possibile inserire una sola riga e sommare i m2 dei manufatti
                        <font color="#f00">(se usi il software “Fattura Infissi” la maggior parte di questi campi vengono compilati in automatico)</font>
                    </div>-->
                    <span v-for="item, k in module.manufatti['chiusure']" :key="item.id" style="width:100%">
                        <br>
                        <v-card
                            class="mx-auto"
                            width="99%"
                            title="User Registration"
                            style="padding:10px;"
                        >
                            <p>Chiusura oscurante {{ k+1 }} <v-btn rounded x-small color="primary" @click="removeManufatto('chiusure', k)">Rimuovi chiusura</v-btn></p>
                            <v-row>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Tipologia chiusura oscurante prima dell’intervento"
                                        :items="tipologieChiusuraPre" 
                                        v-model="item.tipologiaPre"
                                        :error-messages="(!item.tipologiaPre)?['Campo obbligatorio']:[]"
                                        @change="manageResistenzaTermicaSupplementare(k)"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Tipologia chiusura oscurante dopo l’intervento"
                                        :items="tipologieChiusuraPost" 
                                        v-model="item.tipologiaPost"
                                        :error-messages="(!item.tipologiaPost)?['Campo obbligatorio']:[]"
                                        @change="manageResistenzaTermicaSupplementare(k)"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Installazione"
                                        :items="installazioni" 
                                        v-model="item.installazione"
                                        :error-messages="(!item.installazione)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Superficie chiusura"
                                        type="number"
                                        v-model="item.superficieChiusura"
                                        outlined
                                        placeholder=" "
                                        suffix="m²"
                                        :error-messages="(!item.superficieChiusura)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Superficie finestrata protetta"
                                        type="number"
                                        v-model="item.superficieFinestrata"
                                        outlined
                                        placeholder=" "
                                        suffix="m²"
                                        :error-messages="(!item.superficieFinestrata)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <!--<v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Tipologia di vetrata protetta"
                                        :items="tipologieVetrata" 
                                        v-model="item.tipologiaVetrata"
                                        :error-messages="(!item.tipologiaVetrata)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>-->
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Esposizione"
                                        :items="esposizioniChiusura" 
                                        v-model="item.esposizione"
                                        :error-messages="(!item.esposizione)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                    <v-select 
                                        outlined
                                        label="Permeabilità dell'aria"
                                        :items="permeabilitaAria" 
                                        v-model="item.permeabilitaAria"
                                        :error-messages="(!item.permeabilitaAria)?['Campo obbligatorio']:[]"
                                        @change="manageResistenzaTermicaSupplementare(k)"
                                    />
                                </v-col>
                                <v-col 
                                    cols="12"
                                    xs="12"
                                    md="2"
                                >
                                <v-dialog
                                        v-model="dialogPermeabilitaAria"
                                        width="900"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon left dark>
                                                fas fa-question-circle
                                            </v-icon>  
                                            Maggiori info
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title class="text-h5 grey lighten-2">
                                                Cosa di intenda per permeabilità all’aria media e bassa
                                            </v-card-title>
                                            <v-card-text>
                                            La Resistenza Termica Supplementare descrive la capacità della chiusura oscurante di ridurre
                                            nelle stagioni fredde le dispersioni di calore tra interno ed esterno, ovvero la sua capacità
                                            isolante, ed è data dalla combinazione di due fattori: <br>
                                            <ul>
                                                <li>il potere isolante dello schermo opaco, che dipende dalla conducibilità termica del
                                                            materiale da cui è costituito (chiaramente uno schermo in legno è più isolante di uno
                                                        schermo in alluminio!);
                                                </li>
                                                <li>
                                                    il potere isolante di quell’intercapedine di aria che rimane intrappolata tra la schermatura
                                                    ed il vetro, che dipende dall’entità dei movimenti dell’aria al suo interno (che ovviamente
                                                    sono tanto maggiori quanto più ampie sono le “fessure” presenti tra l’oscurante e il foro
                                                    finestra nel quale è inserito e quindi quanto maggiore è la possibilità di scambi di calore
                                                    tra interno ed esterno).
                                                </li>
                                            </ul>
                                            <br>
                                            Ne consegue che a parità di schermo oscurante (cioè stessa tipologia e stesso materiale), il
                                            valore della Resistenza Termica Supplementare è inversamente proporzionale alla sua
                                            permeabilità all’aria una volta che è stato installato. Generalmente nei vecchi oscuranti - a
                                            causa di una posa poco attenta a limitare le possibilità di passaggio di aria (e di conseguenza
                                            di calore) tra interno ed esterno e anche delle deformazioni subite nel tempo dallo schermo -
                                            la permeabilità all’aria è elevata; quindi il suo valore di resistenza termica supplementare è
                                            relativamente basso e quasi sempre inferiore rispetto a quello della nuova chiusura oscurante
                                            nella cui posa in opera si suppone siano stati adottati appositi accorgimenti tecnici volti a
                                            contenere al minimo i passaggi d’aria/calore tra interno ed esterno e che quindi ha almeno
                                            una permeabilità all’aria media se non addirittura bassa. Secondo la UNI EN 13125 (che è la
                                            norma di riferimento per il calcolo della resistenza termica supplementare delle chiusure
                                            oscuranti):
                                            <ul>
                                                <li>si ha una permeabilità all’aria alta quando la somma delle larghezze delle fessure presenti
                                                    lungo il perimetro tra chiusura e parete è compresa tra 15 mm e 35 mm.
                                                </li>
                                                <li>si ha una permeabilità all’aria media quando la somma delle larghezze delle fessure
                                                    presenti lungo il perimetro tra chiusura e parete è tra 15 mm ed 8 millimetri.
                                                </li>
                                                <li>si ha una permeabilità all’aria bassa quando la somma delle larghezze delle fessure 
                                                    presenti lungo il perimetro tra chiusura e parete è inferiore a 8 mm.
                                                </li>
                                            </ul>
                                            <br>
                                            <b>Il valore da inserire in questo campo può essere preso dalla Tabella 1 dell’allegato … che
                                                riporta i valori di resistenza termica supplementare delle varie tipologie di chiusure
                                                oscuranti nell’ipotesi di una permeabilità all’aria alta (com’è quasi sempre quella dei
                                                “vecchi” oscuranti) e che è stata sviluppata sulla base della Norma UNI EN 13125.</b>
                                           
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="dialogPermeabilitaAria = false"
                                            >
                                                Chiudi
                                            </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                                    
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Resistenza termica supplementare iniziale (Rₛᵤₚₚ) [Km²/W]"
                                        v-model="item.resistenzaTermicaIniziale"
                                        outlined
                                        placeholder=" "
                                        :error-messages="(!item.resistenzaTermicaIniziale)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Resistenza termica supplementare finale (Rₛᵤₚₚ) [Km²/W]"
                                        v-model="item.resistenzaTermicaFinale"
                                        outlined
                                        placeholder=" "
                                        :error-messages="(!item.resistenzaTermicaFinale)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Modalità di calcolo adottata per determinare la resistenza termica supplementare"
                                        :items="modalitaDiCalcoloResistenzaTermica" 
                                        v-model="item.modalitaCalcoloResistenzaTermica"
                                        :error-messages="(!item.modalitaCalcoloResistenzaTermica)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field 
                                        outlined
                                        label="Fattore di trasmissione solare gₜₒₜ"
                                        type="number"
                                        step="0.01"
                                        v-model="item.trasmissioneSolare"
                                        :error-messages="(!item.trasmissioneSolare || Number(item.trasmissioneSolare) > 0.35)?['Il fattore di trasmissione solare può avere un valore massimo di 0,35']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field 
                                        outlined
                                        label="Uw infisso posto dietro la chiusura"
                                        type="number"
                                        v-model="item.uwInfissoChiusura"
                                        :error-messages="(!item.uwInfissoChiusura)?['NOTA: se si tratta di un vecchio infisso del quale non si riesce a risalire all’Uw utilizzae i dati riportati nella seguente tabella']:[]"
                                    />
                                </v-col>
                                <!--<v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Materiale chiusura"
                                        :items="materialiChiusura" 
                                        v-model="item.materialeChiusura"
                                        :error-messages="(!item.materialeChiusura)?['Campo obbligatorio']:[]"
                                        @change="manageResistenzaTermicaSupplementare(k)"
                                    />
                                </v-col>-->
                                <v-col cols="12" xs="12" md="6">
                                    <v-select 
                                        outlined
                                        label="Meccanismo di regolazione"
                                        :items="meccanismiRegolazione" 
                                        v-model="item.meccanismoRegolazione"
                                        :error-messages="(!item.meccanismoRegolazione)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-dialog
                                        v-model="dialogTabellaUwInfissiChiusure"
                                        width="900"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            Tabella
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title class="text-h5 grey lighten-2">
                                            Tabella
                                            </v-card-title>

                                            <v-card-text>
                                                <template>
                                                    <v-simple-table>
                                                        <template v-slot:default>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="3">VALORI DI TRASMITTANZA TERMICA DEI SERRAMENTI ESISTENTI [W/m²-K]</td>
                                                                </tr>
                                                                <tr>
                                                                    <td rowspan="2">TIPO DI TELAIO (materiale e spessore)</td>
                                                                    <td colspan="2">TIPO DI VETRATA</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SINGOLA</td>
                                                                    <td>DOPPIA (4-9-4)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Legno duro (rovere, mogano, iroko) mm 50</td>
                                                                    <td>5.05</td>
                                                                    <td>3.15</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Legno tenero (pino, abete, larice, douglas, hemlock) mm 50</td>
                                                                    <td>5.00</td>
                                                                    <td>3.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PVC a due camere</td>
                                                                    <td>5,00</td>
                                                                    <td>3,10</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PVC a tre camere</td>
                                                                    <td>5.00</td>
                                                                    <td>3.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Alluminio senza taglio termico</td>
                                                                    <td>6.00</td>
                                                                    <td>3.90</td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="dialogTabellaUwInfissiChiusure = false"
                                            >
                                                Chiudi
                                            </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-card>
                    </span>
                </span>
                <v-col cols="12" align="end">
                    <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn rounded color="primary" :disabled="!isZeroStepFilled || !isManufattiInserted" @click="onClickEighthStep()">Avanti</v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="isEcobonus"
                :editable="isZeroStepFilled && isManufattiInserted"
                edit-icon="fa-check"
                @click.native="onClickEighthStepDirectly()"
                :complete="stepperPosition > 8"
                :rules="[validateEighthStep]"
                :step=8
                id="tab8"
            >
                <p class="ma-0">Impianto termico esistente</p>
            </v-stepper-step>
            <v-stepper-content step="8" v-if="isEcobonus">
                <br>
                <v-row>
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['serramenti'].length == 0 && module.manufatti['schermature'].length >= 1">
                        <v-checkbox
                            v-model="riscaldamento"
                            label="È presente un impianto di riscaldamento"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="module.manufatti['serramenti'].length >= 1 || riscaldamento == true">
                    <div style="color: #1976d2; margin-left: 13px;">
                        NOTA: inserire i dati relativi al tipo di generatore presente all’inizio dei lavori anche se nel corso degli stessi dovesse essere stato sostituito
                    </div>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Tipo di impianto"
                            :items="tipiImpianto" 
                            v-model="module.tipoImpianto"
                            error-count="2"
                            :error-messages="(!module.tipoImpianto)?['Campo obbligatorio', 'NOTA: per impianto autonomo si intende quando nell’immobile è presente un generatore di calore (caldaia) che il cliente può autonomamente accendere/spegnere/regolare. Per impianto centralizzato si intende il caso in cui una caldaia è condivisa da più appartamenti, mentre per contabilizzazione per singolo utente si intende il caso in cui sui radiatori è presente una valvola termostatica per la regolazione individuale della temperatura.']:[]"
                            :messages="['NOTA: per impianto autonomo si intende quando nell’immobile è presente un generatore di calore (caldaia) che il cliente può autonomamente accendere/spegnere/regolare. Per impianto centralizzato si intende il caso in cui una caldaia è condivisa da più appartamenti, mentre per contabilizzazione per singolo utente si intende il caso in cui sui radiatori è presente una valvola termostatica per la regolazione individuale della temperatura.']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Tipologia prevalente terminali di erogazione"
                            :items="tipiTerminaliErogazione" 
                            v-model="module.tipoTerminaleErogazione"
                            error-count="2"
                            :messages="['NOTA: se in una casa sono installati i termosifoni e split con funzione pompa di calore ma per il riscaldamento vengono utilizzati solo i termosifoni. Allora inserire i dati solamente per quest’ultimi']"
                        />
                        <v-chip color="primary" outlined v-if="module.tipoTerminaleErogazione == 'split'">
                            <font size="2px">
                                NOTA: se lo split viene utilizzato anche per raffrescare gli ambienti, indicare che è presente un impianto di raffrescamento nella sezione successiva
                            </font>
                        </v-chip>
                    </v-col>
                    <!--<v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Tipo di distribuzione"
                            :items="tipiDistribuzione" 
                            v-model="module.tipoDistribuzione"
                    />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-select 
                            outlined
                            label="Tipo di regolazione"
                            :items="tipiRegolazione" 
                            v-model="module.tipoRegolazione"
                            :error-messages="(!module.tipoRegolazione)?['Campo obbligatorio']:[]"
                        />
                    </v-col>-->
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Marca impianto di riscaldamento"
                            v-model="module.marcaImpiantoTermico"
                            outlined
                            placeholder=" "
                            :error-messages="(!module.marcaImpiantoTermico)?['Campo obbligatorio']:[]"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                        <v-text-field
                            label="Modello impianto di riscaldamento"
                            v-model="module.modelloImpiantoTermico"
                            outlined
                            placeholder=" "
                            :error-messages="(!module.modelloImpiantoTermico)?['Campo obbligatorio']:[]"
                        />
                    </v-col>
                    <v-col cols="12">
                        <b>Generatori esistenti prima dell'inizio dei lavori</b>
                        <div style="color: #1976d2;">
                            <!--NOTA: Nel caso non si riesca a trovare il libretto dell’impianto autonomo <a href="/assets/IMPIANTO%20DI%20RISCALDAMENTO.pdf" target="_blank">clicca qui</a> per visualizzare la lista delle schede per i principali produttori. Per gli impianti centralizzati conviene sempre contattare l’amministratore del condominio o il manutentore. In caso di pompe di calore che esprimono la potenza in BTU, trasformare in Kw dividendo per 3.412,14 -->
                            NOTA: potete trovare tutte le informazioni relative al generatore di calore nella sezione4 del libretto d’impianto nominata “generatori”.<br>
                                Nel caso si trattasse di un condominio le informazioni vanno richieste all’amministratore o al manutentore dell’impianto.<br>
                                Se ci sono più generatori distinguiamo 3 casistiche:
                                <ul>
                                    <li>se ci sono due generatori ma solo uno viene utilizzato per il riscaldamento va inserito solo quest’ultimo</li>
                                    <li>se ci sono due generatori di diversa tipologia e ambedue vengono utilizzati per il riscaldamento inserirli entrambi nelle apposite sezioni</li>
                                    <li>se ci sono due generatori della stessa tipologia ed entrambi vengono utilizzati per il riscaldamento inserire la potenza termica totale (ovvero la somma delle varie potenze) e il valore pesato del rendimento (chiedere eventualmente al tecnico a cui è affidata la pratica)</li>
                                </ul>
                                Nel caso per qualsiasi motivo non riusciste a reperire il libretto di impianto del vostro generatore potete consultare la scheda tecnica sul sito del produttore. 
                                <br>In questo caso ricordiamo che va inserita la potenza utile nominale e non quella al focolare e il rendimento deve essere calcolato al 100% della potenza.
                        </div>
                        <span v-for="(k, v) in this.module.generatori" style="width:100%">
                            <br>
                            <v-card
                                class="mx-auto"
                                width="99%"
                                title="User Registration"
                                style="padding:10px;"
                                outlined
                            >
                                <p>Generatore {{ v+1 }} <v-btn v-if="module.generatori.length > 1" rounded x-small color="primary" @click="removeGeneratore(v)">Rimuovi generatore</v-btn></p>
                                <v-row>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-select 
                                            outlined
                                            label="Tipo di generatore"
                                            :items="tipiGeneratore" 
                                            v-model="module.generatori[v].tipoGeneratore"
                                            :error-messages="(!module.generatori[v].tipoGeneratore)?['Campo obbligatorio']:[]"
                                            @change="manageVettoreEnergetico()"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field
                                            label="N. generatori"
                                            v-model="module.generatori[v].numeroGeneratore"
                                            outlined
                                            placeholder=" "
                                            :error-messages="(!module.generatori[v].numeroGeneratore)?['Campo obbligatorio']:[]"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field
                                            label="Rendimento al 100% della potenza / P.E.A."
                                            type="number"
                                            v-model="module.generatori[v].rendimentoMaxPotenza"
                                            outlined
                                            placeholder=" "
                                            :error-messages="(!module.generatori[v].rendimentoMaxPotenza)?['Campo obbligatorio']:[]"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" md="6">
                                        <v-text-field
                                            label="Potenza utile nominale [kW]"
                                            v-model="module.generatori[v].potenzaUtileNominale"
                                            outlined
                                            type="number"
                                            placeholder=" "
                                            :error-messages="(!module.generatori[v].potenzaUtileNominale)?['Campo obbligatorio']:[]"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </span>
                        <v-col cols="12">
                            <v-btn rounded color="primary" @click="addNewGeneratore">Aggiungi generatore</v-btn>
                            <v-dialog
                                v-model="dialogNotaGeneratori"
                                width="900"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn rounded color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    Nota
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-card-title class="text-h5 grey lighten-2">
                                    Nota
                                    </v-card-title>

                                    <v-card-text>
                                        Va indicata la potenza utile nominale e non quella al focolare. Per il rendimento nelle caldaie ad acqua inserire il rendimento nelle condizioni di temperatura di mandata e ritorno rispettivamente di 80°C e 60°C. 
                                        <br>Se ci sono più generatori di calore dello stesso tipo indicare quanti sono e inserire la potenza termica totale (cioè la somma delle varie potenze) e il valore pesato del rendimento. 
                                        <br>In caso di pompe di calore che esprimono la potenza in BTU, considerare la corrispondente potenza in KW usando come riferimento i dati riportati nella tabella seguente per le potenze che si riscontrano più di frequente:
                                        <template>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>2 KW</td>
                                                            <td>7000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2,5 KW</td>
                                                            <td>9000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3,5 KW</td>
                                                            <td>12000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4,2 KW</td>
                                                            <td>15000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5 KW</td>
                                                            <td>18000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6 KW</td>
                                                            <td>21000</td>
                                                        </tr>
                                                        <tr>
                                                            <td>7,1 KW</td>
                                                            <td>24000</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                        Per la potenza termica assorbita delle pompe di calore fare attenzione che sulle schede tecniche è generalmente riportata in Watt e quindi va divisa per mille.
                                        <br>
                                        Nel caso non si riesca a trovare il libretto dell’impianto autonomo<a href="/assets/IMPIANTO DI RISCALDAMENTO.pdf" target="_blank"> clicca
                                        qui </a>  per visualizzare la lista delle schede per i principali
                                        produttori. Per gli impianti centralizzati conviene sempre contattare l’amministratore
                                        del condominio o il manutentore
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="dialogNotaGeneratori = false"
                                    >
                                        Chiudi
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-dialog>
                        </v-col>
                        <p style="color: #f00" v-if="module.generatori.length<=0" >Inserire almeno un generatore</p>
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                        <v-select 
                            outlined
                            label="Vettore energetico"
                            :items="vettoriEnergetici" 
                            v-model="module.vettoreEnergetico"
                            :error-messages="(!module.vettoreEnergetico)?['Campo obbligatorio']:[]"
                        />
                    </v-col>
                </v-row>    
                <v-row>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="module.impiantoClimatizzazioneEstiva"
                            label="È presente un impianto di climatizzazione estiva"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="module.manufatti.schermature.length > 0 && module.impiantoClimatizzazioneEstiva==true">
                        <v-text-field
                            label="Valore EER impianto di climatizzazione"
                            v-model="module.valoreEer"
                            outlined
                            placeholder=" "
                            :error-messages="(!module.valoreEer)?['Campo obbligatorio']:''"
                        />
                        <v-alert
                        dense
                        outlined
                        type="info"
                        >
                        Se nei dati dell’unità non è riportato il valore di EER, è possibile ricavarlo
                        dividendo la potenza termica erogata in raffreddamento (cooling capacity) per la
                        potenza elettrica assorbita. <a href="/assets/IMPIANTO DI RAFFRESCAMENTO.pdf" target="_blank"> Cliccando qui</a>  potete trovare il
                        collegamento alle schede tecniche per i principali produttori e una tabella per
                        individuare il valore di EER in base alla classe energetica del vostro climatizzatore.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-col cols="12" align="end">
                    <v-btn v-if="!isAdmin" rounded color="primary" :loading="saveDraftLoading" @click="saveDraft()">Salva bozza</v-btn>
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn rounded color="primary" :disabled="!isZeroStepFilled || !isManufattiInserted" @click="stepperPosition++">Avanti</v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="isEcobonus"
                :editable="isZeroStepFilled && isManufattiInserted"
                edit-icon="fa-check"
                :complete="stepperPosition > 9"
                :rules="[validateNinthStep]"
                :step=9
                id="tab9"
            >
                <p class="ma-0">Dettagli economici</p>
            </v-stepper-step>
            <v-stepper-content step="9" v-if="isEcobonus">
                <br>
                <v-row v-if="(module.manufatti['serramenti'].length >= 1 && module.manufatti['schermature'].length >= 1) || (module.manufatti['serramenti'].length >= 1 && module.manufatti['chiusure'].length >= 1)">
                    <v-col cols="12">
                        <div style="color: #1976d2;">
                            NOTA: Nel caso debbano essere compilate più pratiche ENEA per lo stesso intervento per il quale è stata emessa un’unica fattura è necessario specificare per ogni pratica la spese relative all’intervento; ti chiedo quindi di suddividerle nel modo più accurato possibile. Il costo delle spese fisse, come quello della pratica ENEA, può essere diviso al 50%
                        </div>
                        
                        <v-card
                            class="mx-auto"
                            width="99%"
                            title="User Registration"
                            style="padding:10px;"
                            outlined
                        >
                            <p>Pratica ENEA 1: serramenti e accessori</p>
                            <v-row>
                                <br>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Spesa congrua e detraibile per serramenti e accessori compresa di manodopera"
                                        v-model="module.spese.spesaDetraibileSerramenti"
                                        outlined
                                        type="number"
                                        placeholder=" "
                                        error-count="2"
                                        :error-messages="(!module.spese.spesaDetraibileSerramenti)?['Campo obbligatorio', 'NOTA: se la fattura è stata fatta ad un soggetto con partita IVA come costo totale degli infissi si considera solo il totale dell’imponibile delle varie fatture']:[]"
                                        :messages="['NOTA: se la fattura è stata fatta ad un soggetto con partita IVA come costo totale degli infissi si considera solo il totale dell’imponibile delle varie fatture']"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                    <v-text-field
                                        label="Importo spese professionali per serramenti e accessori"
                                        v-model="module.spese.speseProfessionaliSerramenti"
                                        outlined
                                        type="number"
                                        placeholder=" "
                                        error-count="2"
                                        :error-messages="(!module.spese.speseProfessionaliSerramenti)?['Campo obbligatorio', 'NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']:[]"
                                        :messages="['NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']"
                                    />
                                </v-col>
                            </v-row>
                        </v-card>
                        <br><br>
                        <v-card
                            class="mx-auto"
                            width="99%"
                            title="User Registration"
                            style="padding:10px;"
                            outlined
                        >
                            <p>Pratica ENEA 2: schermature solari e chiusure oscuranti</p>
                            <v-row>
                                <br>
                                <v-col cols="12" xs="12" md="6" v-if="module.manufatti['schermature'].length >=1" >
                                    <v-text-field
                                        label="Spesa congrua e detraibile per schermature solari compresa di manodopera"
                                        v-model="module.spese.spesaDetraibileSchermature"
                                        outlined
                                        type="number"
                                        placeholder=" "
                                        :error-messages="(!module.spese.spesaDetraibileSchermature)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6" v-if="module.manufatti['schermature'].length >=1">
                                    <v-text-field
                                        label="Importo spese professionali per schermature solari"
                                        v-model="module.spese.speseProfessionaliSchermature"
                                        outlined
                                        placeholder=" "
                                          type="number"
                                        error-count="2"
                                        :error-messages="(!module.spese.speseProfessionaliSchermature)?['Campo obbligatorio', 'NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']:[]"
                                        :messages="['NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6" v-if="module.manufatti['chiusure'].length >=1">
                                    <v-text-field
                                        label="Spesa congrua e detraibile per chiusure oscuranti compresa di manodopera"
                                        v-model="module.spese.spesaDetraibileChiusure"
                                        outlined
                                        type="number"
                                        placeholder=" "
                                        :error-messages="(!module.spese.spesaDetraibileChiusure)?['Campo obbligatorio']:[]"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" md="6" v-if="module.manufatti['chiusure'].length >=1">
                                    <v-text-field
                                        label="Importo spese professionali per chiusure oscuranti"
                                        v-model="module.spese.speseProfessionaliChiusure"
                                        outlined
                                        placeholder=" "
                                        type="number"
                                        error-count="2"
                                        :error-messages="(!module.spese.speseProfessionaliChiusure)?['Campo obbligatorio', 'NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']:[]"
                                        :messages="['NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']"
                                    />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
              
                <v-row v-else>
                  
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['serramenti'].length >=1">
                        <v-text-field
                            label="Spesa congrua e detraibile per serramenti e accessori compresa di manodopera"
                            v-model="module.spese.spesaDetraibileSerramenti"
                            outlined
                            type="number"
                            placeholder=" "
                            error-count="2"
                            :error-messages="(!module.spese.spesaDetraibileSerramenti)?['Campo obbligatorio', 'NOTA: se la fattura è stata fatta ad un soggetto con partita IVA come costo totale degli infissi si considera solo il totale dell’imponibile delle varie fatture']:[]"
                            :messages="['NOTA: se la fattura è stata fatta ad un soggetto con partita IVA come costo totale degli infissi si considera solo il totale dell’imponibile delle varie fatture']"

                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['serramenti'].length >=1">
                        <v-text-field
                            label="Importo spese professionali per serramenti e accessori"
                            v-model="module.spese.speseProfessionaliSerramenti"
                            outlined
                            type="number"
                            placeholder=" "
                            error-count="2"
                            :error-messages="(!module.spese.speseProfessionaliSerramenti)?['Campo obbligatorio', 'NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']:[]"
                            :messages="['NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['schermature'].length >=1">
                        <v-text-field
                            label="Spesa congrua e detraibile per schermature solari compresa di manodopera"
                            v-model="module.spese.spesaDetraibileSchermature"
                            outlined
                            type="number"
                            placeholder=" "
                            :error-messages="(!module.spese.spesaDetraibileSchermature)?['Campo obbligatorio']:[]"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['schermature'].length >=1">
                        <v-text-field
                            label="Importo spese professionali per schermature solari"
                            v-model="module.spese.speseProfessionaliSchermature"
                            outlined
                            type="number"
                            placeholder=" "
                            error-count="2"
                            :error-messages="(!module.spese.speseProfessionaliSchermature)?['Campo obbligatorio', 'NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']:[]"
                            :messages="['NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['chiusure'].length >=1">
                        <v-text-field
                            label="Spesa congrua e detraibile per chiusure oscuranti compresa di manodopera"
                            v-model="module.spese.spesaDetraibileChiusure"
                            outlined
                            type="number"
                            placeholder=" "
                            :error-messages="(!module.spese.spesaDetraibileChiusure)?['Campo obbligatorio']:[]"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" md="6" v-if="module.manufatti['chiusure'].length >=1">
                        <v-text-field
                            label="Importo spese professionali per chiusure oscuranti"
                            v-model="module.spese.speseProfessionaliChiusure"
                            outlined
                            type="number"
                            placeholder=" "
                            error-count="2"
                            :error-messages="(!module.spese.speseProfessionaliChiusure)?['Campo obbligatorio', 'NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']:[]"
                            :messages="['NOTA: tra queste spese rientrano: la pratica enea, le tavole di posa, l’elaborazione della dichiarazione del fornitore, la progettazione del cantiere, i sopralluoghi tecnici esecutivi. Se il beneficiario è un è un soggetto con partita IVA va indicato solo l’imponibile']"
                        />
                    </v-col>
                </v-row>
                <v-col cols="12" align="end" v-if="isAdmin">
                    <v-btn rounded color="primary"  @click="stepperPosition++">Avanti</v-btn>     
                </v-col>
                <v-col cols="12" align="end" v-else>
                    <v-btn rounded color="primary" :disabled="!isZeroStepFilled && !isManufattiInserted" @click="saveDraftBeforeDocuments(true)"  :loading="saveDraftLastStepLoading" >Salva e avanti</v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                :editable="isZeroStepFilled && isManufattiInserted"
                edit-icon="fa-check"
                :complete="stepperPosition > userLastStepNumber"
                :rules="[validateTenthStep]"
                :step=userLastStepNumber
                v-if="!isAdmin"
                id="tab10"
            >
                <p class="ma-0">Documentazione</p>
            </v-stepper-step>
            <v-stepper-content :step="userLastStepNumber" v-if="!isAdmin">
                <v-btn rounded color="primary" :disabled="!isTenthStepFilled"  :loading="downloadDocumentsLoading" @click="downloadDocuments">Download documenti</v-btn>
                
                <br><br>
                <div v-for="doc in documentsRequired" :key="doc.name"  >
                    <v-col cols="12" md="12">
                        <file-upload v-if="stepperPosition== userLastStepNumber"
                            outlined
                            chips
                            :directory="docFolder"
                            :present="doc.uploaded"
                            :file-name="doc.name"
                            :label="doc.name"
                            v-model="doc.path"
                            :vars="(user.role == 'Admin' || user.role == 'SuperAdmin')?{view: 'administrationEdit', module: module} :{view: 'moduleStructure', module: module}"
                            :error-messages="(!(doc.uploaded || doc.path)&& doc.isMandatory)?['Campo obbligatorio']:[]"
                        />
                    </v-coL>
                
                </div>
                <v-chip color="primary" outlined v-if="!isAdmin">
                    Crediti necessari per l'invio della pratica: BonusCasa: {{ moduleCredits.numBonusCasa }}, Ecobonus: {{ moduleCredits.numEcobonus }}, Calcolo energetico: {{ moduleCredits.numCalcoloEnergetico }} (Totale: {{ totalCost }}€)
                </v-chip>
                <v-chip color="red" outlined v-if="!hasEnoughCredits && !isAdmin">
                    {{ creditError }}
                </v-chip>
              
                <v-col v-if="isAdmin" cols="12" align="end">
                    <v-btn rounded color="primary" @click="stepperPosition++">Avanti</v-btn>                  
                </v-col>
                <v-col v-else cols="12" align="end">
                    <v-btn rounded color="primary" :loading="sendModuleLoading" :disabled="!hasEnoughCredits && canSend" @click="sendModule()">Invia pratica</v-btn>
                    <v-btn v-if="!isAdmin" rounded color="secondary" :loading="saveDraftLoading" @click="saveDraft(true)">Salva bozza</v-btn>
                </v-col>
            </v-stepper-content>
            <v-stepper-step
                v-if="isAdmin"
                edit-icon="fa-check"
                :step=userLastStepNumber
                :complete="stepperPosition > userLastStepNumber"
               
            >
                <p class="ma-0">Amministrazione pratica</p>
            </v-stepper-step>
            <v-stepper-content :step="userLastStepNumber" v-if="isAdmin">
                <v-btn rounded color="primary"  class="ml-2" @click="downloadModuleDocuments" :loading="downloadModuleDocumentsLoading">
                    Download documenti pratica
                </v-btn>
                
                <v-btn  rounded color="primary" class="ml-2" v-if="isToDownloadCompilatore" @click="downloadDocumentiCompilatore" :loading="downloadDocumentiCompilatoreLoading">
                    Download documenti compilatore
                </v-btn>
                <br><br>
                <v-select 
                    outlined
                    :items="statuses"
                    item-disabled="disable"
                    v-model="module.status.code"
                    @change="onStatusChange(module)"
                    label = "Stato pratica"
                />
                <v-col
                    cols="12"
                    xs="12"
                    md="12"
                >
                    <file-upload
                        v-if="stepperPosition == userLastStepNumber"
                        outlined
                        chips
                        :directory="docFolder"
                        :present="(!ricevutaDocumentoAdmin)?false:true"
                        :file-name="'Ricevuta'"
                        :vars="{view: 'administrationEdit', module: module, isDocAdmin:true}"
                        v-model="ricevutaDocumentoAdmin"
                        :label="'Ricevuta'"                        
                    />
                </v-col>
                <v-col
                    cols="12"
                    xs="12"
                    md="12"
                >
                    <file-upload
                        v-if="stepperPosition == userLastStepNumber"
                        outlined
                        chips
                        :directory="docFolder"
                        :present="(!codiceCpidAdmin)?false:true"
                        :file-name="'CodiceCpid'"
                        :vars="{view: 'administrationEdit', module: module, isDocAdmin:true}"
                        v-model="codiceCpidAdmin"
                        :label="'Codice CPID'"                        
                    />
                </v-col>
                <v-col cols="12" align="end">
                    <v-btn rounded @click="stepperPosition--">
                        Indietro
                    </v-btn>
                </v-col>
            </v-stepper-content>
        </v-stepper>
        <v-dialog v-model="rejectDialog.visible" max-width="600">
            <v-card>
                <v-card-title class="primary white--text headline mb-3"> Rifiuto Pratica </v-card-title>
                <v-card-subtitle>
                    <br>
                    Sei sicuro di voler rifiutare la pratica? <br>
                    Questa azione riporterà la pratica in uno stati di bozza e ricaricherà il credito all'utente.
                </v-card-subtitle>
                <v-card-text>
                    <v-textarea label="Motivo rifiuto" v-model="rejectDialog.rejectReason" outlined />
                    <v-file-input 
                        outlined
                        chips
                        required
                        ref="allegatoRifiuto"
                        v-model="rejectDialog.attachedFile"
                        label="Allegato"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancelRejection(false)" :disabled="rejectDialog.loading"> Annulla </v-btn>
                    <v-btn @click="sendRejection()" :loading="rejectDialog.loading" :disabled="rejectDialog.loading" color="error"> Rifiuta </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { validationMixin } from "vuelidate";
    import { required, minLength, maxLength, minValue, maxValue} from "vuelidate/lib/validators";
    import { readSSN, uploadModule, uploadModuleLastStep, getModule, generateDocs, sendModule, downloadModuleDocs, downloadDocsCompilatore } from "@/api/module.api.js"
    import { checkLocalFile, checkAzureFile} from "@/api/file.api.js"
    import { updateModuleStatus, rejectModule } from "@/api/admin.api";

    import { getMunicipalityByZip } from "@/api/municipalities.api";


    import DatePicker from "@/components/datePicker";
    import FileUpload from "@/components/fileUpload"
    import Module from "@/models/Module";
    var convert = require('xml-js');
    export default {
        mixins: [validationMixin],
        components: { FileUpload, DatePicker },
        props: {
            editMode: Boolean,
            id: {
                type: String,
                default: ""
            }
        },
        validations: {
            module: {
                serviceType: { required },
                beneficiarySsn: { required, minLength: minLength(16), maxLength: maxLength(16) },
                beneficiaryAttorneySsn: { required, minLength: minLength(16), maxLength: maxLength(16) },
                trasmissioneSolare: { maxValue: maxValue(0,35)}
            },
            numberOfSerramenti: { minValue: minValue(0), maxValue: maxValue(10) },
            numberOfSchermature: { maxValue: maxValue(10) },
            numberOfSelectedManufatto: { maxValue: maxValue(10)}
        },
        data() {
            return {
                saveDraftLastStepLoading:false,
                module: new Module(),
                fileXml:null,
                stepperPosition: 0,
                ricevutaDocumentoAdmin:null,
                codiceCpidAdmin:null,
                rejectDialog: {
                    visible: false,
                    loading: false,
                    moduleId: null,
                    module: null,
                    rejectReason: "",
                    attachedFile: null

                },
                saveDraftLoading: false,
                sendModuleLoading:false,
                downloadModuleDocumentsLoading: false,
                downloadDocumentiCompilatoreLoading: false,
                downloadDocumentoAsseveratoreLoading: false,
                downloadDocumentsLoading:false,
                serviceTypes:
                [
                    {
                        text: "Ecobonus",
                        value: "1" 
                    },
                    {
                        text: "Bonus Casa",
                        value: "2"
                    },
                    {
                        text: "Bonus Sicurezza",
                        value: "3"
                    }
                ],
               
                beneficiaryCompanyTypes:
                [
                    {
                        text: "Soggetto con partita iva",
                        value: "0"
                    },
                    {
                        text: "Altro soggetto senza partita iva",
                        value: "1"
                    },
                ],
                beneficiaryGenders:
                [
                    {
                        text: "M",
                        value: "M"
                    },
                    {
                        text: "F",
                        value: "F"
                    }
                ],
                immovablePossessionTitles:
                [
                    {
                        text: "Proprietario o comproprietario",
                        value: "0"
                    },
                    {
                        text: "Detentore o co-detentore",
                        value: "1"
                    },
                    {
                        text: "Familiare convivente",
                        value: "2"
                    },
                    {
                        text: "Promissario acquirente",
                        value: "3"
                    }
                ],
              
                buildingTypologies:
                [
                    {
                        text: "Edificio oltre i tre piani fuori terra",
                        value: "0"
                    },
                    {
                        text: "Edificio fino a tre piani fuori terra",
                        value: "1"
                    },
                    {
                        text: "Costruzione isolata (villette mono, bi, o tri familiari)",
                        value: "2"
                    },
                    {
                        text: "Edificio industriale artigianale o commerciale",
                        value: "3"
                    },
                    {
                        text: "Altro",
                        value: "4"
                    }
                ],
                cadastralCategories:
                [
                    {
                        text: "A1",
                        value: "A1"
                    },
                    {
                        text: "A2",
                        value: "A2"
                    },{
                        text: "A3",
                        value: "A3"
                    },
                    {
                        text: "A4",
                        value: "A4"
                    },
                    {
                        text: "A5",
                        value: "A5"
                    },
                    {
                        text: "A6",
                        value: "A6"
                    },
                    {
                        text: "A7",
                        value: "A7"
                    },
                    {
                        text: "A8",
                        value: "A8"
                    },
                    {
                        text: "A9",
                        value: "A9"
                    },
                    {
                        text: "A10",
                        value: "A10"
                    },
                    {
                        text: "A11",
                        value: "A11"
                    },
                    {
                        text: "B1 Collegi, convitti, educandati, ricoveri, orfanotrofi, ospizi, conventi, seminari, caserme",
                        value: "B1"
                    },
                    {
                        text: "B2 Case di cura e ospedali senza fini di lucro",
                        value: "B2"
                    },
                    {
                        text: "B4 Uffici pubblici",
                        value: "B4"
                    },
                    {
                        text: "B5 Scuole e laboratori scientifici",
                        value: "B5"
                    },
                    {
                        text: "B6 Biblioteche, pinacoteche, musei, gallerie, accademie che non hanno sede in edifici della categoria A/9",
                        value: "B6"
                    },
                    {
                        text: "B7 Cappelle e oratori non destinati all'esercizio di pubblico culto",
                        value: "B7"
                    },
                    {
                        text: "C1 Negozi e botteghe",
                        value: "C1"
                    },
                    {
                        text: "C2 Magazzini e locale di deposito (ma solo per cantine o soffitte di pertinenza di un edificio abitativo)",
                        value: "C2"
                    },
                    {
                        text: "C3 Laboratori per arti e mestieri",
                        value: "C3"
                    },
                    {
                        text: "C4 Fabbricati e locali per esercizi sportivi senza fine di lucro",
                        value: "C4"
                    },
                    {
                        text: "C5 Stabilimenti Balneari e di acque curative",
                        value: "C5"
                    },
                    {
                        text: "C6 Garage, stalle, scuderie, rimesse ed autorimesse (ma solo per i garage di pertinenza di un edificio abitativo!)",
                        value: "C6"
                    },

                    {
                        text: "D1 Opifici",
                        value: "D1"
                    },
                    {
                        text: "D2 Alberghi e pensioni",
                        value: "D2"
                    },
                    {
                        text: "D3 Teatri, cinematografi, sale per concerti e spettacoli e simili con fini di lucro",
                        value: "D3"
                    },
                    {
                        text: "D4 Case di cura ed ospedali con fini di lucroo",
                        value: "D4"
                    },
                    {
                        text: "D5 Istituti di credito, cambio ed assicurazione",
                        value: "D5"
                    },
                    {
                        text: "D6 Fabbricati e locali sportivi con fini di lucro",
                        value: "D6"
                    },
                    {
                        text: "D7 Fabbricati costruiti od adattati per le speciali esigenze di attività industriale",
                        value: "D7"
                    },
                    {
                        text: "D8 Fabbricati costruiti od adattati per le speciali esigenze di attività commerciali",
                        value: "D8"
                    },
                    {
                        text: "D10 Fabbricati per funzioni produttive connesse alle attività agricole",
                        value: "D10"
                    },
                    {
                        text: "E7 Fabbricati destinati all'esercizio pubblico di culto",
                        value: "E7"
                    },
                    {
                        text: "Altro",
                        value: "altro"
                    },



                    
                ],
                
                immovableParticularUsesHardAttributions:
                [
                    {
                        text: "B3 - Progioni e riformatori",
                        value: "B3"
                    },
                    {
                        text: "B8 - Magazzini sotterranei per depositi di derrate",
                        value: "B8"
                    },
                    {
                        text: "C7 - Tettoie chiuse o aperte",
                        value: "C7"
                    },
                    {
                        text: "D9 - Edifici galleggianti o sospesi assicurati a punti fissi del suolo, ponti privati soggetti a pedaggio",
                        value: "D9"
                    },
                    {
                        text: "E1 - Stazione per servizi di trasporto, terrestri, marittimi ed aerei",
                        value: "E1"
                    },
                    {
                        text: "E2 - Ponti comunali e provinciali soggetti a pedaggio",
                        value: "E2"
                    },
                    {
                        text: "E3 - Costruzioni e fabbricati per speciali esigenze pubbliche",
                        value: "E3"
                    },
                    {
                        text: "E4 - Recinti chiusi per speciali esigenze pubbliche",
                        value: "E4"
                    },
                    {
                        text: "E5 -Fabbricati costituenti fortificazioni e loro dipendenze",
                        value: "E5"
                    },
                    {
                        text: "E6 - Fari, semafori, torri per rendere d'uso pubblico l'orologio comunale",
                        value: "E6"
                    },
                    {
                        text: "E8- Fabbricati e costruzioni nei cimiteri ad esclusione di colombari, tombe di famiglia e sepolcri",
                        value: "E7"
                    },
                    {
                        text: "E9 - Edifici a destinazione particolare non compresi nelle categorie precedenti del gruppo E",
                        value: "E9"
                    },
                    {
                        text: "F1 - Area urbana",
                        value: "F2"
                    },
                    {
                        text: "F2 - Unità collabente",
                        value: "F2"
                    },
                    {
                        text: "F3 - Unità in corso di costruzione",
                        value: "F3"
                    },
                    {
                        text: "F4 - In corso di definizione",
                        value: "F4"
                    },
                    {
                        text: "F5 - Lastrico solare",
                        value: "F5"
                    }
                ],
                interventionOnImmovableTypes:
                [
                    {
                        text: "Singola unità immobiliare in un edificio costituito da più unità immobiliari come un condominio",
                        value: "0"
                    },
                    {
                        text: "Edificio costituito da una singola unità immobiliare",
                        value: "1"
                    }/*,
                    {
                        text: "Intero edificio",
                        value: "2"
                    }*/
                ],
                manufattoTypes:[],
                tipologieVetroPre:
                [
                    {
                        text: "Singolo",
                        value: "singolo"
                    },
                    {
                        text: "Doppio",
                        value: "doppio"
                    },
                    {
                        text: "Opaco",
                        value: "opaco"
                    }
                ],
                tipologieVetroPost:
                [
                    {
                        text: "Doppio",
                        value: "doppio"
                    },
                    {
                        text: "Triplo",
                        value: "triplo"
                    },
                    {
                        text: "Opaco",
                        value: "opaco"
                    }
                ],
                confini:
                [
                    {
                        text: "Verso esterno",
                        value: "esterno"
                    },
                    {
                        text: "Locale non riscaldato",
                        value: "noRiscaldato"
                    }
                ],
                tipologieSchermatura:
                [
                    {
                        text: "Tenda",
                        value: "tenda"
                    },
                    {
                        text: "Veneziana",
                        value: "veneziana"
                    },
                    {
                        text: "Altra schermatura solare",
                        value: "altraSchermatura"
                    }
                ],
                tipologieChiusuraPre:
                [
                    {
                        text: "Avvolgibili in pvc non coibentato",
                        value: "0"
                    },
                    {
                        text: "Avvolgibili in legno",
                        value: "1"
                    },
                    {
                        text: "Persiane in alluminio non coibentato (a stecca chiusa)",
                        value: "2"
                    },
                    {
                        text: "Avvolgibili in alluminio non coibentato",
                        value: "3"
                    },
                    {
                        text: "Persiane a stecca aperta e fissa",
                        value: "4"
                    },
                    {
                        text: "Scuri, antoni o persiane a stecca chiusa in legno",
                        value: "5"
                    },
                    {
                        text: "Nessuna chiusura",
                        value: "6"
                    }

                ],
                tipologieChiusuraPost:
                [
                    {
                        text: "Avvolgibili in pvc coibentato",
                        value: "0"
                    },
                    {
                        text: "Avvolgibili in pvc non coibentato",
                        value: "1"
                    },
                    {
                        text: "Avvolgibili in legno",
                        value: "2"
                    },
                    {
                        text: "Avvolgibili in alluminio coibentato",
                        value: "3"
                    },
                    {
                        text: "Persiane in alluminio coibentato (a stecca chiusa)",
                        value: "4"
                    },
                    {
                        text: "Avvolgibili in alluminio non coibentato",
                        value: "5"
                    },
                    {
                        text: "Persiane in alluminio non coibentato (a stecca chiusa)",
                        value: "6"
                    },
                    {
                        text: "Persiane a stecca aperta e fissa (in qualunque materiale)",
                        value: "7"
                    },
                    {
                        text: "Scuri, antoni o persiane a stecca chiusa in legno",
                        value: "8"
                    },
                ],
                installazioni:
                [
                    {
                        text: "Esterna",
                        value: "esterna"
                    },
                    {
                        text: "Interna",
                        value: "interna"
                    }
                ],
                tipologieFinestra:
                [
                    {
                        text: "Finestra",
                        value: "finestra"
                    },
                    {
                        text: "Portafinestra/scorrevole",
                        value: "portafinestra"
                    }
                ],
                tipologieVetrata:
                [
                    {
                        text: "Vetrata singola",
                        value: "singola"
                    },
                    {
                        text: "Vetrata doppia",
                        value: "doppia"
                    },
                    {
                        text: "Vetrata doppia basso emissiva",
                        value: "doppiaBassoEmissiva"
                    },
                    {
                        text: "Vetrata tripla basso emissiva",
                        valuie: "triplaBassoEmissiva"
                    }
                ],
                esposizioniSchermatura:
                [
                    {
                        text: "Est",
                        value: "est"
                    },
                    {
                        text: "Sud-est",
                        value: "sudEst"
                    },
                    {
                        text: "Sud",
                        value: "sud"
                    },
                    {
                        text: "Sud-ovest",
                        value: "sudOvest"
                    }
                ],
                esposizioniChiusura:
                [
                    {
                        text: "Nord",
                        value: "nord"
                    },
                    {
                        text: "Nord-est",
                        value: "nordEst"
                    },
                    {
                        text: "Sud-est",
                        value: "sudEst"
                    },
                    {
                        text: "Est",
                        value: "est"
                    },
                    {
                        text: "Sud",
                        value: "sud"
                    },
                    {
                        text: "Sud-ovest",
                        value: "sudOvest"
                    },
                    {
                        text: "Ovest",
                        value: "ovest"
                    },
                    {
                        text: "Nord-ovest",
                        value: "nordOvest"
                    }
                ],
                tipiChiusura:
                [
                    {
                        text: "Vecchia chiusura oscurante",
                        value: "vecchia"
                    },
                    {
                        text: "Nuova chiusura oscurante",
                        value: "nuova"
                    }
                ],
                modalitaDiCalcoloResistenzaTermica:
                [
                    {
                        text: "Dichiarato dal fornitore",
                        value: "fornitore"
                    },
                    {
                        text: "Dalla tabella del programma chiusure oscuranti di ENEA",
                        value: "tabellaEnea"
                    },
                    {
                        text: "Calcolato secondo la Norma UNI EN 13125",
                        value: "norma"
                    }
                ],
                materialiSchermatura:
                [
                    {
                        text: "PVC",
                        value: "pvc"
                    },
                    {
                        text: "Tessuto",
                        value: "tessuto"
                    },
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Metallo",
                        value: "metallo"
                    },
                    {
                        text: "Plastica",
                        value: "plastica"
                    },
                    {
                        text: "Misto",
                        value: "misto"
                    }
                ],
                materialiChiusura:
                [
                    {
                        text: "PVC",
                        value: "pvc"
                    },
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Metallo",
                        value: "metallo"
                    },
                    {
                        text: "Plastica",
                        value: "plastica"
                    },
                    {
                        text: "Misto",
                        value: "misto"
                    }
                ],
                meccanismiRegolazione:
                [
                    {
                        text: "Manuale",
                        value: "manuale"
                    },
                    {
                        text: "Motorizzato",
                        value: "motorizzato"
                    },
                    {
                        text: "Automatizzato con sensori",
                        value: "automatizzato"
                    }
                ],
                statiChiusuraOscurante:
                [
                    {
                        text: "Vecchia chiusura oscurante",
                        value: "vecchia"
                    },
                    {
                        text: "Nuova chiusura oscurante",
                        value: "nuova"
                    }
                ],
                tipiImpianto:
                [
                    {
                        text: "Impianto autonomo",
                        value: "autonomo"
                    },
                    {
                        text: "Impianto centralizzato",
                        value: "centralizzato"
                    },
                    {
                        text: "Impianto centralizzato con contabilizzazione di calore per singolo utente",
                        value: "centralizzatoContabilizzazione"
                    },
                    {
                        text: "Impianto centralizzato con più generatori di calore",
                        value: "centralizzatoGeneratori"
                    },
                    {
                        text: "Impianto centralizzato con più generatori di calore con contabilizzazione di calore per singolo utente",
                        value: "centralizzatoGeneratoriContabilizzazione"
                    }
                ],
                tipiTerminaliErogazione:[
                    {
                        text: "Termoconvettori",
                        value: "termoconvettori"
                    },
                    {
                        text: "Ventilconvettori",
                        value: "ventilconvettori"
                    },
                    {
                        text: "Bocchette aria calda",
                        value: "bocchette"
                    },
                    {
                        text: "Radiatori/termosifoni",
                        value: "radiatori"
                    },
                    {
                        text: "Pannelli radianti isolati dalle strutture",
                        value: "pannelliIsolati"
                    },
                    {
                        text: "Pannelli radianti annegati nella struttura",
                        value: "pannelliAnnegati"
                    },
                    {
                        text: "Split",
                        value: "split"
                    },
                    {
                        text: "Altro",
                        value: "altro"
                    }
                ],
                tipiDistribuzione:[
                    {
                        text: "Edifici a colonne montanti situate totalmente all'interno degli ambienti riscaldati",
                        value: "edificiAmbientiRiscaldati"
                    },
                    {
                        text: "Edifici a colonne montanti non isolate termicamente inserite all'interno delle pareti",
                        value: "edificiColonneNonIsolate"
                    },
                    {
                        text: "Edifici a colonne montanti isolate secondo normativa e ubicate all'interno delle pareti",
                        value: "edificiColonneIsolate"
                    },
                    {
                        text: "Edifici con distribuzione orizzontale o ad anello",
                        value: "edificiDistribuzioneOrizzontale"
                    },
                    {
                        text: "Altro",
                        value: "altro"
                    }
                ],
                tipiRegolazione:[
                    {
                        text: "Regolazione centralizzata",
                        value: "centralizzata"
                    },
                    {
                        text: "Regolazione sul terminale di erogazione",
                        value: "terminaleErogazione"
                    },
                    {
                        text: "Regolazione ad ambiente o a zona",
                        value: "ambienteZona"
                    },
                    {
                        text: "Altro",
                        value: "altro"
                    }
                ],
                vettoriEnergetici:[
                    {
                        text: "Gas metano",
                        value: "metano"
                    },
                    {
                        text: "Gasolio",
                        value: "gasolio"
                    },
                    {
                        text: "Gpl",
                        value: "gpl"
                    },
                    {
                        text: "Teleriscaldamento",
                        value: "teleriscaldamento"
                    },
                    {
                        text: "Olio combustibile",
                        value: "olio"
                    },
                    {
                        text: "Energia elettrica",
                        value: "energiaElettrica"
                    },
                    {
                        text: "Biomassa",
                        value: "biomassa"
                    },
                    {
                        text: "Altro",
                        value: "altro"
                    }
                ],
                tipiGeneratore:[
                    {
                        text: "Caldaia ad acqua calda standard",
                        value: "Generatore_caldaia ACS"
                    },
                    {
                        text: "Caldaia ad acqua calda a bassa temperatura",
                        value: "Generatore_caldaia AC basse temp"
                    },
                    {
                        text: "Caldaia a gas a condensazione",
                        value: "Generatore_caldaia condensazione"
                    },
                    {
                        text: "Caldaia a gasolio a condensazione",
                        value: "Generatore_caldaia gasolio"
                    },
                    {
                        text: "Pompa di calore / impianto geotermico",
                        value: "Generatore_Pomapa Calore"
                    },
                    {
                        text: "Generatore aria calda",
                        value: "Generatore_Aria Calda"
                    },
                    {
                        text: "Scambiatore per teleriscaldamento",
                        value: "Generatore_Teleriscaldamento"
                    },
                    {
                        text: "Caldaia a biomassa",
                        value: "Generatore_Biomassa"
                    },
                    {
                        text: "Altro",
                        value: "Generatore_Altro"
                    }
                ],
                tipologieInfisso:[
                    {
                        text: "Finestra",
                        value: "finestra"
                    },
                    {
                        text: "Portoncino",
                        value: "portoncino"
                    },
                    {
                        text: "Cassonetto ispezione frontale",
                        value: "cassonettoIspezioneFrontale"
                    },
                    {
                        text: "Cielino",
                        value: "cielino"
                    }
                ],
                tipologieTelaioPost:[
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "PVC",
                        value: "pvc"
                    },
                    {
                        text: "Metallo taglio termico",
                        value: "metalloTermico"
                    },
                    {
                        text: "Metallo freddo",
                        value: "metalloFreddo"
                    },
                    {
                        text: "Misto",
                        value: "misto"
                    },


                ],
                tipologieTelaio :
                { "finestra": [
                    {
                        text: "Legno duro (rovere, mogano, iroko)",
                        value: "legnoDuro"
                    },
                    {
                        text: "Legno tenero (pino, abete, larice, douglas, hemlock)",
                        value: "legnoTenero"
                    },
                    {
                        text: "PVC a due camere",
                        value: "pvcDue"
                    },
                    {
                        text: "PVC a tre camere",
                        value: "pvcTre"
                    },
                    {
                        text: "Metallo taglio termico",
                        value: "metalloTermico"
                    },
                    {
                        text: "Metallo senza taglio termico",
                        value: "lamiera"
                    }
                    ],
                    "portoncino": [
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Lamiera",
                        value: "lamiera"
                    },
                    {
                        text: "PVC",
                        value: "pvc"
                    },
                    {
                        text: "Misto",
                        value: "misto"
                    },
                    {
                        text: "Metallo termico",
                        value: "metalloTermico"
                    }
                    ],
                    "cassonettoIspezioneFrontale": [
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Lamiera",
                        value: "lamiera"
                    },
                    {
                        text: "Metallo",
                        value: "lamiera"
                    },
                   
                    {
                        text: "PVC",
                        value: "pvc"
                    }],
                    "cielino": [
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Lamiera",
                        value: "lamiera"
                    },
                    {
                        text: "Metallo",
                        value: "legno"
                    },
                   
                    {
                        text: "PVC",
                        value: "pvc"
                    }
                ],

                },
                tipologieTelaioBonusCasa :
                { "finestra": [
                    {
                        text: "Legno duro (rovere, mogano, iroko)",
                        value: "legnoDuro"
                    },
                    {
                        text: "Legno tenero (pino, abete, larice, douglas, hemlock)",
                        value: "legnoTenero"
                    },
                    {
                        text: "PVC a due camere",
                        value: "pvcDue"
                    },
                    {
                        text: "PVC a tre camere",
                        value: "pvcTre"
                    },
                    {
                        text: "Metallo taglio termico",
                        value: "metalloTermico"
                    },
                    {
                        text: "Metallo senza taglio termico",
                        value: "lamiera"
                    }
                    ],
                    "portoncino": [
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "PVC",
                        value: "pvc"
                    },
                    {
                        text: "Misto",
                        value: "misto"
                    }
                    ],
                    "cassonettoIspezioneFrontale": [
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Metallo",
                        value: "legno"
                    },
                   
                    {
                        text: "PVC",
                        value: "pvc"
                    }],
                    "cielino": [
                    {
                        text: "Legno",
                        value: "legno"
                    },
                    {
                        text: "Metallo",
                        value: "legno"
                    },
                   
                    {
                        text: "PVC",
                        value: "pvc"
                    }
                ],

                },
                altezzeSoffitto:
                [
                    {
                        text: "fino a 2,7metri",
                        value: "<=2.7"
                    },
                    {
                        text: "maggiore di 2,7metri",
                        value: ">2,7"
                    },
                    
                ],
                profonditaAggetto:
                [
                    {
                        text: "Fino ad 1m",
                        value: "1"
                    },
                    {
                        text: "Tra 1m e 1,5m",
                        value: "1.5"
                    },
                    {
                        text: "Oltre 1,5m",
                        value: "2"
                    }
                ],
                posizioniAggetto:
                [
                    {
                        text: "Destra",
                        value: "destra"
                    },
                    {
                        text: "Sinistra",
                        value: "sinistra"
                    }
                ],
                distanzePalazzi:
                [
                    {
                        text: "10 m",
                        value: "10"
                    },
                    {
                        text: "20 m",
                        value: "20"
                    },
                    {
                        text: "30 m",
                        value: "30"
                    }
                ],
                differenzePiani:
                [
                    {
                        text: "1",
                        value: "1"
                    },
                    {
                        text: "2",
                        value: "2"
                    },
                    {
                        text: "3",
                        value: "3"
                    },
                    {
                        text: "4",
                        value: "4"
                    },
                    {
                        text: "5",
                        value: "5"
                    },
                    {
                        text: "6",
                        value: "6"
                    },
                    {
                        text: "7",
                        value: "7"
                    },
                    {
                        text: "8",
                        value: "8"
                    }
                ],
                permeabilitaAria:
                [
                    {
                        text: "Media",
                        value: "media"
                    },
                    {
                        text: "Bassa",
                        value: "bassa"
                    }
                ],
                selectedBeneficiaryResidentialMunicipality: null,
                selectedImmovableMunicipality: null,
                selectedImmovableCadastralCode: null,
                menu: false,
                menuBirthDate: false,
                menuBeneficiaryIdentificationDocDate: false,
                menuInterventionStartDatePicker: false,
                menuInterventionEstimatedEndDatePicker: false,
                menuInterventionEndDatePicker: false,
                manufattoType: "",
                numberOfSerramenti: 0,
                numberOfSchermature: 0,
                manufattiError: "",
                numberOfSelectedManufatto: 0,
                riscaldamento: false,
                dialogNotaTipoIntervento: false,
                dialogNotaGeneratori: false,
                dialogTabellaUwInfissiChiusure: false,
                dialogPermeabilitaAria:false,
                documentsRequired:[],
                creditError: "",
                hasEnoughCredits: true,
                canSend:true,
                moduleCredits: [],
                totalCost: 0,
                currentDate: ""
            }
        },
        created(){
            this.$store.dispatch("municipalities/getAllMunicipalities");
            var tmp = new Date();
            this.currentDate =   tmp.getFullYear()+'-'+(("0"+(tmp.getMonth()+1)).slice(-2))+'-'+tmp.getDate();
            this.manageManufattiTypes();
        },
        mounted(){
            if(this.id){
                getModule(this.id)
                .then(res => {
                    
                    this.numberOfSerramenti = res.data.manufatti['serramenti'].length;
                    this.numberOfSchermature = res.data.manufatti['schermature'].length;
                    this.numberOfChiusureOscuranti = res.data.manufatti['chiusure'].length;
                    this.module = res.data;
                    if(this.module.beneficiaryResidentialZip!=""){
                        this.selectedBeneficiaryResidentialMunicipality = this.module.beneficiaryResidentialZip+"_"+this.module.beneficiaryResidentialMunicipality+"_"+this.module.beneficiaryResidentialProvince+"_"+this.module.beneficiaryResidentialClimateZone;
                    }
                    if(this.module.immovableZip!=""){
                        this.selectedImmovableMunicipality = this.module.immovableZip+"_"+this.module.immovableMunicipality+"_"+this.module.immovableProvince+"_"+this.module.immovableClimateZone;
                        
                    }
                    this.manageManufattiTypes();
                    if(this.isAdmin){
                        checkAzureFile(this.module.accountId, this.module.id, "Ricevuta")
                        .then(r => {
                            if (r.data){
                                this.ricevutaDocumentoAdmin = 'trovato';
                            }
                        });
                        checkAzureFile(this.module.accountId, this.module.id, "CodiceCpid")
                        .then(r => {
                            if (r.data){
                                this.codiceCpidAdmin = 'trovato';
                            }
                        });
                        
                    }
                })
            }
        },
        computed: {
            ...mapGetters({
                municipalities: "municipalities/getAll",
                user: "authentication/user",
                statuses: "moduleStatuses/getAll"
            }),
            isAdmin(){
                return this.user.role == 'SuperAdmin' || this.user.role == 'Admin';
            },
            isToDownloadCompilatore(){
               
                if(this.module.manufatti['schermature']){
                    if(this.module.manufatti['schermature'].length >0) return true;
                }

                if(this.module.manufatti['chiusure']){
                    if(this.module.manufatti['chiusure'].length >0) return true;
                }
                return false;
            },
            immovableGeneralUses(){
                if(this.module.serviceType == "1" ||this.module.serviceType == 1 ){
                    return  [
                        {
                            text: "Residenziale",
                            value: "0"
                        },
                        {
                            text: "Non residenziale",
                            value: "1"
                        },
                        {
                            text: "Misto",
                            value: "2"
                        }
                    ];
                }else{
                    return  [
                        {
                            text: "Residenziale",
                            value: "0"
                        }];
                }
            },
            immovableParticularUses(){
                if(this.module.serviceType == "1" ||this.module.serviceType == 1 ){
                    return [
                    {
                        text: "Edifici adibiti a residenza e assimilabili (con carattere continuativo o saltuario)",
                        value: "0"
                    },
                    {
                        text: "Edifici adibiti a uffici e assimilabili",
                        value: "1"
                    },
                    {
                        text: "Edifici adibiti a ospedali, cliniche o case di cura e assimilabili",
                        value: "2"
                    },
                    {
                        text: "Edifici adibiti ad attività ricreative, associative o di culto e assimilabili (cinema, teatri, sale riunioni, musei, chiese e similari)",
                        value: "3"
                    },
                    {
                        text: "Edifici adibiti ad attività commerciali e assimilabili",
                        value: "4"
                    },
                    {
                        text: "Edifici adibiti ad attività sportive (piscine, palestre, servizi di supporto alle attività sportive)",
                        value: "5"
                    },
                    {
                        text: "Edifici adibiti ad attività scolastiche a tutti i livelli e assimilabili",
                        value: "6"
                    },
                    {
                        text: "Edifici adibiti ad attività industriali ed artigianali e assimilabili",
                        value: "7"
                    },
                    {
                        text: "Categorie catastali di difficile attribuzione",
                        value: "8"
                    }
                ];
                
                }else{
                    return [
                    {
                        text: "Edifici adibiti a residenza e assimilabili (con carattere continuativo o saltuario)",
                        value: "0"
                    }];

                }


            },
                
            trasmittanzaPostError: (app)=> (item)=> {
                if(!item.trasmittanzaPost)return ['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale', 'Campo obbligatorio']
                switch(app.module.immovableClimateZone){
                    case 'A':
                    case 'B':
                        if(item.trasmittanzaPost>2.60){
                            return['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale', ' Il valore deve essere <= 2.6 W/m2K']
                        }
                        break;
                    case 'C':
                        if(item.trasmittanzaPost>1.75){
                            return['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale', ' Il valore deve essere <= 1.75 W/m2K']
                        }
                        break;
                    case 'D':
                        if(item.trasmittanzaPost>1.67){
                            return['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale', ' Il valore deve essere <= 1.67 W/m2K']
                        }
                        break;
                    case 'E':
                        if(item.trasmittanzaPost>1.30){
                            return['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale', ' Il valore deve essere <= 1.30 W/m2K']
                        }
                        break;
                    case 'F':
                        if(item.trasmittanzaPost>1.00){
                            return['NOTA: il valore da inserire sarebbe il valore calcolato secondo la norma UNI EN 14351 per il serramento normato e non il valore puntuale', ' Il valore deve essere <= 1.00 W/m2K']
                        }
                        break;
                }
                return  [];   
            },
            userLastStepNumber(){
                if(this.isEcobonus){
                    return 10;
                }else{
                    return 8;
                }

            },
            
            docFolder() {
                return this.user.id+"/"+this.module.id;
            },
            beneficiaryTypes(){
               
                if(this.module.serviceType =="1" || this.module.serviceType ==1){
                    return [
                        {
                            text: "Persona giuridica",
                            value: true
                        },
                        {
                            text: "Persona fisica",
                            value: false
                        }
                    ]
                }else{
                    return [
                        {
                            text: "Persona fisica",
                            value: false
                        }
                    ]
                }
                
            },
            isEcobonus(){
                return this.module.serviceType =="1" || this.module.serviceType ==1;
            },
            isBonusCasa(){
                return this.module.serviceType =="2" || this.module.serviceType ==2;
            },
            isBonusSicurezza(){
                return this.module.serviceType =="3" || this.module.serviceType ==3;
            },
            // validazioni punti
            isZeroStepFilled() {
                return this.validateZeroStep()
            },
            isFirstStepFilled(){
                return this.validateFirstStep();
            },
            isSecondStepFilled(){
                return this.validateSecondStep();
            },
            isThirdStepFilled(){
                return this.validateThirdStep();
            },
            isFourthStepFilled(){
                return this.validateFourthStep();
            },
            isFifthStepFilled(){
                return this.validateFifthStep();
            },
            isSixthStepFilled(){
                return this.validateSixthStep();
            },
            isSeventhStepFilled(){
                return this.validateSeventhStep();
            },
            isEighthStepFilled(){
                return this.validateEighthStep();
            },
            isNinthStepFilled(){
                return this.validateNinthStep();
            },
            isTenthStepFilled(){
                return this.validateTenthStep();
            },
            isEleventhStepFilled(){
                return this.valudateEleventhStep();
            },
            isManufattiInserted(){
                return this.validateManufatti();
            },
            //errori campi
            serviceTypeErrors () {
                const errors = [];
                if(this.module.serviceType == null || this.module.serviceType == ''){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiaryTypeErrors() {
                const errors = [];
                if(this.module.isCompany === '' ){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiaryCompanyTypeErrors() {
                const errors = [];
                if(this.module.beneficiaryCompanyType === '' ){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiarySsnErrors () {
                const errors = [];
                //if (!this.$v.module.codiceFiscale.$dirty) return errors;
                if(this.module.beneficiarySsn == null || this.module.beneficiarySsn==""){
                    errors.push("Campo obbligatorio")
                }else{
                    if(!this.$v.module.beneficiarySsn.minLength || !this.$v.module.beneficiarySsn.maxLength){
                        errors.push("Il codice fiscale deve essere composto da 16 caratteri");     
                    }
                }
                return errors;
            }, 
            beneficiaryAttorneySsnErrors () {
                const errors = [];
                //if (!this.$v.module.codiceFiscale.$dirty) return errors;
                if(this.module.beneficiaryAttorneySsn == null || this.module.beneficiaryAttorneySsn==""){
                    errors.push("Campo obbligatorio")
                }else{
                    if(!this.$v.module.beneficiaryAttorneySsn.minLength || !this.$v.module.beneficiaryAttorneySsn.maxLength){
                        errors.push("Il codice fiscale deve essere composto da 16 caratteri");     
                    }
                }
                return errors;
            }, 
            beneficiaryGenderErrors() {
                const errors = [];
                if(this.module.beneficiaryGender == null || this.module.beneficiaryGender==""){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiaryAttorneyGenderErrors() {
                const errors = [];
                if(this.module.beneficiaryAttorneyGender == null || this.module.beneficiaryAttorneyGender==""){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiaryBirthDateErrors() {
                const errors = [];
                if(this.module.beneficiaryBirthDate == null || this.module.beneficiaryBirthDate==""){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiaryAttorneyBirthDateErrors() {
                const errors = [];
                if(this.module.beneficiaryAttorneyBirthDate == null || this.module.beneficiaryAttorneyBirthDate==""){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            beneficiaryIdentificationDocDateErrors() {
                const errors = [];
                if(this.module.beneficiaryIdentificationDocDate == null || this.module.beneficiaryIdentificationDocDate==""){
                    errors.push("Campo obbligatorio")
                }
                return errors;
            },
            // funzioni generali
            formattedBeneficiaryBirthDate(){
                if(this.module.beneficiaryBirthDate){
                    this.module.beneficiaryBirthDate=this.module.beneficiaryBirthDate.split('T')[0];
                }
                return this.module.beneficiaryBirthDate
                    ? this.module.beneficiaryBirthDate.split("-").reverse().join("/")
                    : "";
            },
            formattedBeneficiaryAttorneyBirthDate(){
                if(this.module.beneficiaryAttorneyBirthDate){
                    this.module.beneficiaryAttorneyBirthDate=this.module.beneficiaryAttorneyBirthDate.split('T')[0];
                }
                return this.module.beneficiaryAttorneyBirthDate
                    ? this.module.beneficiaryAttorneyBirthDate.split("-").reverse().join("/")
                    : "";
            },
            formattedBeneficiaryIdentificationDocDate(){
                if(this.module.beneficiaryIdentificationDocDate){
                    this.module.beneficiaryIdentificationDocDate=this.module.beneficiaryIdentificationDocDate.split('T')[0];
                }
                return this.module.beneficiaryIdentificationDocDate
                    ? this.module.beneficiaryIdentificationDocDate.split("-").reverse().join("/")
                    : "";
            },
            formattedInterventionEstimatedEndDate() {
                if(this.module.interventionEstimatedEndDate){
                    this.module.interventionEstimatedEndDate=this.module.interventionEstimatedEndDate.split('T')[0];
                }
                return this.module.interventionEstimatedEndDate
                    ? this.module.interventionEstimatedEndDate.split("-").reverse().join("/")
                    : "";
            },
            formattedInterventionStartDate() {
                if(this.module.interventionStartDate){
                    this.module.interventionStartDate=this.module.interventionStartDate.split('T')[0];
                }
                return this.module.interventionStartDate
                    ? this.module.interventionStartDate.split("-").reverse().join("/")
                    : "";
            },
            formattedInterventionEndDate() {
                if(this.module.interventionEndDate){
                    this.module.interventionEndDate=this.module.interventionEndDate.split('T')[0];
                }
                return this.module.interventionEndDate
                    ? this.module.interventionEndDate.split("-").reverse().join("/")
                    : "";
            }
        },
        methods: {
            openInNewTab (href) {
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: href,
                }).click();
            },
            onStatusChange(module) {
                if (module && module.status && (module.status.code == "REJECTED" || module.status.code == "WAITING")) {
                    this.rejectDialog.visible = true;
                    this.rejectDialog.moduleId = module.id
                    this.rejectDialog.statusCode = module.status.code
                    return
                }
                this.loadingTable = true;

                updateModuleStatus(module).then(res => {
                    this.oldStatus = module.status.code;
                }).catch(e => {
                    this.module.status.code = this.oldStatus
                    this.$store.dispatch('alert/error', e.response.data);
                }).finally(() => {
                    this.loadingTable = false;
                })
            },
            sendRejection() {
                this.rejectDialog.loading = true
                const form = this.rejectDialog
                form.loading = true
                let formData = new FormData();
                formData.append('moduleId', form.moduleId);
                formData.append('rejectReason', form.rejectReason);
                formData.append('statusCode', form.statusCode);
                if (form.attachedFile)
                    formData.append('attachedFile', form.attachedFile);

                rejectModule(formData).then(() => {
                    this.cancelRejection(true);
                }).finally(() => {this.rejectDialog.loading = false})
                
            },
            cancelRejection(isRejected) {
                this.rejectDialog = {
                    visible: false,
                    loading: false,
                    rejectReason: "",
                    moduleId: null,
                    attachedFile: null,
                    statusCode: ""
                }
                if (!isRejected){
                    this.module.status.code = this.oldStatus
                }

            },
            manageCompletedStep(){
                //NON FUNZIONA!!!
                //var oldStep = this.stepperPosition
                //document.getElementById('content-tab0').childNodes[0].style.height = "300px !important";
                //console.log(document.getElementById('content-tab0').childNodes[0])
                this.stepperPosition++  
            },
            downloadModuleDocuments(){
                this.downloadModuleDocumentsLoading = true;
                downloadModuleDocs(this.module.id).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([response.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Documenti Pratica");
                }).finally(_ => {
                    this.downloadModuleDocumentsLoading = false
                });
            },
            downloadDocumentiCompilatore(){
                this.downloadDocumentiCompilatoreLoading = true;
                downloadDocsCompilatore(this.module.id).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([response.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Documenti Compilatore");
                }).finally(_ => {
                    this.downloadDocumentiCompilatoreLoading = false
                });
            },
            validateZeroStep(){
                if(this.stepperPosition !== 0){
                    if(this.module.serviceType === null || this.module.serviceType === ""){
                        return false;
                    }
                }
                if(this.module.annoRiferimento == ""){
                    return false
                }
                return true;
            },
            validateFirstStep(){
                //return true;
                if(this.module.isCompany){
                    if(
                        !this.module.beneficiaryCompanyType ||
                        !this.module.beneficiaryCompanyName ||
                        !this.module.beneficiarySsn ||
                        !this.module.beneficiaryAttorneyFirstName ||
                        !this.module.beneficiaryAttorneyLastName ||
                        !this.module.beneficiaryAttorneySsn
                    ){
                        return false
                    }
                }else{
                    if(
                        !this.module.beneficiaryFirstName ||
                        !this.module.beneficiaryLastName ||
                        !this.module.beneficiarySsn ||
                        !this.module.beneficiaryBirthDate ||
                        !this.module.beneficiaryBirthMunicipality ||
                        !this.module.beneficiaryBirthNation ||
                        !this.module.beneficiaryBirthProvince ||
                        !this.module.beneficiaryGender ||
                        !this.module.beneficiaryPhone
                    )
                    {
                        return false
                    }
                }
                return true
            },
            validateSecondStep(){
                //return true
                if(this.module.acquisizioneBeneficiarioAmbrosi){
                    return true;
                }
                if(
                    !this.module.beneficiaryResidentialAddress ||
                    !this.module.beneficiaryResidentialMunicipality ||
                    !this.module.beneficiaryResidentialProvince ||
                    !this.module.beneficiaryResidentialZip ||
                    !this.module.beneficiaryResidentialNation
                ){

             

                    
                    

                    return false
                }
                return true
            },
            validateThirdStep(){
                //return true
                if(this.module.acquisizioneBeneficiarioAmbrosi){
                    return true;
                }

                if(this.isEcobonus){
                    if(
                    !this.module.immovableAddress ||
                    !this.module.immovableMunicipality ||
                    !this.module.immovableProvince ||
                    !this.module.immovableZip ||
                    !this.module.immovableClimateZone
                    ){
                        return false
                    }
                }else{
                    if(
                    !this.module.immovableAddress ||
                    !this.module.immovableMunicipality ||
                    !this.module.immovableProvince ||
                    !this.module.immovableZip 
                    ){
                        return false
                    }


                }
                
                return true
            },
            validateFourthStep(){
                //return true
                if(this.module.acquisizioneBeneficiarioAmbrosi){
                    return true;
                }
                if(
                    !this.module.cadastralParcel ||
                    !this.module.cadastralSheet ||
                    !this.module.immovableMunicipalityCode ||
                    !this.module.immovableCadastralCode
                ){
                    return false
                }
                return true
            },
            validateFifthStep(){
                //return true
                if(this.module.acquisizioneBeneficiarioAmbrosi){
                    return true;
                }

                if(this.isEcobonus){
                    if(
                    !this.module.immovableEstimatedYearOfConstruction ||
                    !this.module.immovableSurfaceArea ||
                    !this.module.immovableUnitNumber ||
                    !this.module.immovablePossessionTitle ||
                    !this.module.immovableGeneralUse ||
                    !this.module.immovableParticularUse ||
                    !this.module.immovableBuildingTypology
                    ){
                        return false
                    }
                }else{
                    if(
                    !this.module.immovableEstimatedYearOfConstruction ||
                    !this.module.immovableSurfaceArea ||
                    !this.module.immovableUnitNumber ||
                    !this.module.immovablePossessionTitle ||
                    !this.module.immovableGeneralUse ||
                    !this.module.immovableParticularUse ||
                    !this.module.immovableBuildingTypology
                    ){
                        return false
                    }


                }
                
                return true
            },
            validateSixthStep(){
                //return true
                if(this.module.acquisizioneBeneficiarioAmbrosi == 1){
                    return true;
                }
                if(
                    !this.module.interventionOnImmovableType ||
                    //!this.module.interventionImmovableUnitNumber ||
                    !this.module.interventionStartDate
                ){
                    return false
                }
                return true
            },
            /*validateSeventhStep(){
                return true;
                if(
                    this.numberOfSerramenti < 1 && 
                    this.numberOfSchermature < 1 &&
                    this.numberOfChiusureOscuranti < 1
                ){
                    return false
                }
                return true
            },*/
            validateManufatti(){
                if(
                    this.module.manufatti['serramenti'].length == 0 &&
                    this.module.manufatti['schermature'].length == 0 &&
                    this.module.manufatti['chiusure'].length == 0
                ){
                    return false
                }
                return true
            },
            validateSeventhStep(){
                //return true;
                var errors = false;
                if(
                    this.module.manufatti['serramenti'].length == 0 &&
                    this.module.manufatti['schermature'].length == 0 &&
                    this.module.manufatti['chiusure'].length == 0
                ){
                    return false
                }

                if(this.module.manufatti['serramenti'].length !== 0){
                    for (let i = 1; i <= this.module.manufatti['serramenti'].length; i++) {
                        var serramento = this.module.manufatti['serramenti'][i-1];
                        if(this.isEcobonus){
                            if(
                            !serramento.confine ||
                            !serramento.superficie ||
                            !serramento.tipologiaInfisso ||
                            !serramento.tipologiaTelaioPost ||
                            !serramento.tipologiaTelaioPre ||
                            !serramento.tipologiaVetroPost ||
                            !serramento.tipologiaVetroPre ||
                            !serramento.trasmittanzaPost ||
                            !serramento.trasmittanzaPre
                            ){
                                errors = true;
                                break;
                            }
                        }else{
                            if(
                            !serramento.confine ||
                            !serramento.superficie ||
                            //!serramento.tipologiaTelaioPost ||
                            !serramento.tipologiaVetroPost ||
                            !serramento.tipologiaVetroPre ||
                            !serramento.trasmittanzaPost ||
                            !serramento.trasmittanzaPre
                            ){
                                errors = true;
                                break;
                            }

                        }
                        
                    }
                    if(errors){ return false }
                }
                if(this.module.manufatti['schermature'].length !== 0){
                    for(let j = 1; j <= this.module.manufatti['schermature'].length; j++){
                        var schermatura = this.module.manufatti['schermature'][j-1];
                        if(
                            !schermatura.tipologia ||
                            !schermatura.installazione ||
                            !schermatura.superficieSchermatura ||
                            !schermatura.superficieFinestrata ||
                            !schermatura.tipologiaFinestra ||
                            !schermatura.esposizione ||
                            !schermatura.tipologiaVetrata ||
                            !schermatura.trasmissioneSolare ||
                            !schermatura.materialeSchermatura ||
                            !schermatura.meccanismoRegolazione ||
                            !schermatura.modalitaCalcoloResistenzaTermica
                        ){
                            errors = true;
                            break;
                        }
                    }
                    if(errors){return false}
                }
                if(this.module.manufatti['chiusure'].length !== 0){
                    for(let i = 1; i <= this.module.manufatti['chiusure'].length; i++){
                        var chiusura = this.module.manufatti['chiusure'][i-1];
                        if(
                            !chiusura.tipologiaPre ||
                            !chiusura.tipologiaPost ||
                            !chiusura.installazione ||
                            !chiusura.superficieChiusura ||
                            !chiusura.superficieFinestrata ||
                            !chiusura.permeabilitaAria ||
                            !chiusura.esposizione ||
                            !chiusura.resistenzaTermicaIniziale ||
                            !chiusura.resistenzaTermicaFinale ||
                            !chiusura.modalitaCalcoloResistenzaTermica ||
                            !chiusura.trasmissioneSolare ||
                            !chiusura.uwInfissoChiusura ||
                            //!chiusura.materialeChiusura ||
                            !chiusura.meccanismoRegolazione
                        ){
                            errors = true;
                            break;
                        }
                    }
                    if(errors){return false}
                }
                return true
            },
            validateEighthStep(){
                if(this.module.manufatti['serramenti'].length >0){
                    if(
                        !this.module.tipoImpianto ||
                        //!this.module.tipoTerminaleErogazione ||
                        //!this.module.tipoRegolazione ||
                        !this.module.marcaImpiantoTermico ||
                        !this.module.modelloImpiantoTermico
                    ){
                        return false
                    }
                }
                return true
            },
            validateNinthStep(){
                if( this.module.serviceType =="2" || this.module.serviceType ==2 || this.module.serviceType =="3" || this.module.serviceType ==3){
                    // bonus casa
                    return true;
                }
                //return true
                if(this.module.manufatti['serramenti'].length !== 0){
                    if(
                        !this.module.spese.speseProfessionaliSerramenti ||
                        !this.module.spese.spesaDetraibileSerramenti
                    ){
                        return false
                    }
                }
                if(this.module.manufatti['schermature'].length !== 0){
                    if(
                        !this.module.spese.speseProfessionaliSchermature ||
                        !this.module.spese.spesaDetraibileSchermature
                    ){
                        return false
                    }
                }
                if(this.module.manufatti['chiusure'].length !== 0){
                    if(
                        !this.module.spese.speseProfessionaliChiusure ||
                        !this.module.spese.spesaDetraibileChiusure
                    ){
                        return false
                    }
                }
                return true
            },
            validateTenthStep(){
                return true
            },

            updatedService(){
                if(this.module.serviceType =="2" || this.module.serviceType ==2){
                    this.module.isCompany = false;
                }
                this.manageManufattiTypes();
            },
            manageManufattiTypes(){
                // in base al servizio scelto, gestisco i manufatti che possono essere inseriti
                if(this.isEcobonus){
                    this.manufattoTypes = [
                        {
                            text: "Serramenti e infissi",
                            value: "serramenti"
                        },
                        {
                            text: "Schermature solari",
                            value: "schermature"
                        },
                        {
                            text: "Chiusure oscuranti",
                            value: "chiusure"
                        }
                    ]
                }else{
                    this.manufattoTypes = [
                        {
                            text: "Serramenti e infissi",
                            value: "serramenti"
                        }
                    ]
                }
            },
            readSSN(){
                readSSN(this.module.beneficiarySsn).then((res) => {
                    var codiceFiscale = res.data;
                    this.module.beneficiaryGender = codiceFiscale.genere;
                    this.module.beneficiaryBirthDate = codiceFiscale.nascita;
                    this.module.beneficiaryBirthMunicipality = codiceFiscale.comune;
                    this.module.beneficiaryBirthProvince = codiceFiscale.provincia;
                    this.module.beneficiaryBirthNation = "Italia";
                }).catch((e) => {
                    this.$store.dispatch('alert/error', "codice fiscale non riconosciuto; ricontrollare se è effettivamente corretto compilare i campi sucessivi");
                })
            },
            readAttorneySSN(){
                readSSN(this.module.beneficiaryAttorneySsn).then((res) => {
                    var codiceFiscale = res.data;
                    this.module.beneficiaryAttorneyGender = codiceFiscale.genere;
                    this.module.beneficiaryAttorneyBirthDate = codiceFiscale.nascita;
                    this.module.beneficiaryAttorneyBirthMunicipality = codiceFiscale.comune;
                    this.module.beneficiaryAttorneyBirthProvince = codiceFiscale.provincia;
                    this.module.beneficiaryAttorneyBirthNation = "Italia";
                }).catch((e) => {
                    this.$store.dispatch('alert/error', "codice fiscale non riconosciuto; ricontrollare se è effettivamente corretto compilare i campi sucessivi");
                })
            },
            addNewBeneficiary(){
                this.module.otherBeneficiaries.push(
                    {
                        isCompany: false, 
                        beneficiaryCompanyName: "", 
                        beneficiaryFirstName: "",
                        beneficiaryLastName: "", 
                        beneficiarySsn: ""
                    }
                );
               
            },
            removeNewBeneficiary(id){
                this.module.otherBeneficiaries.splice(id, 1);
            },
            manageBeneficiaryResidentialMunicipality(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedBeneficiaryResidentialMunicipality)
                    .then(
                        response => {
                            this.module.beneficiaryResidentialZip = response[0];
                            this.module.beneficiaryResidentialMunicipality = response[1];
                            this.module.beneficiaryResidentialProvince = response[2];
                            this.module.beneficiaryResidentialClimateZone = response[3];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            removeAggettoOrizzontale(k){
                this.module.manufatti['schermature'][k].aggettiOstruzioni.orizzontali = {
                    altezzaSoffitto: "",
                    profonditaAggetto: ""
                };

            },
            removeAggettoVerticale(k){
                this.module.manufatti['schermature'][k].aggettiOstruzioni.verticali = {                 
                    posizione: "",
                    distanza: "",
                    profondita: ""
                };
            },
            removeAggettoOstruzione(k){
                this.module.manufatti['schermature'][k].aggettiOstruzioni.ostruzioni = {
                    distanza: "",
                    differenzaPiani: ""
                };
              
            },
            /*trasmittanzaNuovoInfissoErrors(k){
                DA INSERIRE NELL'INPUT
                :rules="trasmittanzaNuovoInfissoErrors(k)"

                console.log(k);
                //console.log(this.module.manufatti['serramenti']);
                var trasmittanzaPost = this.module.manufatti['serramenti'][k].trasmittanzaPost;
                var max = 0;
                switch(this.module.immovableClimateZone){
                    case 'C':
                        max = 1.75;
                    break;
                    case 'D':
                        max = 1.67;
                    break;
                    case 'E':
                        max = 1.30;
                    break;
                    case 'F':
                        max = 1.00;
                    break;
                    default:
                        max = 2.60;
                    break;
                }
                return [false, 'Campo obbligatorio '+max];
            
           //return true;
            },*/
            manageImmovableMunicipality(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedImmovableMunicipality)
                    .then(
                        response => {
                            this.module.immovableZip = response[0];
                            this.module.immovableMunicipality = response[1];
                            this.module.immovableProvince = response[2];
                            this.module.immovableClimateZone = response[3];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            manageInterventionOnImmovableTypes(){
                if( this.module.interventionOnImmovableType == "1"){
                    this.module.interventionImmovableUnitNumber = "1";
                }else{
                    this.module.interventionImmovableUnitNumber = "";
                }
            },
            onCadastralCategoryChange(){
                switch (this.module.immovableCadastralCode){
                    case 'A1':
                    case 'A2':
                    case 'A3':
                    case 'A4':
                    case 'A5':
                    case 'A6':
                    case 'A7':
                    case 'A8':
                    case 'A9':
                    case 'A11':
                    case 'B1':
                    case 'D2':
                    case 'C2':
                    case 'C6':
                        this.module.immovableParticularUse = "0"
                        break;
                    case 'A10':
                    case 'B4':
                        this.module.immovableParticularUse = "1"
                        break;
                    case 'B2':
                    case 'D4':
                        this.module.immovableParticularUse = "2"
                        break;
                    case 'B6':
                    case 'B7':
                    case 'D3':
                    case 'E7':
                        this.module.immovableParticularUse = "3"
                        break;
                    case 'C1':
                    case 'D5':
                    case 'D8':
                        this.module.immovableParticularUse = "4"
                        break;
                    case 'C4':
                    case 'C5':
                    case 'D6':
                        this.module.immovableParticularUse = "5"
                        break;   
                    case 'B5':
                        this.module.immovableParticularUse = "6"
                        break;   
                    case 'C3':
                    case 'D1':
                    case 'D7':
                    case 'D10':
                        this.module.immovableParticularUse = "7"
                        break;  
                    default:
                        this.module.immovableParticularUse = "8"
                        break;  
                }
            },
            copyResidentialDataToImmovable(){
                this.module.immovableAddress = this.module.beneficiaryResidentialAddress
                this.module.immovableMunicipality = this.module.beneficiaryResidentialMunicipality;
                this.module.immovableProvince = this.module.beneficiaryResidentialProvince;
                this.module.immovableZip = this.module.beneficiaryResidentialZip;
                this.selectedImmovableMunicipality = this.module.beneficiaryResidentialZip+'_'+this.module.beneficiaryResidentialMunicipality+'_'+this.module.beneficiaryResidentialProvince+'_'+this.module.beneficiaryResidentialClimateZone;
                this.module.immovableClimateZone = this.module.beneficiaryResidentialClimateZone;
            },
            manageManufatti(){
                this.manufattiError = "";
                var serramenti = this.module.manufatti['serramenti'].length;
                var schermature = this.module.manufatti['schermature'].length;
                var chiusure = this.module.manufatti['chiusure'].length;
                switch(this.manufattoType){
                    case 'serramenti':
                        serramenti = serramenti + parseInt(this.numberOfSelectedManufatto);
                    break;
                    case 'schermature':
                        schermature = schermature + parseInt(this.numberOfSelectedManufatto);
                    break;
                    case 'chiusure':
                        chiusure = chiusure + parseInt(this.numberOfSelectedManufatto);
                    break;
                }

                if(this.manufattoType == "serramenti"){
                    if(serramenti > 10){
                        return this.manufattiError = "Il numero massimo di serramenti che è possibile aggiungere è 10";
                    }
                    for (let k = 1; k <= this.numberOfSelectedManufatto; k++){
                        var tmp = {
                            //id: this.module.manufatti['serramenti'].length+k,
                            tipologiaInfisso: null,
                            tipologiaTelaioPre: null,
                            tipologiaVetroPre: null,
                            trasmittanzaPre: null,
                            superficie: null,
                            tipologiaTelaioPost: null,
                            tipologiaVetroPost: null,
                            trasmittanzaPost: null,
                            confine: "esterno",
                            installazioneChiusuraOscurante: false
                        }
                        this.module.manufatti['serramenti'].push(tmp);
                    }
                }

                if(this.manufattoType == "schermature"){
                    if((schermature+chiusure) > 10){
                        return this.manufattiError = "Il numero massimo tra schermature e chiusure oscuranti che è possibile aggiungere è 10";
                    }
                    for (let k = 1; k <= this.numberOfSelectedManufatto; k++){
                        var tmp = {
                            //id: this.module.manufatti['schermature'].length+k,
                            tipologia: null,
                            installazione: "esterna",
                            superficieSchermatura: null,
                            superficieFinestrata: null,
                            tipologiaFinestra: null,
                            tipologiaVetrata: null,
                            esposizione: null,
                            trasmissioneSolare: null,
                            materialeSchermatura: null,
                            meccanismoRegolazione: null,
                            modalitaCalcoloResistenzaTermica: "tabellaEnea",
                            aggettiOstruzioni: {
                                orizzontali:{
                                    altezzaSoffitto: "",
                                    profonditaAggetto: ""
                                },
                                verticali: {
                                    posizione: "",
                                    distanza: "",
                                    profondita: ""
                                },
                                ostruzioni: {
                                    distanza: "",
                                    differenzaPiani: ""
                                }
                            }
                        };
                        this.module.manufatti['schermature'].push(tmp);
                    }
                }

                if(this.manufattoType == "chiusure"){
                    if((schermature+chiusure) > 10){
                        return this.manufattiError = "Il numero massimo tra schermature e chiusure oscuranti che è possibile aggiungere è 10";
                    }
                    for (let k = 1; k <= this.numberOfSelectedManufatto; k++){
                        var tmp = {
                            //id: this.module.manufatti['chiusure'].length+k,
                            tipologiaPre: "",
                            tipologiaPost: "",
                            installazione: "esterna",
                            superficieChiusura: "",
                            superficieFinestrata: "",
                            tipologiaVetrata: "",
                            esposizione: "",
                            permeabilitaAria: "",
                            resistenzaTermicaIniziale: "",
                            resistenzaTermicaFinale: "",
                            modalitaCalcoloResistenzaTermica: "tabellaEnea",
                            trasmissioneSolare: "",
                            uwInfissoChiusura: "",
                            materialeChiusura: "",
                            meccanismoRegolazione: ""
                        };
                        this.module.manufatti['chiusure'].push(tmp);
                    }
                }  
                
                if(this.module.id == 0){
                    this.saveDraft(false);
                }
            },
            removeManufatto(type, id){
                this.module.manufatti[type].splice(id, 1);
                switch(type){
                    case 'serramenti':
                        this.numberOfSerramenti--
                    break;
                    case 'schermature':
                        this.numberOfSchermature--
                    break;
                    case 'chiusure':
                        this.numberOfChiusureOscuranti--
                    break;
                }
            },
            manageTrasmittanzaVecchioInfisso(k){
                if(
                    this.module.manufatti['serramenti'][k].tipologiaTelaioPre == "" ||
                    this.module.manufatti['serramenti'][k].tipologiaVetroPre == ""
                )
                {
                    return false
                }

                var tipoInfisso = this.module.manufatti['serramenti'][k].tipologiaInfisso
                var tipoTelaio = this.module.manufatti['serramenti'][k].tipologiaTelaioPre
                var tipoVetro = this.module.manufatti['serramenti'][k].tipologiaVetroPre

                if(tipoVetro == 'opaco'){
                    if(tipoInfisso == 'cassonettoIspezioneFrontale'){
                        switch(tipoTelaio){
                            case 'legno':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "3.95";
                            break;
                            case 'lamiera':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "7.72";
                            break;
                            default:
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "2.51";
                            break;
                        }
                    }else if(tipoInfisso == 'cielino'){ 
                        switch(tipoTelaio){
                            case 'lamiera':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "10.48";
                            break;
                            default:
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "5.07";
                        }   
                    }else if(tipoInfisso == 'portoncino'){
                        switch(tipoTelaio){
                            case 'metalloTermico':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "5.3";
                            break;
                            case 'lamiera':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "6.0";
                            break;
                            case 'misto':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "5.2";
                            break;
                            default:
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "2.8";
                        }
                    }
                }else if(tipoVetro == 'singolo'){
                    if(tipoInfisso == 'finestra'){
                        switch(tipoTelaio){
                            case 'legnoDuro':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "5.05";
                            break;
                            case 'lamiera':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "6.00";
                            break;
                            default:
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "5.00";
                        }
                    }
                }else if(tipoVetro == 'doppio'){
                    if(tipoInfisso == 'finestra'){
                        switch(tipoTelaio){
                            case 'legnoDuro':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "3.15";
                            break;
                            case 'pvcDue':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "3.10";
                            break;
                            case 'lamiera':
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "3.90";
                            break;
                            default:
                                this.module.manufatti['serramenti'][k].trasmittanzaPre = "3.00";
                        }
                    }
                }
            },
            /*manageSchermatura(k){
                var tipoSchermatura = this.module.manufatti['schermature'][k].tipologia;

                //gestisco gli item del campo di esposizione
                if(
                    tipoSchermatura == 'tapparella' ||
                    tipoSchermatura == 'persiana' ||
                    tipoSchermatura == 'altraChiusura'
                ){
                    this.esposizioni =
                        [{text: "Nord", value: "nord"},{text: "Nord-est", value: "nordEst"},
                        {text: "Est", value: "est"},{text: "Sud", value: "sud"},
                        {text: "Sud-ovest", value: "sudOvest"},
                        {text: "Ovest", value: "ovest"},{text: "Nord-ovest", value: "nordOvest"}]
                    this.materialiSchermatura = 
                        [{text: "PVC", value: "pvc"},{text: "Legno",value: "legno"},
                        {text: "Metallo", value: "metallo"}, {text: "Plastica",value: "plastica"},
                        {text: "Misto",value: "misto"}];
                }else{
                    this.esposizioni =
                        [{text: "Est", value: "est"},{text: "Sud", value: "sud"},
                        {text: "Sud-ovest", value: "sudOvest"}, {text: "Ovest", value: "ovest"}]
                    this.materialiSchermatura = 
                        [{text: "PVC", value: "pvc"},{ text: "Tessuto", value: "tessuto"},
                        {text: "Legno",value: "legno"},{text: "Metallo", value: "metallo"},
                        {text: "Plastica",value: "plastica"},{text: "Misto",value: "misto"}];
                }

                this.manageResistenzaTermicaSupplementare(k)
            },*/
            manageVettoreEnergetico(){
                switch(this.module.generatori[0].tipoGeneratore){
                    case 'Generatore_Teleriscaldamento':
                        this.module.vettoreEnergetico = "teleriscaldamento";
                    break;
                    case 'Generatore_Biomassa':
                        this.module.vettoreEnergetico = "biomassa";
                    break;
                    case "Generatore_caldaia gasolio":
                        this.module.vettoreEnergetico = "gasolio";
                    break;
                    default:
                        this.module.vettoreEnergetico = "metano";
                    break;
                }
            },
            manageResistenzaTermicaSupplementare(k){
                const tipologiaChiusuraPre = this.module.manufatti['chiusure'][k].tipologiaPre;

                const tipologiaChiusuraPost = this.module.manufatti['chiusure'][k].tipologiaPost;                
                const permeabilita = this.module.manufatti['chiusure'][k].permeabilitaAria;

                if(tipologiaChiusuraPre){
                    switch(tipologiaChiusuraPre){
                        case "0":
                            this.module.manufatti['chiusure'][k].resistenzaTermicaIniziale = "0,12";
                        break;
                        case "1":
                            this.module.manufatti['chiusure'][k].resistenzaTermicaIniziale = "0,13";
                        break;
                        case "2":
                        case "3":
                            this.module.manufatti['chiusure'][k].resistenzaTermicaIniziale = "0,10";
                        break;
                        case "4":
                            this.module.manufatti['chiusure'][k].resistenzaTermicaIniziale = "0,08";
                        break;
                        case "5":
                            this.module.manufatti['chiusure'][k].resistenzaTermicaIniziale = "0,14";
                        break;
                    }
                }

                if(tipologiaChiusuraPost && permeabilita){
                    if(permeabilita == "media"){
                        switch(tipologiaChiusuraPost){
                            case "0":
                            case "2":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,19";
                            break;
                            case "1":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,16";
                            break;
                            case "3":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,17";
                            break;
                            case "4":
                            case "8":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,22";
                            break;
                            case "5":
                            case "6":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,12";
                            break;
                            case "7":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,08";
                            break;
                        }
                    }else if(permeabilita == "bassa"){
                        switch(tipologiaChiusuraPost){
                            case "0":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,26";
                            break;
                            case "1":
                            case "2":
                            case "3":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,22";
                            break;
                            case "4":
                            case "8":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,30";
                            break;
                            case "5":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,15";
                            break;
                            case "6":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,16";
                            break;
                            case "7":
                                this.module.manufatti['chiusure'][k].resistenzaTermicaFinale = "0,08";
                            break;
                        }
                    }
                }
            },
            addNewGeneratore(){
                this.module.generatori.push(
                    {
                        tipoGeneratore: "", 
                        numeroGeneratore: "", 
                        rendimentoMaxPotenza: "",
                        potenzaUtileNominale: ""
                    }
                );
            },
            removeGeneratore(id){
                this.module.generatori.splice(id, 1);
            },
            saveDraft(fromDocumentsStep){
                this.saveDraftLoading = true;
                this.module.status = {code: "DRAFT"}
                this.module.accountId = this.user.id
                uploadModule(this.module)
                .then(c => {
                    if(fromDocumentsStep){
                        this.$router.push('/modules/drafts').catch(e => {});
                    }else{
                        this.module = c.data;
                    }
                })
                .finally(_=>{
                    this.saveDraftLoading = false;
                })
            },
            sendModule(){
                this.canSend = false;
                this.sendModuleLoading = true;
                sendModule(this.module.id).then(c=>{
                    this.$router.push('/').catch(e => {});
                }).finally(()=>{
                    this.canSend = true;
                    this.sendModuleLoading = false;
                });

            },
            onClickEighthStepDirectly(){
                if(this.isAdmin){
                    this.stepperPosition= 8
                }else{
                    if(this.isEcobonus){
                        if( (this.module.manufatti['serramenti'].length >= 1 || this.riscaldamento == true) && this.module.generatori.length==0 ){
                            this.addNewGeneratore();
                        }
                    }
                    if(this.module.serviceType =="2" || this.module.serviceType ==2 || this.module.serviceType =="3" || this.module.serviceType ==3){
                        this.saveDraftBeforeDocuments(true)
                    }else{
                        this.stepperPosition=8;
                    }
                }
            },
            onClickEighthStep(){
                if(this.isAdmin){
                    this.stepperPosition++;
                }else{
                    if(this.isEcobonus){
                        if( (this.module.manufatti['serramenti'].length >= 1 || this.riscaldamento == true) && this.module.generatori.length==0 ){
                            this.addNewGeneratore();
                        }
                    }
                    if(this.module.serviceType =="2" || this.module.serviceType ==2 || this.module.serviceType =="3" || this.module.serviceType ==3){
                        this.module.status = {code: "DRAFT"}
                        this.module.accountId = this.user.id
                        this.saveDraftLastStepLoading =true;
                        uploadModuleLastStep(this.module)
                        .then(c => {
                                this.module.id = c.data.moduleId; 
                                this.hasEnoughCredits = c.data.hasEnoughCredits;
                                this.moduleCredits = c.data.moduleCredits;
                                this.totalCost = c.data.totalCost;

                                this.documentsRequired = [];
                                c.data.documentSet.documentsDocumentsSetResponse.forEach(element => {
                                
                                    if(element.toBeUploaded){
                                        // todo controllare logica dell'uploaded
                                        var doc = {
                                            name: element.document.name,
                                            isMandatory:element.isMandatory,
                                            documentDocumentSetId:element.id,
                                            path:"",
                                            uploaded: element.document.isUploaded
                                        };
                                        this.documentsRequired.push(doc);
                                    }
                                
                                });
                                if(c.data.hasEnoughCredits == false){
                                    this.creditError = "ATTENZIONE! Non hai abbastanza crediti per l'invio della pratica (BonusCasa: "+c.data.moduleCredits.numBonusCasa+", CalcoloEnergetico: "+c.data.moduleCredits.numCalcoloEnergetico+", Ecobonus: "+c.data.moduleCredits.numEcobonus+")"   
                                }

                                this.stepperPosition=8               
                    
                            }).catch(err=>{
                                this.$store.dispatch('alert/error', "Errore salvataggio praticha");
                            }).finally(()=>{
                                this.saveDraftLastStepLoading = false;
                            });
                    }else{
                        this.stepperPosition=8;
                    }
                }
            },
            saveDraftBeforeDocuments(auto){
                this.module.status = {code: "DRAFT"}
                this.module.accountId = this.user.id
                this.saveDraftLastStepLoading =true;
                uploadModuleLastStep(this.module)
                .then(c => {
                    this.module.id = c.data.moduleId; 
                    this.hasEnoughCredits = c.data.hasEnoughCredits;
                    this.moduleCredits = c.data.moduleCredits;
                    this.totalCost = c.data.totalCost;

                    this.documentsRequired = [];
                    c.data.documentSet.documentsDocumentsSetResponse.forEach(element => {
                      
                        if(element.toBeUploaded){
                            // todo controllare logica dell'uploaded
                            var doc = {
                                name: element.document.name,
                                isMandatory:element.isMandatory,
                                documentDocumentSetId:element.id,
                                path:"",
                                uploaded: element.document.isUploaded
                            };
                            this.documentsRequired.push(doc);
                        }
                       
                    });
                    if(c.data.hasEnoughCredits == false){
                        this.creditError = "ATTENZIONE! Non hai abbastanza crediti per l'invio della pratica (BonusCasa: "+c.data.moduleCredits.numBonusCasa+", CalcoloEnergetico: "+c.data.moduleCredits.numCalcoloEnergetico+", Ecobonus: "+c.data.moduleCredits.numEcobonus+")"   
                    }

                    if(auto){
                        this.stepperPosition++
                    }else{
                        this.$router.push('/modules/drafts').catch(e => {})
                    }
                    
                }).catch(err=>{
                    this.$store.dispatch('alert/error', "Errore salvataggio praticha");
                }).finally(()=>{
                    this.saveDraftLastStepLoading = false;
                });
            },
            downloadDocuments(){
                this.downloadDocumentsLoading = true;
                generateDocs(this.module).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // IE variant
                    window.navigator.msSaveOrOpenBlob(
                        new Blob([response.data]),
                        fileName
                    );
                    } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    let filename = "rif-cliente"
                    if (this.module.beneficiaryFirstName && this.module.beneficiaryLastName) {
                        filename += `-${this.module.beneficiaryFirstName}-${this.module.beneficiaryLastName}`
                    }
                    if (this.module.beneficiaryCompanyName) {
                        filename += `-${this.module.beneficiaryCompanyName}`
                    }
                    if(this.module.id){
                        filename += `-${this.module.id} - documentazione`
                    }
                    link.setAttribute("download", `${filename}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    }
                }).catch(() => {
                    this.$store.dispatch('alert/error', "Errore Download Pratiche");
                }).finally(_ => {
                    this.downloadDocumentsLoading = false;
                });
            },
            fileXmlUploaded: function (event) {
                var file = document.getElementById("fileXml").files[0];
                var reader = new FileReader();       
                var self = this;
                reader.onload = function(){
                    var text = reader.result;
                    var options = {compact: true, ignoreComment: true, spaces: 4};
                    var result = convert.xml2js(text, options);
                    if('DATI_ENEA' in result){
                        console.log(result['DATI_ENEA'])
                        self.importDataFromFile(result['DATI_ENEA']);
                    }
                
                }
                reader.readAsText(file);
            },
            async importDataFromFile(fileObj){
                
                if('TipoBonus' in fileObj){
                    if(fileObj['TipoBonus']._text=="Ecobonus"){
                        this.module["serviceType"]="1";
                    }
                    if(fileObj['TipoBonus']._text=="BonusCasa"){
                        this.module["serviceType"]="2";
                    }
                    if(fileObj['TipoBonus']._text=="BonusSicurezza"){
                        this.module["serviceType"]="3";
                    }    

                }

                if('Beneficiario' in fileObj){
                    if(Array.isArray(fileObj['Beneficiario'])){
                        var ben = this.parseBeneficiario(fileObj['Beneficiario'][0]);  
                        this.module = {...this.module, ...ben}
                        if(!this.module.isCompany && this.beneficiarySsn !=""){
                            this.readSSN()
                        }
                        for ( var i=1; i<fileObj['Beneficiario'].length; i++){
                            this.module["otherBeneficiaries"].push(this.parseBeneficiario(fileObj['Beneficiario'][i]))
                        }
                    }else{
                        var ben = this.parseBeneficiario(fileObj['Beneficiario']);  
                        this.module = {...this.module, ...ben}
                        if(!this.module.isCompany && this.beneficiarySsn !=""){
                            this.readSSN()
                        }
                    }
                }
                if('DatiCatastali' in fileObj){
                    var cat = this.parseDatiCatastali(fileObj['DatiCatastali']);  
                    this.module = {...this.module, ...cat}
                }
                if('UbicazioneImmobile' in fileObj){
                    var ubi = await this.parseUbicazioneImmobile(fileObj['UbicazioneImmobile']);  
                    this.module = {...this.module, ...ubi}
                    this.selectedImmovableMunicipality = this.module.immovableZip+"_"+this.module.immovableMunicipality+"_"+this.module.immovableProvince+"_"+this.module.immovableClimateZone;
                }

                if('Residenza' in fileObj){
                    var resi = await this.parseResidenza(fileObj['Residenza']);  
                    this.module = {...this.module, ...resi}
                    this.selectedBeneficiaryResidentialMunicipality = this.module.beneficiaryResidentialZip+"_"+this.module.beneficiaryResidentialMunicipality+"_"+this.module.beneficiaryResidentialProvince+"_"+this.module.beneficiaryResidentialClimateZone;
                }

                if('DettaglioEconomico' in fileObj){

                    var detail = this.parseDettaglioEconomico(fileObj['DettaglioEconomico']);
                    this.module = {...this.module, ...detail}
                
                }  
                if('Serramenti_Infissi' in fileObj){
                    this.module.manufatti['serramenti'] = [];
                    this.module.manufatti['schermature'] = [];
                    this.module.manufatti['chiusure'] =[];
                    if(Array.isArray(fileObj['Serramenti_Infissi'])){
                        for (var k=0; k<fileObj['Serramenti_Infissi'].length; k++){
                            
                            if(fileObj['Serramenti_Infissi'][k]["Prodotto"]._text.toLowerCase().includes("schermature")||fileObj['Serramenti_Infissi'][k]["Prodotto"]._text.toLowerCase().includes("tende") ||fileObj['Serramenti_Infissi'][k]["Prodotto"]._text.toLowerCase().includes("veneziane") ){
                                var obj = this.parseSchermature(fileObj['Serramenti_Infissi'][k])
                                this.module.manufatti['schermature'].push(obj);
                                
                            }else if (fileObj['Serramenti_Infissi'][k]["Prodotto"]._text.toLowerCase().includes("chiusure") ||fileObj['Serramenti_Infissi'][k]["Prodotto"]._text.toLowerCase().includes("tapparelle") ){
                                var obj = this.parseChiusure(fileObj['Serramenti_Infissi'][k])
                                this.module.manufatti['chiusure'].push(obj);
                            }else{
                                var obj = this.parseSerramentiInfissi(fileObj['Serramenti_Infissi'][k])
                                var found = false;
                                for (var i = 0; i<this.module.manufatti['serramenti'].length; i++ ){
                                    if(this.deepEqual(obj, this.module.manufatti['serramenti'][i])){
                                        
                                        var superficie= (parseFloat(this.module.manufatti['serramenti'][i]["superficie"])+parseFloat(obj["superficie"])).toFixed(2);
                                        //console.log(superficie)
                                        this.module.manufatti['serramenti'][i]["superficie"] =superficie;
                                        found = true; break;
                                    }
                                }
                                if(!found)
                                    this.module.manufatti['serramenti'].push(obj);
                            }
                        }
                    }else{
                        if(fileObj['Serramenti_Infissi']["Prodotto"]._text.toLowerCase().includes("schermature")||fileObj['Serramenti_Infissi']["Prodotto"]._text.toLowerCase().includes("tende") ||fileObj['Serramenti_Infissi']["Prodotto"]._text.toLowerCase().includes("veneziane") ){
                            var obj = this.parseSchermature(fileObj['Serramenti_Infissi'])
                            this.module.manufatti['schermature'].push(obj);
                        }else if (fileObj['Serramenti_Infissi']["Prodotto"]._text.toLowerCase().includes("chiusure") ||fileObj['Serramenti_Infissi']["Prodotto"]._text.toLowerCase().includes("tapparelle")){
                            var obj = this.parseChiusure(fileObj['Serramenti_Infissi'])
                            this.module.manufatti['chiusure'].push(obj);
                        }else{
                            var obj = this.parseSerramentiInfissi(fileObj['Serramenti_Infissi'])
                            this.module.manufatti['serramenti'].push(obj);
                        }
                    }

                    this.numberOfSerramenti =this.module.manufatti['serramenti'].length; 
                    this.numberOfSchermature =this.module.manufatti['schermature'].length;
                    this.numberOfChiusureOscuranti =this.module.manufatti['chiusure'].length;
                    

                }
            },
            parseDettaglioEconomico(obj){
                var res = {
                    spese:{}
                };        
                if("SpesaCongruaFinestre" in obj){
                    if(Object.keys(obj["SpesaCongruaFinestre"]).length > 0){
                        res["spese"]["spesaDetraibileSerramenti"] = obj['SpesaCongruaFinestre']._text.replace("€", "").replace(".", "").replace(",", ".").trim();
                    }
                }
                if("SpesaCongruaOscuranti" in obj){
                    if(Object.keys(obj["SpesaCongruaOscuranti"]).length > 0){
                        res["spese"]["spesaDetraibileChiusure"] = obj['SpesaCongruaOscuranti']._text.replace("€", "").replace(".", "").replace(",", ".").trim();
                    }
                }
                if("SpesaCongruaSchermature" in obj){
                    if(Object.keys(obj["SpesaCongruaSchermature"]).length > 0){
                        res["spese"]["spesaDetraibileSchermature"] = obj['SpesaCongruaSchermature']._text.replace("€", "").replace(".", "").replace(",", ".").trim();
                    }
                }
                if("SpeseProfessionaliFinestre" in obj){
                    if(Object.keys(obj["SpeseProfessionaliFinestre"]).length > 0){
                        res["spese"]["speseProfessionaliSerramenti"] = obj['SpeseProfessionaliFinestre']._text.replace("€", "").replace(".", "").replace(",", ".").trim();
                    }
                }
                if("SpeseProfessionaliOscuranti" in obj){
                    if(Object.keys(obj["SpeseProfessionaliOscuranti"]).length > 0){
                        res["spese"]["speseProfessionaliChiusure"] = obj['SpeseProfessionaliOscuranti']._text.replace("€", "").replace(".", "").replace(",", ".").trim();
                    }
                }
                if("SpeseProfessionaliSchermature" in obj){
                    if(Object.keys(obj["SpeseProfessionaliSchermature"]).length > 0){
                        res["spese"]["speseProfessionaliSchermature"] = obj['SpeseProfessionaliSchermature']._text.replace("€", "").replace(".", "").replace(",", ".").trim();
                    }
                }
                return res;

            },
            parseBeneficiario(beneficiarioObj){
                var res = {};              
                if(beneficiarioObj["TipoPersona"]._text=="Fisica"){
                    res["isCompany"] = false;
                    res["beneficiaryFirstName"] = beneficiarioObj["Nome"]._text??"";
                    res["beneficiaryLastName"] = beneficiarioObj["Cognome"]._text??"";
                    res["beneficiarySsn"] = beneficiarioObj["CodiceFiscale"]._text??"";
                }else{
                    res["isCompany"] = true;
                    res["beneficiaryCompanyName"] = beneficiarioObj["Denominazione"]._text??"";
                    if("PartitaIVA" in beneficiarioObj){
                        if(Object.keys(beneficiarioObj["PartitaIVA"]).length > 0){
                            res["beneficiaryCompanyType"] ="0"
                            res["beneficiarySsn"] = beneficiarioObj["PartitaIVA"]._text??"";
                        }
                    }
                }
                
                return res;
            },
            parseDatiCatastali(obj){
                var res = {};
                res["cadastralSection"] = obj["Sezione"]._text??"";
                res["cadastralSheet"] = obj["Foglio"]._text??"";
                res["cadastralParcel"] = obj["Particella"]._text??"";
                res["cadastralSubordinate"] = obj["Subalterno"]._text??"";
                
                res["immovableMunicipalityCode"] = obj["ComuneCatastale"]._text??"";
                //comune catastale da capire se serve
               
                return res;
            },
           async  parseUbicazioneImmobile(obj){
                var res = {};   
                var self = this;
                res["immovableAddress"] = obj["Indirizzo"]._text??"";
            
                try{
                    var req = await getMunicipalityByZip(obj["CAP"]._text, obj["Comune"]._text); 
                    var mun = req.data;
                    res["immovableMunicipality"] = mun["name"];
                    res["immovableZip"] = mun["cap"]
                    res["immovableProvince"] = mun["province"]
                    res["immovableClimateZone"] = mun["climateZone"] 
                    self.selectedImmovableMunicipality =  res["immovableZip"]+"_"+ res["immovableMunicipality"]+"_"+ res["immovableProvince"]+"_"+res["immovableClimateZone"];                         
                    return res;
                }catch(e){
                    console.log("Comune non trovato")
                }
                if("ZONA" in obj){
                    if(Object.keys(obj["ZONA"]).length > 0){
                        res["ImmovableClimateZone"] =obj["ZONA"]; 
                    }
                }
                
            },
            async parseResidenza(obj){
                var res = {};
                res["beneficiaryResidentialAddress"] = obj["Indirizzo"]._text??"";
                res["beneficiaryResidentialNation"] = obj["Nazione"]._text??"";
                
               try{
                    var req =  await getMunicipalityByZip(obj["CAP"]._text, obj["Comune"]._text);
                    var mun = req.data;
                    res["beneficiaryResidentialMunicipality"] = mun["name"];
                    res["beneficiaryResidentialZip"] = mun["cap"]
                    res["beneficiaryResidentialProvince"] = mun["province"]
                    res["beneficiaryResidentialClimateZone"] = mun["climateZone"]     
                    self.selectedBeneficiaryResidentialMunicipality =  res["beneficiaryResidentialZip"]+"_"+ res["beneficiaryResidentialMunicipality"]+"_"+ res["beneficiaryResidentialProvince"]+"_"+res["beneficiaryResidentialClimateZone"];             
                    return res;                     
               }catch(e){
                    console.log("Comune non trovato");
                }
            
            },
            parseSerramentiInfissi(obj){
                var res = {};

                if("TipoVetroPannelloDopo" in obj){
                    if(Object.keys(obj["TipoVetroPannelloDopo"]).length > 0){
                        switch(obj["TipoVetroPannelloDopo"]._text.toLowerCase().trim()){
                            case "vetro doppio": res["tipologiaVetroPost"] = "doppio"; break;
                            case "vetro triplo": res["tipologiaVetroPost"] = "triplo"; break;                       
                            case "opaco": res["tipologiaVetroPost"] = "opaco"; break;
                        }
                    }
                }
                if("TipoVetroPannelloPrima" in obj){
                    if(Object.keys(obj["TipoVetroPannelloPrima"]).length > 0){
                            switch(obj["TipoVetroPannelloPrima"]._text.toLowerCase().trim()){
                            case "vetro singolo": res["tipologiaVetroPre"] = "singolo"; break;
                            case "vetro doppio": res["tipologiaVetroPre"] = "doppio"; break;
                            case "opaco": res["tipologiaVetroPre"] = "opaco"; break;
                            
                        }
                    }
                    
                }


                switch(obj["Prodotto"]._text.toLowerCase().trim()){
                    case "finestre": 
                    case "finestra da tetto": 
                    case "alzante scorrevole":
                    case "portefinestre": 
                    case "vetrata fissa": 
                        res["tipologiaInfisso"] = "finestra"; break;
                    case "portone ingresso": 
                    case "portoncini blindati":
                    case "portone coibentato per garage":
                    case "portone coibentato per garage":  
                        res["tipologiaInfisso"] = "portoncino"; break;
                    case "cassonetto coibentato con ispezione frontale": 
                    case "coibentazione del cassonetto con ispezione frontale":
                    case "cassonetto monoblocco ":
                    case "cassonetto monoblocco ":
                        res["tipologiaInfisso"] = "cassonettoIspezioneFrontale"; 
                        res["tipologiaVetroPre"] = "opaco"; 
                        res["tipologiaVetroPost"] = "opaco"
                        break;
                    case "cielino coibentato":
                    case "coibentazione del celino":
                        res["tipologiaInfisso"] = "cielino"; 
                        res["tipologiaVetroPre"] = "opaco";
                        res["tipologiaVetroPost"] = "opaco" 
                        break;
                }

               
             
                if( res["tipologiaInfisso"] == "portoncino"){
                    res["tipologiaVetroPre"] = "opaco";
                }



              
               res["superficie"]= obj["Superficie"]._text.replace(",", ".")??"";
                
                if("Confine" in obj){
                    if(Object.keys(obj["Confine"]).length > 0){
                        if(obj["Confine"]._text.toLowerCase().trim() =="verso esterno"){
                            res["confine"] = "esterno";
                        }else if(obj["Confine"]._text.toLowerCase().trim() =="zona non riscaldata"){
                            res["confine"] = "noRiscaldato";
                        }
                    }
                }
                
                if("ConOscurante" in obj){
                    if(Object.keys(obj["ConOscurante"]).length > 0){
                        if(obj["ConOscurante"]._text.toLowerCase().trim() == "si"){
                            res["installazioneChiusuraOscurante"] = true;
                        }else{
                            res["installazioneChiusuraOscurante"] = false;
                        }
                    }
                }

                res["trasmittanzaPre"]= obj["TrasmittanzaPrima"]._text.replace(",", ".")??null;
                res["trasmittanzaPost"]= obj["TrasmittanzaDopo"]._text.replace(",", ".")??null;
                
                

                if("TipoTelaioPrima" in obj){
                    if(Object.keys(obj["TipoTelaioPrima"]).length > 0){
                        switch(obj["TipoTelaioPrima"]._text.toLowerCase().trim()){
                            //FINESTRA
                            case "materiale misto -": res["tipologiaTelaioPre"] = "metalloTermico"; break;
                            case "alluminio senza taglio termico -": res["tipologiaTelaioPre"] = "lamiera"; break;                       
                            case "legno duro mm 50 -": res["tipologiaTelaioPre"] = "legnoDuro"; break;
                            case "legno tenero mm 50 -": res["tipologiaTelaioPre"] = "legnoTenero"; break;
                            case "pvc a due camere -": res["tipologiaTelaioPre"] = "pvcDue"; break;
                            case "pvc a tre camere -": res["tipologiaTelaioPre"] = "pvcTre"; break;
                            //PORTONCINO
                            case "legno": res["tipologiaTelaioPre"] = "legno"; break;
                            case "metallo no-taglio termico": res["tipologiaTelaioPre"] = "lamiera"; break;
                            case "pvc": res["tipologiaTelaioPre"] = "pvc"; break;
                            case "misto": res["tipologiaTelaioPre"] = "misto"; break;
                            case "metallo taglio termico": res["tipologiaTelaioPre"] = "metalloTermico"; break;
                            //CASSONETTO
                            case "cassonetto in legno con tappo frontale": res["tipologiaTelaioPre"] = "legno"; break;
                            case "cassonetto in metallo con tappo frontale": res["tipologiaTelaioPre"] = "lamiera"; break;
                            case "cassonetto monoblocco in lamiera": res["tipologiaTelaioPre"] = "lamiera"; break;
                            case "cassonetto in pvc con tappo frontale": res["tipologiaTelaioPre"] = "pvc"; break;
                            //CIELINO
                            case "cassonetto in legno con cielino": res["tipologiaTelaioPre"] = "legno"; break;
                            case "cassonetto in metallo con cielino": res["tipologiaTelaioPre"] = "lamiera"; break;
                            case "cassonetto monoblocco in lamiera": res["tipologiaTelaioPre"] = "lamiera"; break;
                            case "cassonetto in PVC con cielino": res["tipologiaTelaioPre"] = "pvc"; break;
                        }
                    }
                }

                if("TipoTelaioDopo" in obj){
                    if(Object.keys(obj["TipoTelaioDopo"]).length > 0){
                        switch(obj["TipoTelaioDopo"]._text.toLowerCase().trim()){
                            case "legno": res["tipologiaTelaioPost"] = "legno"; break;
                            case "pvc": res["tipologiaTelaioPost"] = "pvc"; break;                       
                            case "metallo taglio termico": res["tipologiaTelaioPost"] = "metalloTermico"; break;
                            case "metallo taglio freddo": res["tipologiaTelaioPost"] = "metalloFreddo"; break;
                            case "misto": res["tipologiaTelaioPost"] = "misto"; break;
                            case "altro": res["tipologiaTelaioPost"] = "misto"; break;
                        }
                    }
                }



                return res;
            },
            parseChiusure(obj){
                var res = {};               
                res["permeabilitaAria"]="bassa";
                
                if("TipoTelaioPrima" in obj){
                    if(Object.keys(obj["TipoTelaioPrima"]).length > 0){
                        switch(obj["TipoTelaioPrima"]._text.toLowerCase().trim()){
                            //FINESTRA
                            case "avvolgibili in pvc non coibentato": res["tipologiaPre"] = "0"; break;
                            case "avvolgibili in legno": res["tipologiaPre"] = "1"; break;                       
                            case "persiane in alluminio non coibentato (astecca chiusa o mobile": res["tipologiaPre"] = "2"; break;
                            case "avvolgibili in alluminio non coibentato": res["tipologiaPre"] = "3"; break;
                            case "persiane a stecca aperta e fissa": res["tipologiaPre"] = "4"; break;
                            case "scuri, antoni o persiane a stecca chiusa in legno": res["tipologiaPre"] = "5"; 
                            case "nessuna chiusura": res["tipologiaPre"] = "6"; 
                            
                            break;                          
                        }
                    }
                }
                if("Tipologia" in obj){
                    if(Object.keys(obj["Tipologia"]).length > 0){
                        switch(obj["Tipologia"]._text.toLowerCase().trim()){
                            case "avvolgibili in alluminio coibentato": res["tipologiaPost"] = "3"; break;
                            case "avvolgibili in alluminio non coibentato": res["tipologiaPost"] = "5"; break;
                            case "avvolgibili in legno": res["tipologiaPost"] = "2"; break;
                            case "avvolgibili in pvc coibentato": res["tipologiaPost"] = "0"; break;
                            case "avvolgibili in pvc non coibentato/persiane in pvc": res["tipologiaPost"] = "1"; break;
                            case "persiane a stecca aperta e fissa (in qualunque materiale)": res["tipologiaPost"] = "7"; break;                       
                            case "persiane in alluminio coibentato (a stecca chiusa)": res["tipologiaPost"] = "4"; break;                       
                            case "persiane in alluminio non coibentato (a stecca chiusa)": res["tipologiaPost"] = "6"; break;                       
                            case "scuri, antoni o persiane a stecca chiusa in legno": res["tipologiaPost"] = "8"; break;                       
                        }
                    }
                }
                res["resistenzaTermicaIniziale"] = obj["ResistenzaTermica"]._text.replace(",", ".")??"";


                if("TipoVetroPannelloPrima" in obj){
                    if(Object.keys(obj["TipoVetroPannelloPrima"]).length > 0){
                            switch(obj["TipoVetroPannelloPrima"]._text.toLowerCase().trim()){
                            case "vetro singolo": res["tipologiaVetroPre"] = "singolo"; break;
                            case "vetro doppio": res["tipologiaVetroPre"] = "doppio"; break;
                            case "opaco": res["tipologiaVetroPre"] = "opaco"; break;
                            
                        }
                    }
                    
                }
                if("TipoVetroPannelloDopo" in obj){
                    if(Object.keys(obj["TipoVetroPannelloDopo"]).length > 0){
                        switch(obj["TipoVetroPannelloDopo"]._text.toLowerCase().trim()){
                            case "vetro doppio": res["tipologiaVetroPost"] = "doppio"; break;
                            case "vetro triplo": res["tipologiaVetroPost"] = "triplo"; break;                       
                            case "opaco": res["tipologiaVetroPre"] = "opaco"; break;
                        }
                    }
                }

                if("Installazione" in obj){
                    if(Object.keys(obj["Installazione"]).length > 0){
                        if(obj["Installazione"]._text.toLowerCase().trim()=="interna"){
                            res["installazione"] = "interna"
                        }else if(obj["Installazione"]._text.toLowerCase().trim()=="esterna"){
                            res["installazione"] = "esterna"
                        }
                    }
                }

                res["superficieFinestrata"]= obj["SuperficieProtetta"]._text.replace(",", ".")??"";
                res["superficieChiusura"]= obj["Superficie"]._text.replace(",", ".")??"";
                res["trasmittanzaPre"]= obj["TrasmittanzaPrima"]._text.replace(",", ".")??null;
                res["trasmittanzaPost"]= obj["TrasmittanzaDopo"]._text.replace(",", ".")??null;
                
                if("GTot" in obj){
                    if(Object.keys(obj["GTot"]).length > 0){
                        res["trasmissioneSolare"] = obj["GTot"]._text.replace(",", ".");
                    }
                }

                if("Azionamento" in obj){
                    if(Object.keys(obj["Azionamento"]).length > 0){
                        switch(obj["Azionamento"]._text.toLowerCase().trim()){
                            case "manuale": res["meccanismoRegolazione"] = "manuale"; break;
                            case "servoassistito": res["meccanismoRegolazione"] = "motorizzato"; break;                       
                            case "automatico": res["meccanismoRegolazione"] = "automatizzato"; break;
                        }
                    }
                }

               
                
                if(res["tipologiaPre"]){
                    switch(res["tipologiaPre"]){
                        case "0":
                            res["resistenzaTermicaIniziale"] = "0,12";
                        break;
                        case "1":
                            res["resistenzaTermicaIniziale"] = "0,13";
                        break;
                        case "2":
                        case "3":
                            res["resistenzaTermicaIniziale"] = "0,10";
                        break;
                        case "4":
                            res["resistenzaTermicaIniziale"] = "0,08";
                        break;
                        case "5":
                            res["resistenzaTermicaIniziale"] = "0,14";
                        case "6":
                            res["resistenzaTermicaIniziale"] = "0,00";
                        break;
                    }
                }

                if(res["tipologiaPost"]){
                    switch(res["tipologiaPost"]){   
                        case "0":
                            res["resistenzaTermicaFinale"] = "0,26";
                        break;
                        case "1":
                        case "2":
                        case "3":
                            res["resistenzaTermicaFinale"] = "0,22";
                        break;
                        case "4":
                        case "8":
                            res["resistenzaTermicaFinale"] = "0,30";
                        break;
                        case "5":
                            res["resistenzaTermicaFinale"] = "0,15";
                        break;
                        case "6":
                            res["resistenzaTermicaFinale"] = "0,16";
                        break;
                        case "7":
                            res["resistenzaTermicaFinale"] = "0,08";
                        break;
                    }
                }

                if("Esposizione" in obj){
                    if(Object.keys(obj["Esposizione"]).length > 0){
                        switch(obj["Esposizione"]._text.toLowerCase().trim()){
                            case "nord": res["esposizione"] = "nord"; break;
                            case "nord-est": res["esposizione"] = "nordEst"; break;                       
                            case "sud-est": res["esposizione"] = "sudEst"; break;
                            case "est": res["esposizione"] = "est"; break;
                            case "sud": res["esposizione"] = "sud"; break;
                            case "sud-ovest": res["esposizione"] = "sudOvest"; break;
                            case "ovest": res["esposizione"] = "ovest"; break;
                            case "nord-ovest": res["esposizione"] = "nordOvest"; break;
                        }
                    }
                }
                res["modalitaCalcoloResistenzaTermica"]= "tabellaEnea";
                return res;
            },
            parseSchermature(obj){
                var res = {};
                if("Tipologia" in obj){
                    if(Object.keys(obj["Tipologia"]).length > 0){
                        switch(obj["Tipologia"]._text.toLowerCase().trim()){
                            case "tenda microforata": res["tipologia"] = "tenda"; break;
                            case "tenda da sole a braccio": res["tipologia"] = "tenda"; break;                       
                            case "tenda tecnica": res["tipologia"] = "tenda"; break;
                            case "pergotenda": res["tipologia"] = "tenda"; break;
                            case "veneziana integrata al vetro": res["tipologia"] = "veneziana"; break;
                            case "frangisole con lamelle mobili": res["tipologia"] = "altraSchermatura"; break;                          
                        }
                    }
                }
                if("Azionamento" in obj){
                    if(Object.keys(obj["Azionamento"]).length > 0){
                        switch(obj["Azionamento"]._text.toLowerCase().trim()){
                            case "manuale": res["meccanismoRegolazione"] = "manuale"; break;
                            case "servoassistito": res["meccanismoRegolazione"] = "motorizzato"; break;                       
                            case "automatico": res["meccanismoRegolazione"] = "automatizzato"; break;
                        }
                    }
                }


                if("TipoTelaioDopo" in obj){
                    if(Object.keys(obj["TipoTelaioDopo"]).length > 0){
                        switch(obj["TipoTelaioDopo"]._text.toLowerCase().trim()){
                            case "pvc": res["materialeSchermatura"] = "pvc"; break;
                            case "legno": res["materialeSchermatura"] = "legno"; break;                       
                            case "metallo": res["materialeSchermatura"] = "metallo"; break;
                            case "misto": res["materialeSchermatura"] = "misto"; break;
                            case "plastica": res["materialeSchermatura"] = "plastica"; break;
                            case "tessuto": res["materialeSchermatura"] = "tessuto"; break;
                            case "altro": res["materialeSchermatura"] = "misto"; break;                          
                        }
                    }
                }
                if("Posizione" in obj){
                    if(Object.keys(obj["Posizione"]).length > 0){
                        if(obj["Posizione"]._text.toLowerCase().trim()=="interna"){
                            res["installazione"] = "interna"
                        }else if(obj["Posizione"]._text.toLowerCase().trim()=="esterna"){
                            res["installazione"] = "esterna"
                        }
                    }
                }
                res["superficieFinestrata"]= obj["SuperficieProtetta"]._text.replace(",", ".")??"";
                res["superficieSchermatura"]= obj["Superficie"]._text.replace(",", ".")??"";

                if("Esposizione" in obj){
                    if(Object.keys(obj["Esposizione"]).length > 0){
                        switch(obj["Esposizione"]._text.toLowerCase().trim()){
                            case "sud-est": res["esposizione"] = "sudEst"; break;
                            case "est": res["esposizione"] = "est"; break;
                            case "sud": res["esposizione"] = "sud"; break;
                            case "sud-ovest": res["esposizione"] = "sudOvest"; break;
                            case "ovest": res["esposizione"] = "ovest"; break;
                        }
                    }
                }

                if("GTot" in obj){
                    if(Object.keys(obj["GTot"]).length > 0){
                        res["trasmissioneSolare"] = obj["GTot"]._text.replace(",", ".");
                    }
                }
                res["aggettiOstruzioni"]= {
                    orizzontali:{
                        altezzaSoffitto: "",
                        profonditaAggetto: ""
                    },
                    verticali: {
                        posizione: "",
                        distanza: "",
                        profondita: ""
                    },
                    ostruzioni: {
                        distanza: "",
                        differenzaPiani: ""
                    }
                };
                
                res["modalitaCalcoloResistenzaTermica"]= "tabellaEnea";
                return res;
            },

            printModule(){
                console.log(this.module)
            },
            deepEqual(obj1, obj2) {
                // Check if both are the exact same object (reference check)
                if (obj1 === obj2) {
                    return true;
                }

                // Check if both are objects (and not null)
                if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
                    return false;
                }

                // Check if both objects have the same number of keys
                let keys1 = Object.keys(obj1);
                let keys2 = Object.keys(obj2);

                if (keys1.length !== keys2.length) {
                    return false;
                }

                // Check if all keys and their values are the same in both objects
                for (let key of keys1) {  
                    if(key!= "superficie"){
                    // Recursively check for nested objects
                        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
                            return false;
                        }
                    }
                }

                return   true;
            }
            
        }
    }
</script>