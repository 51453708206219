<template>
  <div>
    <v-card>
            <v-data-table
            :headers="headersPackages"
            :items="filteredPackages"
            :search="search"
            
            fixed-header
            :footer-props="{
                'items-per-page-options':[10, 20, 30, 40]
            }"
        >
        <template v-slot:top>
            <v-toolbar
            dark
            color="secondary"
            flat
            >
            <v-toolbar-title>Pacchetti attivi</v-toolbar-title>
                <v-spacer />
                <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    label="Cerca"
                    single-line
                    hide-details
                    outlined
                />  
                
            </v-toolbar>
        </template>
        </v-data-table>
    </v-card>
  
    <br>
    <v-card>
    <v-data-table
      :headers="headersPayments"
      :items="filteredPayments"
      :search="searchPayments"
      dense
      height="75vh"
      :loading="loadingTablePayments"
      fixed-header
      :footer-props="{
        'items-per-page-options':[10, 20, 30, 40]
      }"
        >
      <template v-slot:top>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-toolbar-title>Pagamenti</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="searchPayments"
            append-icon="fa-search"
            label="Cerca"
            single-line
            hide-details
            outlined
          />  
        </v-toolbar>
      </template>
    
    </v-data-table>
      
  </v-card>
  
  </div>
</template>
<script>
import { getActivePackages }from "@/api/accounts.api"
import { getAllByUser  } from "@/api/payments.api";
import { mapGetters } from "vuex"

export default {
  data () {
    return { 
        search: "",  
        filteredPackages:[],
        headersPackages: [
          {text:  "Nome pacchetto", value: "rechargePackage.name", type: "string"},
          { text: "Crediti iniziali", value: "initialCredits", type: "number", filterActive: false },
          { text: "Crediti residui", value: "residualCredits", type: "number", filterActive: false },
        ],
        searchPayments: "",
        headersPayments: [
          {text: "Data", value: "createdString", type: "string"},
          { text: "Descrizione pagamento", value: "description", type: "string", filterActive: false },
          { text: "Crediti", value: "amount", type: "number", filterActive: false }       
        ],
        payments: [],
        filtersPayments: [],
        loadingTablePayments: false,
     }
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
    filteredPayments () {
      let payments = this.payments
      this.filtersPayments.forEach(filter => {
        if (typeof filter.value == "boolean") {
          payments = payments.filter(c => c[filter.field] === filter.value)
        }
        else { payments = payments.filter(c => {

          if(typeof c[filter.field] != 'undefined'){
            if(c[filter.field].includes(filter.value)){
              return c;
            }
          }

        }) }
      })
      return payments
    }
    
  },
  created(){
         if(this.$route.params.id){      
            this.id = this.$route.params.id 
        }
  },
  methods: {
    getActivePackages(){
        getActivePackages(this.id)
        .then(res =>{      
            this.filteredPackages = res.data;
        })
        .catch(err => {console.log(err)})
    },
    loadPayments () {
      getAllByUser(this.id).then((result) => {
         this.$nextTick(()=>{
           this.payments = result.data;
         })
      }).catch(err=>{
        console.log(err);
      });
    },
  
  },
  mounted (){
      this.getActivePackages();
      this.loadPayments();
   },
  
  
};
</script>

<style scoped>
  



.card_container {
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
}


</style>