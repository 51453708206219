<template>
    <v-card>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loadingTable"
            :search="search"
            @click:row="setCurrentRow"
            @dblclick:row="rowClick"
            fixed-header
            dense
        >
            <template v-slot:top>
                <v-toolbar dark color="primary" flat>
                    <v-toolbar-title>Pratiche elaborate</v-toolbar-title>
                    <v-spacer />
                    <v-text-field
                        v-model="search"
                        append-icon="fa-search"
                        label="Cerca"
                        single-line
                        outlined
                        hide-details
                    />
                    <v-col cols="auto">
                       <v-btn text>
                            <v-btn @click="exportToExcel" text>Esporta Excel</v-btn>
                        </v-btn>
                    </v-col>
                </v-toolbar>
                <div class="pa-2">
                    <v-btn icon color="primary" class="mr-2" @click="filterDialog.visible=true">
                        <v-icon>fa-filter</v-icon>
                    </v-btn>
                    <v-chip
                        v-for="filter in filters"
                        :key="filter.field"
                        close
                        class="mx-1"
                        @click:close="removeFilter(filter)"
                    >
                        <strong>{{ filter.text }}</strong>:
                        <span v-if="typeof filter.value == 'boolean'"> {{ filter.value?'Sì':'No' }}</span>
                        <span v-if="typeof filter.value == 'string'"> {{ filter.value }}</span>
                        <span v-if="typeof filter.value == 'object'"> {{ filter.value&&filter.value.length?filter.value.join(' - '):filter.value }}</span>
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.statoPratica="{ item }">
                <v-progress-linear
                    :value="item.status.statusPercentage"
                    :color="barColorByStatus(item.status.code)"
                    height="40"
                    rounded
                    
                >
                    <template v-slot: default>
                        <strong>{{ item.status.name }}</strong>
                    </template>
                </v-progress-linear>
            </template>
            <template v-slot:item.statusChange="{ item }">
                <v-select 
                    :items="statuses"
                    item-disabled="disable"
                    v-model="item.status.code"
                    @change="onStatusChange(item)"
                />
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small :loading="loading" @click="downloadModule(item.id)"> fas fa-download </v-icon>
            </template>
           <!-- <template v-slot:item.serviceType="{ item }">
                <span v-if="item.serviceType=='1'">
                    Asseverazione barriere architettoniche
                </span>
                <span v-else>
                    Asseverazione spesa
                </span>
                
            </template>-->
        </v-data-table>
        
        <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>

        <v-dialog v-model="rejectDialog.visible" max-width="600">
            <v-card>
                <v-card-title class="primary white--text headline mb-3"> Rifiuto Pratica </v-card-title>
                <v-card-subtitle>
                    <br>
                    Sei sicuro di voler rifiutare la pratica? <br>
                    Questa azione riporterà la pratica in uno stati di bozza e ricaricherà il credito all'utente.
                </v-card-subtitle>
                <v-card-text>
                    <v-textarea label="Motivo rifiuto" v-model="rejectDialog.rejectReason" outlined />
                    <v-file-input 
                        outlined
                        chips
                        required
                        ref="allegatoRifiuto"
                        v-model="rejectDialog.attachedFile"
                        label="Allegato"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancelRejection(false)" :disabled="rejectDialog.loading"> Annulla </v-btn>
                    <v-btn @click="sendRejection()" :loading="rejectDialog.loading" :disabled="rejectDialog.loading" color="error"> Rifiuta </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="filterDialog.visible"
            max-width="600"
        >
        <v-card>
            <v-card-title class="headline mb-3">
            Aggiungi Filtro
            </v-card-title>
            <v-card-text>
            <v-autocomplete
                v-model="filterDialog.selectedFilter"
                :items="headers.filter(c=>c.filterActive)"
                @change="initializeFilterValue"
                return-object
                outlined
                label="Campo"
            />
            <v-text-field
                v-if="filterDialog.selectedFilter.type==='string'"
                label="Filtro"
                v-model="filterDialog.value"
                outlined
            />
            <v-switch
                v-if="filterDialog.selectedFilter.type==='boolean'"
                v-model="filterDialog.value"
                :label="`${filterDialog.value?'Sì':'No'}`"
            />
            <v-select
                v-if="filterDialog.selectedFilter.type==='status'"
                outlined
                :items="this.statuses"
                item-value="text"
                v-model="filterDialog.value"
            />
            <v-menu
                ref="filterDate"
                v-model="filterDate"
                :close-on-content-click="false"
                transition="scale-transition"
                v-if="filterDialog.selectedFilter.type==='date'"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="dateRangeText"
                    label="Data"
                    outlined
                    readonly
                    placeholder=" "
                    v-bind="attrs"
                    v-on="on"
                />
                </template>
                <v-date-picker
                ref="picker"
                v-model="filterDialog.value"
                range
                />
            </v-menu>
            </v-card-text>
            <v-card-actions>
            <v-spacer />
            <v-btn @click="clearfilterDialog()">
                Annulla
            </v-btn>
            <v-btn
                color="primary"
                @click="addFilter"
            >
                Aggiungi
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import * as XLSX from 'xlsx';
    import { mapGetters } from "vuex";
    import { rejectModule, updateModuleStatus } from "@/api/admin.api";
    import { getModules, getModulesForExcel, downloadModuleDocs } from "@/api/module.api";
    export default {
        data () {
            return {
                search: "",
                loading: false,
                loadingTable: false,
                rejectedItemIndex: -1,
                oldStatuses: [],
                headers: [
                    {
                        text: "Numero pratica",
                        value: "id",
                        width: "130",
                        type: "string",
                        filterActive: false
                    },
                    {
                        text: "Ragione Sociale serramentista",
                        value: "user",
                        type: "string",
                        filterActive: true
                    },
                    {
                        text: "Cliente",
                        value: "client",
                        type: "string",
                        filterActive: true
                    },
                    /*{
                        text: "Data ricezione",
                        value: "receivedDate",
                        type: "string",
                        filterActive: true
                    },*/
                    {
                        text: "Operatore gestore",
                        value: "operatoreName",
                        type: "string",
                        filterActive: true
                    },
                    {
                        text: "Stato",
                        value: "statoPratica",
                        width: "150",
                        type: "status",
                        filterActive: true
                    },
                    {
                        text: "",
                        value: "statusChange",
                        width: "200",
                        type: "string",
                        filterActive: false
                    },
                    {text: "Azioni", value: "actions", sortable: false}
                ],
                //statuses: [],
                /*statuses: [
                    {
                        value: "RECEIVED",
                        text: "Ricevuta"
                    },
                    {
                        value: "WIP",
                        text: "In Lavorazione"
                    },
                    {
                        value: "WAITING",
                        text: "In attesa di correzioni"
                    },
                    {
                        value: "ASSEVERATA",
                        text: "Asseverata"
                    },
                    {
                        value: "REJECTED",
                        text: "Rifiutata"
                    }
                ],*/
                items: [],
                filters: [],
                currentRow: {},

                rejectDialog: {
                    visible: false,
                    loading: false,
                    moduleId: null,
                    module: null,
                    rejectReason: "",
                    attachedFile: null

                },
                filterDialog: {
                    visible: false,
                    selectedFilter: {},
                    value: "",
                    statusCode: ""
                }
            }
        },
        computed: {
            ...mapGetters({
                statuses: "moduleStatuses/getAll" 
            }),
            excelFileName () {
                return `export_moduli_elaborati_${new Date().toLocaleDateString()}`
            },
            filteredItems(){
                let items = this.items
                this.filters.forEach(filter => {
                    if (typeof filter.value == "boolean") {
                    items = items.filter(c => c[filter.field] === filter.value)
                    }
                    else if (filter.field == "statoPratica") {

                    items = items.filter(c => c.status.name.includes(filter.value))
                    }
                    else if (filter.value && typeof filter.value === "object" && filter.value.length > 0) {
                    items = items.filter(c => {
                        if (c[filter.field]) {
                        const dataModulo = moment(c[filter.field], 'DD/MM/YYYY')
                        const dataDa = moment(filter.value[0])
                        const dataA = moment(filter.value[1])
                        return dataModulo.isSameOrAfter(dataDa) && dataModulo.isSameOrBefore(dataA)
                        }
                    })

                    }
                    else {
                    items = items.filter(c => c[filter.field].toLowerCase().includes(filter.value.toLowerCase()))
                    }
                })
                return items
            }
        },
        mounted () {
            this.updateGrid();
        },
        created() {
            this.$store.dispatch("moduleStatuses/getAll"); 
        }, 
        methods: {
            updateGrid() {
                this.loadingTable = true;

                getModules("ELABORATED").then(res => {
                    this.items = res.data;
                    this.items.forEach(item => {
                        this.oldStatuses[item.id] = item.status.code;
                    })
                }).finally(res => { this.loadingTable = false })
            },
            barColorByStatus (code) {
                switch (code) {
                    case "SENT":
                    case "WAITING":
                        return 'yellow'
                    case "ACCEPTED":
                    case "SENTTOENEA":
                        return '#007fff'
                    
                    case "SELLED":
                        return 'green'
                }
            },
            initializeFilterValue (){
                const selectedFilter = this.filterDialog.selectedFilter;
                if (selectedFilter) {
                    switch (selectedFilter.type) {
                        case 'date':
                            this.filterDialog.value = []
                        break;
                        default:
                            this.filterDialog.value = ""
                        break;
                    }
                }
            },  
            orderDates () {
                if (this.filterDialog.value && this.filterDialog.value.length > 0) {
                    return this.filterDialog.value.sort((left, right) => moment.utc(moment(left).diff(moment.utc(moment(right)))))
                }
                return []
            },
            addFilter () {
                const modal = this.filterDialog
                this.filters = this.filters.filter(c => c.field !== modal.selectedFilter.value)
                if (modal.selectedFilter.type === "boolean") {
                    modal.value = modal.value === "" ? false : modal.value
                }
                this.filters.push({ field: modal.selectedFilter.value, value: modal.value, text: modal.selectedFilter.text })
                this.clearfilterDialog()
            },
            clearfilterDialog () {
                this.filterDialog = {
                    visible: false,
                    selectedFilter: {},
                    value: "",
                    statusCode: ""
                }
            },
            onStatusChange(module) {
                let index = this.items.indexOf(module)

                if (module && module.status && (module.status.code == "REJECTED" || module.status.code == "WAITING")) {
                    this.rejectedItemIndex = index;
                    this.rejectDialog.visible = true;
                    this.rejectDialog.moduleId = module.id
                    this.rejectDialog.statusCode = module.status.code
                    return
                }
                this.loadingTable = true;

                updateModuleStatus(module).then(res => {
                    this.oldStatuses[module.id] = module.status.code;
                    this.updateGrid();
                }).catch(e => {
                    module.status.code = this.oldStatuses[module.id];
                    this.$store.dispatch('alert/error', "Errore Cambio stato");
                }).finally(() => {
                    this.loadingTable = false;
                })
            },
            sendRejection() {
                this.rejectDialog.loading = true
                const form = this.rejectDialog
                form.loading = true
                let formData = new FormData();
                formData.append('moduleId', form.moduleId);
                formData.append('rejectReason', form.rejectReason);
                formData.append('statusCode', form.statusCode);
                if (form.attachedFile)
                    formData.append('attachedFile', form.attachedFile);

                rejectModule(formData).then(() => {
                    this.cancelRejection(true);
                    if(this.rejectedItemIndex != -1){
                        //this.item.splice(this.rejectedItemIndex, 1);
                        this.rejectedItemIndex = -1;
                        this.updateGrid();
                    }
                }).finally(() => {this.rejectDialog.loading = false})
                
            },
            cancelRejection(isRejected) {
                if(!isRejected){
                    this.items[this.rejectedItemIndex].status.code = this.oldStatuses[this.rejectDialog.moduleId]
                }
                this.rejectDialog = {
                    visible: false,
                    loading: false,
                    rejectReason: "",
                    moduleId: null,
                    attachedFile: null,
                    statusCode: ""
                }
            },
            setCurrentRow (element) {
                this.currentRow = element
            },
            rowClick () {
                /*this.loading = true
                downloadModuleDocs(this.currentRow.id).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([response.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Pratiche");
                }).finally(_ => {
                    this.loading = false
                });*/
                this.$router.push("edit/" + this.currentRow.id);
            },
            downloadModule(id){
                this.loading = true
                downloadModuleDocs(id).then((response) => {
                    let fileName = response.headers["content-disposition"].split(
                    "filename="
                    )[1];
                    fileName = fileName.substring(0, fileName.indexOf(";"));
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    var file = new Blob([response.data]);
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = fileName.split('"').join("");
                    a.click();
                }).catch(_ => {
                    this.$store.dispatch('alert/error', "Errore Download Pratiche");
                }).finally(_ => {
                    this.loading = false
                });
            },
            exportToExcel(){
                let wb = XLSX.utils.book_new();
                getModulesForExcel("ELABORATED").then(c => {
                    /*c.data.forEach(pratica => {
                        pratica.serramenti.forEach(serr => {
                            if(!pratica[serr.type]){pratica[serr.type] = 0;}
                            pratica[serr.type]++;
                        })
                    })*/ 
                    var mappedData = c.data.map(row => {
                        return {
                            "Numero pratica": row.id,
                            "Calcolo energetico":(row.calcoloEnergetico)?"X":"",
                            "Tipologia servizio": row.serviceType,
                            "Servizio inserimento dati":(row.acquisizioneBeneficiarioAmbrosi)?"X":"",
                            "Ragione sociale":row.businessName,
                            "Partita iva azienda":row.vatNumber,
                            "Acquirente serramenti":row.beneficiaryName,
                            "Codice fiscale":row.beneficiarySsn,
                            "Sponsor":"",
                            "Stato avanzamento":row.status,
                            "Motivo rifiuto":row.rejectionReason,
                            "Data rifiuto":row.rejectionDate,
                            "Data ricezione": row.receivedDate,
                            "Data in lavorazione": row.wipDate,
                            "Data invio all’ENEA": row.sentToEneaDate,
                            "Data ultima invio pratica": row.moduleLastSendDate,
                            "Operatore": row.operatore
                        }
                    })
                    let ws = XLSX.utils.json_to_sheet(mappedData);
                    XLSX.utils.book_append_sheet(wb, ws, "Moduli");
                    XLSX.writeFile(wb, `${this.excelFileName}.xlsx`)
                });
            }
        }
    }
</script>