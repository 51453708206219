<template>
  <div>
    <v-card>
      <v-card-title>Pagamento tramite bonifico bancario</v-card-title>
          <div class="card_container">
            <v-row>
              <v-col>
                Importo: <b>€ {{ parseFloat(priceWithVat).toFixed(2) }}</b>
              </v-col>
            </v-row>           
            <v-row>
              <v-col>
                Intestatario: <b>Ambrosi Partner srl</b>
              </v-col>
            </v-row> 
            <v-row>
              <v-col>
                IBAN: <b>IT72 V030 6934 9341 0000 0000 686</b>
              </v-col>
            </v-row> 
            <v-row>
              <v-col>
                Causale Bonifico: <b>{{ user.companyName }} - {{ user.email }} - {{ packageName }}</b>
              </v-col>
            </v-row>   
          </div>

      </v-card>
    </div>
</template>
<script>
import { getPackage} from "@/api/recharge.api";

import { mapGetters } from "vuex"

export default {
  data () {
    return {
      currentPackage: null
     }
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
    priceWithVat () {
      return this.currentPackage && this.currentPackage.priceWithVat
    },
    packageName () {
      return this.currentPackage && this.currentPackage.name
    }
  },
  created(){

  },
  methods: {
  
  },
  mounted () {
    var packageSelected = this.$route.params.type;
    
    var numCredits = 0;
    
    if(this.$route.query.numCredits != null){
      numCredits = this.$route.query.numCredits 
    }
    
    
    var self = this;
    getPackage(packageSelected,numCredits).then((res) => {
      const rPackage = res.data;
      this.currentPackage = rPackage
    })
  },
};
</script>

<style scoped>
  



.card_container {
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
}


</style>