<template>
   
    <v-row>
    <v-dialog
            v-model="dialogVisible"
            persistent
        >
    <a href="#" style="text-decoration: none;">
      <v-card
        color="primary"
        dark
        @click="logout"
      >
        <v-card-title class="headline">
            Contratto non ancora verificato
        </v-card-title>
        <v-card-text>
            Gentile cliente, Stiamo verificando il contratto caricato durante la registrazione. Riceverà una mail non appena il contratto verrà verificato<br>
            <b>Grazie mille e a presto, da parte di tutto il team Ambrosi Partner</b>
            <br>
            <b>Clicca qui per effettuare il log out </b>
        </v-card-text>
      </v-card>
    </a>
    </v-dialog>
     <v-col
            cols="12"
            xs="12"
            md="4"
            >
            <v-card
                dark
                style="border: 1px solid #2B8A37 !important; color: #2B8A37; background-color:white"
                @click="$router.push('/modules/drafts').catch(err=>{})"
            >
                <v-card-title>Pratiche in bozza</v-card-title>
                <v-card-text class="headline" style="color:  #2B8A37">
                    {{ uploadedModules }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="4"
        >
            <v-card
                dark
                style="border: 1px solid #2B8A37 !important; color: #2B8A37; background-color:white"
                @click="$router.push('/modules/sent').catch(err=>{})"
            >
                <v-card-title>Pratiche Inviate</v-card-title>
                <v-card-text class="headline" style="color:  #2B8A37">
                    {{ sentModules }}
                </v-card-text>
            </v-card>
        </v-col>

        <v-col
            cols="12"
            xs="12"
            md="4"
        >
            <v-card
                dark
                style="border: 1px solid #2B8A37 !important; color: #2B8A37; background-color:white"
            >
                <v-card-title>Pratiche completate</v-card-title>
                <v-card-text class="headline" style="color:  #2B8A37">
                    {{ completedModules }}
                </v-card-text>
            </v-card>
        </v-col>

        <v-col
            cols="12"
            xs="12"
            md="6"
        >
            <v-card
                dark
                style="border: 1px solid #ADCC59 !important; color: #ADCC59; background-color:white"
                @click="$router.push('/packages').catch(err=>{})"
            >
                <v-card-title>Crediti residui</v-card-title>
                <v-card-text class="headline"  style="color:  #ADCC59">
                  {{ credits }}
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="6"
        >
            <v-card
                dark
                style="border: 1px solid #ADCC59 !important; color: #ADCC59; background-color:white"
                @click="$router.push('/packages').catch(err=>{})"
                height="100%"
            >
                <v-card-title>Acquista Servizio</v-card-title>
                <v-card-text class="headline" style="color:  #ADCC59">
                ‎‎‎‎
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            xs="12"
            md="12"
        >
        <a href="#" style="text-decoration: none;">
            <v-card
                dark
                 @click="dialogFatturaInfissi=true"
                style="border: 1px solid #EE9223 !important; color: #EE9223; background-color:white"
            >
                <v-card-title>Vuoi rendere più agevole e veloce la compilazione delle pratiche?</v-card-title>
                <v-card-text class="headline" style="color:  #EE9223">
                    Clicca qui
                </v-card-text>
            </v-card>
        </a>
        </v-col>
        <v-dialog
        v-model="dialogFatturaInfissi"
        width="900"
        >
        <v-card>
            <v-card-title class="text-h5 lighten-2" style="color:  #EE9223">
            Info software "Fattura infissi"
            </v-card-title>

            <v-card-text>
                    <br>
                    Il software Fattura Infissi ti permette di esportare un file XML
                    contenente la quasi totalità delle informazioni necessarie permettendoti di risparmiare tempo
                    ed evitare errori. <br>
                    Fattura Infissi non è un programma di fatturazione, ma si limita a fare tutto
                    quello che il tuo programma di fatturazione non fa: 
                    <ul>
                        <li>determinazione e calcolo automatico dell’aliquota Iva</li>
                        <lI>determinazione dei prodotti detraibili in funzione del bonus</li>
                        <li>verifica dei massimali unitari</li>
                        <lI>produzione della documentazione di supporto e redazione del fac-simile della fattura</li>
                    </ul>
                    Se ancora non usi questo strumento lo puoi acquistare direttamente da questo link:
                    oppure puoi chiedere maggiori informazioni – anche sulla possibilità di integrazione nel vostro
                    attuale processo di preventivazione/fatturazione - al dott. Leonardo Ambrosi scrivendo a
                    <span style="text-decoration:underline">leonardo@ambrosipartner.com</span> o chiamando lo 0461960286
                    <br>
            </v-card-text> 

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialogFatturaInfissi = false"
            >
                Chiudi
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        
    </v-row>
        
                 
</template> 
<script>
    import { mapGetters } from "vuex";
    import {getCountModules} from "@/api/module.api.js"
    import {getCredits} from "@/api/accounts.api.js"
    export default {
        name: "Dashboard",
        data () {
            
        return {
            uploadedModules:0,
            sentModules:0,
            completedModules: 0,
            dialogFatturaInfissi:false,
            credits:0,
            dialogVisible: false
        };
    },
    computed: {
        ...mapGetters({
            user: "authentication/user",
        }),
    },
    mounted () {    
      
      getCredits()
      .then(res=>{
            this.credits = res.data;
      })
        getCountModules().then(res=>{
            this.uploadedModules = res.data.drafts;
            this.sentModules = res.data.sent;
            this.completedModules = res.data.completed;
        }).catch(err=>{
            console.log(err);
        });

        if(!this.user.isContractVerified){
            this.dialogVisible=true;

        }

    },
    methods: {
        openInNewTab (href) {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: href,
            }).click();
        },
        logout(){
            this.$store.dispatch("authentication/logout", true);
        }
    }
}
</script>