<template>
    <v-card>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
            @click:row="setCurrentRow"
            @dblclick:row="rowClick"
        >
            <template v-slot:top>
                <v-toolbar dark flat color="primary">
                    <v-toolbar-title>Pratiche caricate in bozza</v-toolbar-title>
                    <v-spacer />
                    <v-text-field 
                        v-model="search"
                        append-icon="fa-search"
                        label="Cerca"
                        outlined
                        single-line
                        hide-details
                    />
                </v-toolbar>
            </template>
            <template v-slot:item.service="{ item }">
                <template v-if="item.serviceType == '1'">
                    Ecobonus
                </template>
                <template v-else-if="item.serviceType == '2'">
                    Bonus Casa
                </template>
                <template v-else-if="item.serviceType == '3'">
                    Bonus Sicurezza
                </template>
            
            </template>
            <template v-slot:item.reject="{ item }">
                <v-btn v-if="item.rejectReason" elevation="2" x-small color="error" @click="openRejectReasonDialog(item.id, item.rejectReason, item.status.code)"><span v-if="item.status.code == 'WAITING'">CORREZIONI RICHIESTE</span> <span v-else>MOTIVO RIFIUTO</span> </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small :loading="loading" v-if="item.rejectReason" @click="openRejectReasonDialog(item.id, item.rejectReason)" class="mx-2">fas fa-exclamation-triangle</v-icon>
                <v-icon small :loading="loading" @click="editDraft(item.id)"> fas fa-edit </v-icon>
                <v-icon small :loading="loading" @click="deleteDraft(item.id)" class="mx-2"> fas fa-trash </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="rejectReasonDialog.visible" max-width="600">
            <v-card>
                <v-card-title class="primary white--text headline mb-3"> <span v-if="rejectReasonDialog.statusCode == 'WAITING'">Correzioni&nbsp;</span><span v-else>Motivo rifiuto&nbsp;</span> pratica {{ this.rejectReasonDialog.moduleId }}</v-card-title>
                <v-card-subtitle>
                    <br>
                    {{ this.rejectReasonDialog.reason }}
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeRejectReasonDialog()" color="primary"> Chiudi </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import { getModules, deleteModule } from "@/api/module.api";
import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                search: "",
                loading: false,
                headers: [
                    {text: "Numero modulo", value: "id", width: 150},
                    {text: "Data creazione", value: "created"},
                    {text: "Cliente", value: "client"},
                    {text: "Servizio", value: "service"},
                    {text: "Termine ultimo invio al controllo", value: "deadlineDate"},
                    {text: "", value: "reject"},
                    {text: "Azioni", value: "actions", sortable: false}
                ],
                items: [],
                rejectReasonDialog: {
                    visible: false,
                    moduleId: "",
                    reason: "",
                    statusCode: ""
                }
            }
        },
        computed: {
            ...mapGetters ({
                user: "authentication/user",
            })
        },
        mounted() {
            this.updateGrid();
        },
        methods: {
            updateGrid() {
                this.loading = true;
                getModules("DRAFT")
                .then(
                    res => { 
                        this.items = res.data
                    }
                )
                .catch(
                    e => { console.log(e) }
                )
                .finally(
                    f => { this.loading = false }
                )
            },
            editDraft(id) {
                this.$router.push("edit/" + id);
            },
            deleteDraft (id) {
                this.loading = true
                deleteModule(id).finally(() => {
                    this.updateGrid();
                });
            },
            setCurrentRow (element) {
                this.currentRow = element
            },
            rowClick () {
                this.loading = true
                this.$router.push("edit/" + this.currentRow.id);
            },
            openRejectReasonDialog(moduleId, reason, statusCode) {
                this.rejectReasonDialog.moduleId = moduleId;
                this.rejectReasonDialog.reason = reason;
                this.rejectReasonDialog.visible = true;
                this.rejectReasonDialog.statusCode = statusCode;
            },
            closeRejectReasonDialog() {
                this.rejectReasonDialog.moduleId = "";
                this.rejectReasonDialog.reason = "";
                this.rejectReasonDialog.visible = false;
                this.rejectReasonDialog.statusCode = "";
            }
        }
    }
</script>