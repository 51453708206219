import api from './baseApi'

export function getAllMunicipalities(){
    return api.get('municipalities/getAll')
}

export function inserimentoMassivoComuni(){
    return api.get(`municipalities/inserimentoMassivoComuni`);
  }
  
  export function inserimentoMassivoZoneClimatiche(){
    return api.get(`municipalities/inserimentoMassivoZoneClimatiche`);
  }

  export function getMunicipalityByZip(zip, municipality){
    return api.get(`municipalities/fromZip/${zip}/${municipality}`);
  }