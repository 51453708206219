import {
   authenticate
} from '@/api/auth.api.js'

import {
   forgotPasswordApi,
   resetPasswordApi
} from '@/api/accounts.api.js'

import { store } from '@/store'
import  router  from '@/router'
export const authService = {
   login,
   logout,
   forgotPassword,
   resetPassword,
}

function login(email, password){
   return authenticate ( {email, password} )
      .then(
         (response) => {
            const user = response.data
            //login andato a buon fine se nella response trovo il jwtToken
            if (user.jwtToken) {
               localStorage.setItem('user', JSON.stringify(user));
            }
            
            return user;
         }
      )
      .catch(

         err => {
            console.log(err);
            if(err.response.status == 400){
               store.dispatch('alert/error', "Account non verificato - verificare l'account tramite la mail di verifica che ha ricevuto a seguito della registrazione", {root: true})
           
            }else{
               console.log(err);
               store.dispatch('alert/error', 'Email o password non corretti', {root: true})
            }
            /*if(res.response.status == 409){
               // account ets faccio accettare i termini 
               router.push('/terms-conditions/'+res.response.data).catch(err=>{});

            }else{*/
               
            //}
            
           

         }
      )
}

function logout() {
   // remove user from local storage to log user out
   localStorage.removeItem('user');
 }
function forgotPassword(email) {
   // remove user from local storage to log user out
   return forgotPasswordApi(email)
}

function resetPassword(payload) {
   // remove user from local storage to log user out
   return resetPasswordApi(payload)
 }