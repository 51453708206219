import {
    getAllMunicipalities
  } from '@/api/municipalities.api'
  
  const municipalitiesList = JSON.parse(localStorage.getItem('municipalitiesList'));

  const initialState = municipalitiesList ? {
    municipalitiesList
  } : {
    municipalitiesList: {}
  };

  export const municipalities = {
    namespaced: true,
    state: initialState,
    actions: {
      getAllMunicipalities({
        commit
      }) {
        return getAllMunicipalities().then(res => {
          commit('setMunicipalities', res.data)
        })
      },
      getMunicipalityDetails(_, id){
        //risalgo a cap, comune e provincia dall'id 
        return id.split("_");
      }
    },
    mutations: {

      setMunicipalities(state, list) {
        var tmp = [];
        for (let i = 0; i < list.length; i++) {
          var id = list[i].cap+'_'+list[i].name+'_'+list[i].province+'_'+list[i].climateZone;
          var value = list[i].name+', '+list[i].province+' (Cap: '+list[i].cap+')';
          tmp[i] = {'id': id, 'label': value};
        }
        state.municipalitiesList = tmp;
        localStorage.setItem('municipalitiesList', JSON.stringify(state.municipalitiesList));
      }
    },
    getters: {
        getAll: state => {
        return state.municipalitiesList
      }
    }
  }
  