import api from './baseApi'

export function gets() {
    return api.get(`Accounts`)
}


export function get(id) {
    return api.get(`Accounts/${id}`)
}

export function updateAccount(account) {
    return api.put(`Accounts`, account, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

export function forgotPasswordApi(email) {
    return api.post(`Accounts/forgot-password`, email)
}

export function resetPasswordApi(payload) {
    return api.post(`Accounts/reset-password`, payload)
}

export function updateTermsApi(model) {
    return api.post(`Accounts/update-terms-and-conds`, model, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}


export function verifyEmail(payload) {
    return api.post(`Accounts/verify-email`, payload)
}


export function verifyEmailManual(accountId) {
    return api.post(`Accounts/verify-email-account/${accountId}`, {})
}

export function verifyContract(accountId) {
    return api.post(`Accounts/verify-contract-account/${accountId}`, {})
}

export function testEmail() {
    return api.get(`Accounts/test-email`)
}

export function getCredits() {
    return api.get(`Accounts/credit`)
}

export function getCreditsCongruitaSpesa() {
    return api.get(`Accounts/credit-congruita-spesa`)
}

export function getByEmail(payload) {
    return api.post(`Accounts/get-by-email`, payload)
}

export function deleteAccount(userId) {
    return api.delete(`Accounts/${userId}`)
}
  
export function getAllGrid() {
    return api.get(`Accounts/get-all-gird`)
}

export function getAllExcel() {
    return api.get(`Accounts/get-all-excel`)
}
  
export function changeSponsor(userId, sponsor) {
    return api.post(`Accounts/change-sponsor`, {UserId:userId, Sponsor:sponsor} )
}

export function downloadUserDocs(id) {
    return api.download(`Accounts/download/` + id)
}

export function getActivePackages(id){
    return api.get(`Accounts/get-active-packages/` + id)
}

export function insertNewAccountPackage(id, rechargePackage){
    return api.post(`Accounts/recharge/` + id, rechargePackage)
}