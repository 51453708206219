<template>
    <v-card>
        <v-card-title>
            <v-avatar size="50" color="primary">
                <span class="white--text headline">{{ userInitials.toUpperCase() }}</span>
            </v-avatar>
            <span class="mx-3">{{ user.firstName }} {{ user.lastName }}</span>
            
            <v-spacer />

            <v-btn
                rounded
                tile
                color="primary"
                v-if="viewMode"
                @click="viewMode=false"
            >
                <v-icon small left>fas fa-edit</v-icon>
                Modifica Dati
            </v-btn>
            <v-btn
                rounded
                tile
                color="success"
                v-else
                @click="updateAccount()"
                :loading="saveLoading"
            >
                <v-icon small left>fas fa-edit</v-icon>
                Salva
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row dense>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        :disabled="viewMode"
                        label="Nome"
                        v-model="user.firstName"
                        required
                        :rules= "requiredField"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        :disabled="viewMode"
                        label="Cognome"
                        v-model="user.lastName"
                        required
                        :rules= "requiredField"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        :disabled="true"
                        label="Email"
                        v-model="user.email"
                        required
                        :rules= "[...requiredField, ...emailField]"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        :disabled="viewMode"
                        label="Telefono"
                        v-model="user.phone"
                        required
                        :rules= "[...requiredField, ...phoneField]"
                    />
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="12" md="12">
                    <v-select
                        :items="legalForms"
                        :disabled="viewMode"
                        outlined
                        label="Forma Giuridica"
                        v-model="user.legalForm"
                        :rules="requiredField"
                    />
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        outlined
                        :disabled="true"
                        label="Ragione Sociale"
                        v-model="user.businessName"
                        required
                        :rules= "requiredField"
                    />
                </v-col>

                <v-col cols="12" md="12">
                    <v-text-field
                        outlined
                        :disabled="viewMode"
                        label="Via"
                        v-model="user.street"
                        required
                        :rules= "requiredField"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        :disabled="viewMode"
                        label="Paese"
                        v-model="user.village"
                        required
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        outlined
                        label="Comune"
                        item-value="id"
                        item-text="label"
                        v-model="selectedComune"
                        :items="this.municipalities"
                        :rules="requiredField"
                        :disabled="viewMode"
                        @change="manageComune"
                        required
                    />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                    <v-select 
                        outlined
                        required
                        label="Regione"
                        :disabled="viewMode"
                        :items="regions" 
                        v-model="user.region"
                    />
                </v-col>
                <v-col
                    cols="12" md="12"
                >
                    <v-text-field
                        outlined
                        :rules="[...requiredField, ...vatField]"
                        :disabled="true"
                        label="Partita IVA"
                        v-model="user.vat"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        :rules="requiredField"
                        label="Codice Fiscale"
                        v-model="user.ssnCompany"
                        :disabled="viewMode"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        label="PEC"
                        v-model="user.pec"
                        :disabled="viewMode"
                        :rules="[...requiredField, ...emailField]"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        label="Codice Univoco"
                        v-model="user.uniqueCode"
                        :disabled="viewMode"
                        :rules="[...requiredField, ...uniqueCodeField]"
                    />
                </v-col>
               
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        label="Nome Rappresentante"
                        v-model="user.attorneyFirstName"
                        :disabled="viewMode"
                        :rules="requiredField"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        outlined
                        label="Cognome Rappresentante"
                        v-model="user.attorneyLastName"
                        :disabled="viewMode"
                        :rules="requiredField"
                    />
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        outlined
                        label="Paese Residenza Rappresentante"
                        v-model="user.attorneyVillage"
                        :disabled="viewMode"
                        :rules="requiredField"
                        required
                    />
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        outlined
                        label="Comune Residenza Rappresentante"
                        item-value="id"
                        item-text="label"
                        v-model="selectedAttorneyMunicipality"
                        :disabled="viewMode"
                        :items="this.municipalities"
                        :rules="requiredField"
                        @change="manageAttorneyMunicipality"
                        required
                    />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                    <v-select 
                        outlined
                        required
                        label="Regione Residenza Rappresentante"
                        :disabled="viewMode"
                        :items="regions" 
                        v-model="user.attorneyRegion"
                    />
                </v-col>
                 
                <v-col cols="12">
                    <v-text-field
                        outlined
                        label="Codice Fiscale Rappresentante"
                        v-model="user.attorneySSN"
                        :disabled="viewMode"
                        :rules="[...requiredField, ...ssnField]"
                    />
                </v-col>
                
                <v-col cols="12">
                    <v-select
                        :items="idTypes"
                        outlined
                        label="Documento di identificazione"
                        v-model="user.attorneyIDCardType"
                        :disabled="viewMode"
                        :rules="requiredField"
                    />
                </v-col>
                <v-col cols="12">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        :disabled="viewMode"
                        >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        :value="formattedDate"
                        label="Data scadenza documento di identità"
                        outlined
                        readonly
                        placeholder=" "
                        v-bind="attrs"
                        :disabled="viewMode"
                        v-on="on"
                        />
                    </template>
                        <v-date-picker
                        :disabled="viewMode"
                        ref="picker"
                        v-model="user.attorneyIDCardExpiryDate"
                        />
                    </v-menu>
                </v-col>
               
                <v-col cols="12">
                    <v-text-field
                        label="Numero Documento di identità"
                        outlined
                        v-model="user.attorneyIDCardNumber"
                        placeholder=" "
                        :disabled="viewMode"
                        :rules="requiredField"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        outlined
                        label="Comune di rilascio Documento di identità"
                        v-model="user.attorneyIDCardIssuingAuthority"
                        placeholder=""
                        :disabled="viewMode"
                        :rules="requiredField"
                        required
                    />
                </v-col>  
                <v-col
                    cols="12"
                    xs="12"
                    md="12"
                >
                    <v-file-input
                        outlined
                        chips
                        :disabled="viewMode"
                        :rules="[
                        (value) =>
                            !value ||
                            value.size < 40000000 ||
                            'Dimensione massima consentita di 40MB superata!'
                        ]"
                        ref="ciFrontFile"
                        v-model="ciFront"
                        label="Documento di identità fronte"
                    />
                </v-col>
                <v-col
                    cols="12"
                    xs="12"
                    md="12"
                >
                    <v-file-input
                        outlined
                        chips
                        :disabled="viewMode"
                        :rules="[
                        (value) =>
                            !value ||
                            value.size < 40000000 ||
                            'Dimensione massima consentita di 40MB superata!'
                        ]"
                        ref="ciRetroFile"
                        v-model="ciRear"
                        label="Documento di identità retro"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters } from "vuex";
    import { updateAccount } from "@/api/accounts.api"
    import LegalForms from "../../models/LegalForms";
    import IDTypes from "../../models/IDTypes";
    import Regions from '../../models/Regions';
    import DatePicker from "@/components/datePicker";

    export default {
        name: "User",
        components: { DatePicker },
        computed: {
            ...mapGetters({
                user: "authentication/user",
                municipalities: "municipalities/getAll"
            }),
            userInitials () {
                if (this.user.firstName && this.user.lastName) {
                    return this.user.firstName[0] + this.user.lastName[0];
                }else {
                    return this.user.firstName[0]
                }
            },
            formattedDate () {
                if(this.user.attorneyIDCardExpiryDate){
                    this.user.attorneyIDCardExpiryDate=this.user.attorneyIDCardExpiryDate.split('T')[0];
                }
                return this.user.attorneyIDCardExpiryDate
                    ? this.user.attorneyIDCardExpiryDate.split("-").reverse().join("/")
                    : "";
                
            },
        },
        data() {
            return {
                viewMode: true,
                menu: false,
                saveLoading: false,
                selectedComune: null,
                regions: Regions,
                selectedAttorneyMunicipality: null,
                menuExpiryDate: false,
                ciFront: null,
                ciRear: null,

                legalForms: LegalForms,
                idTypes: IDTypes,
                requiredField: [(v) => !!v || "Campo Obbligatorio!"],
                emailField: [
                    (v) =>
                    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || "Inserire Email Valida",
                ],
                phoneField: [
                    (value) => {
                        const pattern = /^(\d{10})$/;
                        return pattern.test(value) || "Inserire numero di telefono valido.";
                    }
                ],
                ssnField: [
                    (v) =>
                    !!(v && v.length === 16) ||
                    "Il codice fiscale deve essere composto da 16 caratteri",
                ],
                uniqueCodeField: [
                    (v) =>
                    !!(v && v.length === 7) ||
                    "Il codice univoco deve essere composto da 7 caratteri",
                ],
                vatField: [
                    (v) =>
                    !!(v && v.length === 11) ||
                    "La partita IVA deve essere composta da 11 caratteri",
                ],
            }
        },
        created() {
            this.$store.dispatch("municipalities/getAllMunicipalities"); 
            this.selectedComune = this.user.zip+'_'+this.user.city+'_'+this.user.province; 
            this.selectedAttorneyMunicipality = this.user.attorneyZip+'_'+this.user.attorneyCity+'_'+this.user.attorneyProvince;
        },
        methods: {
            updateAccount() {
                this.saveLoading = true;
                let formData = new FormData();
                formData.append('user', JSON.stringify(this.user));
                if (this.ciFront && this.ciFront !== undefined) {
                    const ext = this.ciFront.name.split(".").pop();
                    const fileName = `I.D. FRONTE ${this.user.attorneyLastName}_${this.user.attorneyFirstName}.${ext}`;
                    formData.append("ciFront", this.ciFront, fileName);
                }
                if (this.ciRear && this.ciRear !== undefined) {
                    const ext = this.ciRear.name.split(".").pop();
                    const fileName = `I.D. RETRO ${this.user.attorneyLastName}_${this.user.attorneyFirstName}.${ext}`;
                    formData.append("ciRear", this.ciRear, fileName);
                }
                updateAccount(formData).then((resp) => {
                    this.$store.dispatch("authentication/update", resp.data);
                    this.saveLoading = false;
                    this.viewMode = true;
                });
            },
            manageComune(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedComune)
                    .then(
                        response => {
                        this.user.zip = response[0];
                        this.user.city = response[1];
                        this.user.province = response[2];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
            manageAttorneyMunicipality(){ 
                this.$store.dispatch("municipalities/getMunicipalityDetails", this.selectedAttorneyMunicipality)
                    .then(
                        response => {
                        this.user.attorneyZip = response[0];
                        this.user.attorneyCity = response[1];
                        this.user.attorneyProvince = response[2];
                        }, 
                        error => {
                        console.log(error);
                        }
                    );
            },
        }
    }
</script>