import api from './baseApi'

export function readSSN(ssn) {
    return api.get(`Module/getDataFromSSN/${ssn}`)
}
export function uploadModule(module) {
    return api.put(`Module`, module).catch(c => {
        console.log(c)
    })
}
export function getModules(state) {
    return api.get('Module/getAll',
        {
            params: { state: state }
        }
    )
}

export function getModule(id) {
    return api.get(`Module/${id}`)
}

export function generateDocs(module) {
    return api.downloadPOST('/Module/generate-documents', module)
}

export function uploadModuleLastStep(module) {
    return api.put(`Module/lastStepDraft`, module).catch(c => {
        console.log(c)
    })
}

export function sendModule(id) {
    return api.post(`Module/send/${id}`).catch(c => {
        console.log(c)
    })
}

export function deleteModule(id) {
    return api.delete(`Module/${id}`)
}

export function downloadModuleDocs(id) {
    return api.download('/module/download/' + id)
}

export function downloadDocsCompilatore(id){
    return api.download('/module/downloadDocsCompilatore/' + id)
}

export function getCountModules() {
    return api.get(`Module/count`)
}
export function getModuleStatuses() {
    return api.get(`ModuleStatuses/getAll`)
}

export function getModulesForExcel(state) {
    return api.get(`Module/getallexcel`, {
      params: {
        state: state
      }
    })
  }

/*
export function generateDocsPostRequest(module) {
    return api.downloadPOST('/Module/generate-documents-post',module)
}



export function getModule(id) {
    return api.get(`Module/${id}`)
}



export function getModules(state) {
    return api.get('Module/getAll',
        {
            params: { state: state }
        }
    )
}

export function deleteModule(id) {
    return api.delete(`Module/${id}`)
}



export function downloadDocumentoAsseveratore(id) {
    return api.download('/module/downloadDocumentoAsseveratore/' + id);
}




  export function getModulesCongruitaConnectable(accountId, moduleId){
    return api.get(`Module/getCongruitaConnectable/${accountId}/${moduleId}`).catch(c => {
        console.log(c)})

  }*/
