import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import it from 'vuetify/es5/locale/it'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  lang: {
    locales: { it },
    current: 'it'
  },
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
          primary: '#0D6F4F',
          secondary: '#1077AD',
          accent: '#8c9eff',
          error: '#b71c1c'
        },
    },
  },
})
