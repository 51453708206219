<template>
  <div>
    <v-card-title class="font-weight-light headline pa-0 mb-5">
      Recupero Password
    </v-card-title>
    <v-card-subtitle class="font-weight-light pa-0 mb-10">
      Inserisci la tua email per resettare la password
    </v-card-subtitle>
    <v-text-field
      outlined
      label="Email"
      type="email"
      class="pb-0"
      :rules="[rules.requiredUsername, rules.email]"
      :error-messages="usernameError"
      v-model="username"
    />
    <v-row
      no-gutters
      justify="end"
    >
      <v-col cols="auto">
        <v-btn
          rounded
          color="secondary"
          class="pa-5"
          text
          @click="()=>{$router.push('/register')}"
        >
          Indietro
        </v-btn>
        <v-btn
          rounded
          color="primary"
          class="ml-3 pa-5"
          :loading="loading"
          @click="handleSubmit"
        >
          Recupera
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      username: "",
      usernameError: "",
      loading: false,
      rules: {
        requiredUsername: (value) => !!value || "Inserire Email.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Inserire Email valida.";
        },
      },
    };
  },
  methods: {
    validate () {
      const usernameValid = this.username.length > 0;
      this.usernameError = usernameValid ? "" : "Inserire username";
      return usernameValid;
    },
    handleSubmit (e) {
      if (this.validate()) {
        this.loading = true;
        this.$store
          .dispatch("authentication/forgotPassword", {
            email: this.username,
          })
          .then(res => {
            this.$store.dispatch('alert/success', res.data.message)
            setTimeout(() => this.$router.push('login'), 3000)
          })
          .finally(() => { this.loading = false })
      }
    },
  },
};
</script>
<style>
.background-auth {
  background-image: url("@/assets/bg.png");
  background-repeat: repeat;
  max-width: unset !important;
  max-height: unset !important;
  width: 100vw;
  height: 100vh;
}
.v-btn__content {
  letter-spacing: 0px !important;
}
</style>